import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import CalScoApiUrls from 'src/apiUrls/SCOJobApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SCOJobConfigTranslationText from './SCOJobConfigTranslationText'

const {
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup,
  }
} = components


const {
  filter: {
    EQUAL
  }
} = constants


function ManageJobConfigsTable(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.CAL_SCO
  ])
  const fetchCustomLookup = useFetchCustomLookup();
  const translatedTextsObject = SCOJobConfigTranslationText();
  const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
  const partyId = getPartyID();





  const columns = [
    {
      field: "jobConfigurationName",
      title: translatedTextsObject.scoJobConfigName,
    },
    {
      field: "createdBy",
      title: translatedTextsObject.scoCreatedBy,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
          {
            "partyId": getPartyID()
          },
          'content',
          'userProfile.loginId', //label accessor
          'id', //value accessor 
          (error) => console.error(error)
        )
      }
    },
    {
      field: "createdDate",
      title: translatedTextsObject.scoCreatedOn,
      defaultSort: "desc",
      type: 'datetime',
      filtering: false
    }
  ]

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/create`}
              columns={columns}
              del={{ url: CalScoApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              fetch={{
                url: CalScoApiUrls.GET,
              }}
              fetchFilters={
                [
                  {
                    field: 'isDeleted',
                    operator: EQUAL,
                    value: 'false'
                  },
                  {
                    field: 'partyId',
                    operator: EQUAL,
                    value: partyId
                  }
                ]
              }
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ManageJobConfigsTable
