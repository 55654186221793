import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_SCOJob'
import SCOJobTranslationText from './SCOJobTranslationText'
import TablePage from './TablePage'
import NewJobPage from './NewJobPage'
import EditPage from './EditPage'

function WrappedTablePage(props) {
      const translatedTextsObject = SCOJobTranslationText()
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={translatedTextsObject.scoJobTitle}
                  renderPage={(showSnackbar) => (
                        <TablePage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedAddPage(props) {
      const translatedTextsObject = SCOJobTranslationText()
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={translatedTextsObject.scoCreateNewJob}
                  renderPage={(showSnackbar) => (
                        <NewJobPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedEditPage(props) {
      const translatedTextsObject = SCOJobTranslationText()
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={"Edit Job"}
                  renderPage={(showSnackbar) => (
                        <EditPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )

}

export {
      WrappedTablePage as TablePage,
      WrappedAddPage as AddPage,
      WrappedEditPage as EditPage
}