import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useState, useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'
import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'
import JobDetailsActionMenu from './JobDetailsActionMenu'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import Utils from 'src/views/common/utils/Utils'


const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngDocumentAutocompleteField,
      CngLookupAutocompleteField
    },
  },
} = components




const JobDocumentDetailsComponents = (props) => {
  const { createRecord, fetchRecords } = useServices()
  const { submitForm, setFieldValue } = useFormikContext()
  const [files, setFiles] = useState([])


  useEffect(() => {

    if (props.tile !== null || props.tile.length <= 0) {
      setFiles(props.tile)
    }
  }, [props.tile])





  const [anchorMoreActionEl, setAnchorMoreActionEl] = React.useState(null)
  const open = Boolean(anchorMoreActionEl)

  const handleMoreActionClick = (event) => {
    console.log(event)
    setAnchorMoreActionEl(event.currentTarget)
  }

  const handleMoreActionClose = () => {
    setAnchorMoreActionEl(null)
  }



  if (files !== null || files.length <= 0) {
    return (
      <TableContainer component={Paper}>
        <Table id="table">
          <TableBody>
            {files.map((elem, i) => {
              return (<TableRow key={`row-${i}`}>
                <TableCell > <FileCopyIcon /></TableCell>
                <TableCell>
                  <DocumentInfoDecoratorComponent name={Utils.truncLongFileName(elem.fileName)} size={elem.fileSize} /> </TableCell>

                <TableCell>
                  <CngLookupAutocompleteField
                    name={`jobDocuments[${i}].docType`}
                    label={'File Type'}
                    required
                    onChange={(val, evt) => props.handleDropdownChange(val, evt, i, 'docType')}
                    disabled={!elem.editable}
                    key={props.docTypeKeyRef}
                    error={props.getFieldError(i, 'jobDocuments.docType')}
                    helperText={props.getFieldError(i, 'jobDocuments.docType')}
                    lookupProps={{
                      url: SCOJobApiUrls.DOCUMENT_GETDOCTYPE,
                      label: 'name',
                      value: 'code'
                    }}
                  />
                </TableCell>
                <TableCell>

                  <CngTextField
                    name={`jobDocuments[${i}].fileDescription`}
                    label={'File Description'}
                    onChange={e => props.handleInputChange(e, i)}
                    disabled={!elem.editable}
                    error={props.getFieldError(i, 'jobDocuments.fileDescription')}
                    helperText={props.getFieldError(i, 'jobDocuments.fileDescription')}
                  />
                </TableCell>
                <TableCell>
                  <JobDetailsActionMenu id={i}
                    hideDownloadLog
                    removeRowClick={props.removeRowClick}
                    editRowClick={props.editRowClick}
                    downloadRowClick={props.downloadRowClick}
                  />
                </TableCell>
              </TableRow>)
            })
            }
          </TableBody >
        </Table>
      </TableContainer>

    )
  } else {
    return (<br />)
  }



}

export default JobDocumentDetailsComponents
