import { Yup } from 'cng-web-lib'

const FIELDS_MAX_LENGTH = {
      ATTR_VALUE: 255
}

function JobAttributesSchema() {
      const mandatoryErrMsg = 'Required/Mandatory.'
      const exceedCharLimit = 'Exceed Characters Limit.'

      return Yup.object({
            jobAttributeList: Yup.array(
                  Yup.object().shape({
                        value: Yup.string().nullable().required(mandatoryErrMsg).max(FIELDS_MAX_LENGTH.ATTR_VALUE, exceedCharLimit)
                  })
            )
      })
}

export default JobAttributesSchema
