import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import SCOJobTranslationText from 'src/views/scojob/job/SCOJobTranslationText'
import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'

const {
      form: {
            adapter: {
                  useFormAdapter: { useField, useFormikContext }
            },
            field: {
                  CngSelectField,
                  CngLookupAutocompleteField
            },
      },
      CngGridItem
} = components

const {
      filter: { EQUAL }
} = constants

function JobTaskRateCardComponent({ taskList, index, handleRateCardChange, disabled, setFieldValue, handleRateOwnerAutoCompleteChange }) {
      const { fetchRecords } = useServices()
      const [rateOwner, setRateOwner] = useState();
      const [customer, setCustomer] = useState([]);
      const [rateCard, setRateCard] = useState([]);
      const [rateCardList, setRateCardList] = useState([]);

      const translatedTextsObject = SCOJobTranslationText()

      const handleRateOwnerChangeFunc = (val) => {
            setFieldValue('jobTaskItems[' + index + '].rateCardId', '')
            setFieldValue('jobTaskItems[' + index + '].customer', '')
            setRateCard([])
            setRateCardList([])
            setCustomer([])

            if (val != null) {
                  handleRateOwnerAutoCompleteChange(val, index)
            } else {
                  setRateOwner('')
            }

      }

      const handleRateCardChangeFunc = (rateCardValue) => {
            if (rateCardValue != null) {
                  const { value } = rateCardValue
                  var selected = rateCardList.filter(l => l.id === value)
                  if (selected.length > 0) {
                        const options = selected.map((rc) => {
                              return { text: rc.customerDisplayName, value: rc.customerAccountNo }
                        })
                        setCustomer(options);
                        setFieldValue('jobTaskItems[' + index + '].customer', selected[0].customerAccountNo)
                        handleRateCardChange(index, selected);
                  }
                  setFieldValue('jobTaskItems[' + index + '].rateCardId', rateCardValue)
            } else {
                  setCustomer([]);
                  setFieldValue('jobTaskItems[' + index + '].customer', '')
                  handleRateCardChange(index, null);
            }
      }

      const getRateCardByIssuer = (rateOwnerVal) => {
            const onSuccess = (response) => {
                  if (response.content.length > 0) {
                        const options = response.content.map((rc, i) => {
                              return { label: rc.serviceName, value: rc.id }
                        })

                        setRateCard(options)
                        setRateCardList(response.content)

                  } else {
                        setRateCard([])
                        setRateCardList([])
                  }

            }
            const onError = (error) => {
                  console.log("Error:[" + JSON.stringify(error) + "]")
            }

            fetchRecords.execute(
                  SCOJobApiUrls.GET_RATECARD_BY_ISSU,
                  {
                        filters: [
                              {
                                    field: 'id',
                                    operator: EQUAL,
                                    value: rateOwnerVal
                              }
                        ]
                  },
                  onSuccess,
                  onError
            )
      }


      const updateRateOwner = () => {
            if (taskList[index].rateCardId != null) {
                  setFieldValue('jobTaskItems[' + index + '].rateCardId', taskList[index].rateCardId)

                  //Auto-select rate card customer
                  var selectedRO = rateCardList.filter(l => l.id === taskList[index].rateCardId)
                  if (selectedRO.length > 0) {
                        const optionsRO = selectedRO.map((rc) => {
                              return { text: rc.customerDisplayName, value: rc.customerAccountNo }
                        })
                        setCustomer(optionsRO)
                        setFieldValue('jobTaskItems[' + index + '].customer', optionsRO[0].value)
                        handleRateCardChange(index, selectedRO);
                  }
            }
      }

      const checkDisabled = (source) => {
            let ldisabled = false;
            if ((taskList[index].id != '' && taskList[index].id != null) || disabled) {
                  //load from existing job task 
                  ldisabled = true;
            }

            if (taskList[index].id == null) {
                  //new added task - regardless of Edit Job
                  ldisabled = false;
            }

            if (source == 'rateCard') {
                  //if no rate card found
                  if (!ldisabled && rateCard.length == 0) {
                        ldisabled = true
                  }
            }
            return ldisabled;
      }

      useEffect(() => {
            if (taskList[index].rateOwnerId != undefined && taskList[index].rateOwnerId != '' && taskList[index].rateOwnerId!=rateOwner) {
                  setRateOwner(taskList[index].rateOwnerId)
                  getRateCardByIssuer(taskList[index].rateOwnerId)

            }
      }, [taskList[index].rateOwnerId])

      useEffect(() => {
            if (taskList[index].rateCardId != undefined && taskList[index].rateCardId != '') {
                  updateRateOwner();
            }
      }, [taskList[index].rateCardId])

      
      useEffect(() => {
            if (rateCardList.length>0 && taskList[index].rateCardId != '') {
               updateRateOwner();
            }
      }, [rateCardList])

      useEffect(() => {
            if (customer != undefined && customer.length > 0) {
                  setFieldValue('jobTaskItems[' + index + '].customer', customer[0].value)
            }
      }, [customer])

      return (
            <Grid container xs={12} sm={12} spacing={2}>
                  <CngGridItem xs={12} sm={12} shouldHide={true}>
                        <h5>
                              Outsource<br />
                        </h5>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                        <CngLookupAutocompleteField
                              name={`jobTaskItems[${index}].rateOwnerId`}
                              label={translatedTextsObject.outsourceTo}
                              onChange={(val) => {
                                    handleRateOwnerChangeFunc(val);

                              }}
                              lookupProps={{
                                    url: CecAccountApiUrls.GET_ACCOUNT_ISSUER,
                                    label: 'displayName',
                                    value: 'id'
                              }}
                              //disabled={checkDisabled('rateOwner')}
                              disabled={taskList[index].isRateCardDisabled}
                        />


                  </CngGridItem>
                  <CngGridItem xs={12} sm={4}>
                        {rateCard.length > 0 ?
                              <CngLookupAutocompleteField
                                    name={`jobTaskItems[${index}].rateCardId`}
                                    //key={`jobTaskItems[${index}].rateCardId`}
                                    label={translatedTextsObject.scoRateCard}
                                    disabled={checkDisabled('rateCard')}
                                    options={rateCard}
                                    hidden={true}
                                    onChange={(e) => {
                                          handleRateCardChangeFunc(e)
                                    }}
                                    lookupProps={{
                                          url: SCOJobApiUrls.GET_RATECARD_BY_ISSU,
                                          label: 'serviceName',
                                          value: 'id',
                                          filters:
                                                [{
                                                      field: 'id',
                                                      operator: EQUAL,
                                                      value: rateOwner
                                                }]
                                    }}
                              />
                              :
                              <CngSelectField
                                    name={`jobTaskItems[${index}].rateCardId`}
                                    key={`jobTaskItems[${index}].rateCardId`}
                                    label={translatedTextsObject.scoRateCard}
                                    hidden={true}
                                    disabled={true}
                                    items={rateCard}
                                    value={taskList[index].rateCardId ? taskList[index].rateCardId : ''}
                              />
                        }

                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                        <CngSelectField
                              name={`jobTaskItems[${index}].customer`}
                              key={`jobTaskItems[${index}].customer`}
                              label={translatedTextsObject.scoCustomer}
                              type='text'
                              disabled={true}
                              hidden={true}
                              items={customer}
                              value={taskList[index].customer ? taskList[index].customer : ''}
                        />
                  </CngGridItem>


            </Grid>
      );

}

export default JobTaskRateCardComponent