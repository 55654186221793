import React, { useState, useEffect } from 'react'
import { Box, Paper } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'

import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import WarningDialog from 'src/components/dialog/WarningDialog'
import CancelConfirmDialog from './components/DialogPopUp/CancelConfirmDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'

import SCOJobTranslationText from './SCOJobTranslationText'

import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import JobDetailsComponents from './components/JobDetailsComponents'
import JobTaskComponent from './components/JobTaskComponent'
import JobDocumentComponent from './components/JobDocumentComponent'
import JobButtonComponents from './components/JobButtonComponents'
import JobAttributeDialog from './components/JobAttributeDialog'
import CngBackdrop from '../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import { useFormContext } from "react-hook-form"


const {
      form: {
            adapter: {
                  useFormAdapter: { useFormikContext }
            }
      },
      CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
      id: '',
      ...JobAttributeDialog.initialValues,
      ...JobDetailsComponents.initialValues,
      ...JobTaskComponent.initialValues
})

const FORMIK_PROPS = {
      initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({ history, showNotification, loading, id }) {
      const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
      const [warningDialogOpen, setWarningDialogOpen] = useState(false)
      const { submitForm, setFieldValue, errors } = useFormikContext()
      let [jobTypeId, setJobTypeId] = useState('')
      const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
      const translatedTextsObject = SCOJobTranslationText()

      const [cancelDialogOpen, setCancelDialogOpen] = useState(false)

      //========================================
      const [timezoneList, setTimezoneList] = useState([])
      const { fetchRecords } = useServices()
      const [jobStatus, setJobStatus] = useState('')
      const [jobDataTaskLists, setJobDataTaskLists] = useState([])
      const [userAssignedList, setUserAssignedList] = useState([])
      const [jobAttributes, setJobAttributes] = useState([])
      const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
      const { getValues } = useFormContext();
      const values = getValues();
      const [customerCompanyId, setCustomerCompanyId] = useState()
      const [rateCardId, setRateCardId] = useState()
      //=========================================

      const handleJobConfigChange = (e) => {
            setJobTypeId(e ? e : "")
      }

      function isEmpty(obj) {
            return Object.keys(obj).length === 0;
      }

      const onSaveDraft = async () => {
            console.log('On Save Draft')
            if (!isEmpty(errors)) {
                  console.log('errors ' + JSON.stringify(errors))
                  setWarningDialogOpen(true);
            } else {
                  await setFieldValue("isDraft", true)
                  await setFieldValue("updateJOb", false)
                  submitForm()
            }
      }

      const onCreate = async () => {
            console.log('On Create')
            if (!isEmpty(errors)) {
                  console.log('errors ' + JSON.stringify(errors))
                  setWarningDialogOpen(true);
            } else {
                  await setFieldValue("isDraft", false)
                  await setFieldValue("updateJob", false)
                  await setFieldValue("cancelJob", false)
                  submitForm()
            }
      }

      const onUpdate = async () => {

            console.log('on Update')
            if (!isEmpty(errors)) {
                  console.log('errors ' + JSON.stringify(errors))
                  setWarningDialogOpen(true)
            } else {
                  console.log("Inside on update")
                  await setFieldValue("isDraft", false)
                  await setFieldValue("updateJob", true)
                  await setFieldValue("cancelJob", false)
                  //submitForm()
            }

      }

      const onDiscard = () => {
            console.log('On Discard')
            setConfirmDialogOpen(true)
      }

      const onBack = () => {
            history.push({
                  pathname: '/cal-sco/job'
            })
      }

      const onCancel = async () => {
            console.log('on Cancel')
            //Dialog Popup will appear 
            setCancelDialogOpen(true)

            if (!isEmpty(errors)) {
                  console.log('errors ' + JSON.stringify(errors))
                  setWarningDialogOpen(true)
            } else {
                  await setFieldValue("isDraft", false)
                  await setFieldValue("cancelJob", true)
            }
      }

      //Trigger after user click Confirm cancel
      const submitCancelJob= async () => {
            submitForm()
            setCancelDialogOpen(false)
        }
    

      //============================================

      function getTimezone() {
            fetchRecords.execute(
                  SCOJobApiUrls.GETTIMEZONE,
                  {},
                  (data) => {
                        setTimezoneList(
                              data.content.map((datum) => {
                                    return {
                                          text: datum.name,
                                          value: datum.id
                                    }
                              })
                        );
                  }
            )
      }

      function loadUserAssigned() {
            fetchRecords.execute(
                  `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
                  {
                        data: {
                              partyId: getPartyID()
                        }
                  },
                  (data) => {
                        setUserAssignedList(
                              data.content.map((datum) => {
                                    return {
                                          text: datum.userProfile.loginId,
                                          value: datum.id
                                    }
                              })
                        )
                  }

            )
      }



      useEffect(() => {
            loadUserAssigned()
            getTimezone()
            fetchRecords.execute(
                  SCOJobApiUrls.GETMYJOB,
                  {
                        filters: [
                              {
                                    field: 'id',
                                    operator: 'equal',
                                    value: id
                              }
                        ]
                  },
                  (data) => {
                        setJobStatus(data.content[0].jobStatus)
                        setJobDataTaskLists(data.content[0].jobTasksList)
                        setJobAttributes(data.content[0].jobAttributes)
                        setCustomerCompanyId(data.content[0].customerCompanyId)
                        setRateCardId(data.content[0].rateCardId)
                  }
            )

      }, [])

      return (
            <Box>
                  <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                        <CngBackdrop loading={loading} />
                  </CngGridItem>

                  <Paper>
                        <Box className="page-content">
                              <Box pt={5} p={5}>
                                    <JobDetailsComponents.FormBody
                                          parentHandleJobConfigChange={handleJobConfigChange}
                                          jobId={id}
                                          origin={values.origin}
                                          destination={values.destination}
                                          customerCompanyId = {customerCompanyId}
                                          rateCardId = {rateCardId}
                                    />
                              </Box>
                              <Box pt={0} p={5}>
                                    <JobAttributeDialog.FormBody
                                          jobId={id}
                                          jobTypeId={jobTypeId}
                                          jobAttributesData={jobAttributes}
                                    />
                              </Box>

                              <Box pt={0} p={5}>
                                    <JobTaskComponent.FormBody
                                          jobTypeId={jobTypeId}
                                          jobId={id}
                                          timezoneList={timezoneList}
                                          jobStatus={jobStatus}
                                          jobDataTaskLists={jobDataTaskLists}
                                          userAssignedList={userAssignedList}
                                          showNotification={showNotification}
                                    />
                              </Box>

                              <Box pt={0} p={5}>
                                    <JobDocumentComponent.FormBody
                                          showNotification={showNotification}
                                          jobId={id}
                                          jobStatus={jobStatus}
                                    />
                              </Box>

                        </Box>
                  </Paper>

                  <Paper className='sticky-footer'>
                        <Box alignItems='center'>
                              <JobButtonComponents
                                    onDiscard={onDiscard}
                                    onSaveDraft={onSaveDraft}
                                    onCreate={onCreate}
                                    onUpdate={onUpdate}
                                    jobStatus={jobStatus}
                                    onCancel= {onCancel}
                                    jobId={id}

                              />
                        </Box>
                  </Paper>

                  <ConfirmDialog
                        isConfirmDialogOpen={confirmDialogOpen}
                        closeDialog={() => setConfirmDialogOpen(false)}
                        confirmDialog={onBack}
                        content={translatedTextsObject.scoJobDiscardChanges}
                        okMsg={uiTranslatedTextsObject.ok}
                        cancelMsg={uiTranslatedTextsObject.cancel}
                        title={translatedTextsObject.scoJobDiscardTitle}
                  />

                  <WarningDialog
                        isDialogOpen={warningDialogOpen}
                        closeDialog={() => setWarningDialogOpen(false)}
                        okDialog={() => setWarningDialogOpen(false)}
                        content={translatedTextsObject.scoFormError}
                        okMsg={uiTranslatedTextsObject.ok}
                  />

                  <CancelConfirmDialog
                        isCancelDialogOpen={cancelDialogOpen}
                        closeDialog={()=> setCancelDialogOpen(false)}
                        confirmDialog={submitCancelJob}
                        content={"Are you sure you want to cancel this Job?"}
                        okMsg={"Confirm"}
                        cancelMsg={uiTranslatedTextsObject.cancel}
                        title={"Cancel Job"}
                        values={values}
                  />

            </Box>
      )

}


const EditJobFormProperties = Object.freeze({
      formikProps: FORMIK_PROPS,
      FormFields: FormFields
})

export default EditJobFormProperties