import React, { useState, useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import JobFormProperties from './JobFormProperties'
import SCOJobApiurls from 'src/apiUrls/SCOJobApiUrls'
import JobValidationSchema from './JobValidationSchema'


const {
      form: { CngViewForm },
      table: { useDefaultNotification }
} = components

function NewJobPage({ showNotification }) {
      const { createRecord, securedSendRequest } = useServices()
      const [loading, setLoading] = useState(false)
      const validationSchema = JobValidationSchema()
      const history = useHistory()
      const { id } = useParams()
      const [cloneData, setCloneData] = useState([])
      const { state } = useLocation()
      const jobId = state.jobId

      function isEmpty(obj) {
            return Object.keys(obj).length === 0;
      }

      const {
            success: showSuccessNotification,
            error: showErrorNotification
      } = useDefaultNotification(showNotification)

      const reqData = {
            "baseFilterDTO": {
                  "filterType": "AND",
                  "filterProperties": [
                        {
                              "fieldName": "id",
                              "operatorType": "EQUAL",
                              "value1": jobId
                        }
                  ],
                  "sortProperties": []
            }
      }

      useEffect(() => {
            if (jobId == undefined) {
                  return
            }
            const onSuccess = (data) => {
                  const list = data.data.content[0]
                  const joblist = data.data.content[0]?.jobTasksList
                  joblist.forEach(item => {
                        item.timezoneId = null
                        item.localEventTimestamp = null
                        item.taskStatus = 'NOTSTARTED'
                  })
                  list.jobTasksList = joblist
                  setCloneData(list)
            }
            const onError = (data) => {
                  console.log(data)
            }
            securedSendRequest.execute(
                  'post',
                  SCOJobApiurls.GETMYJOB,
                  reqData,
                  onSuccess,
                  onError
            )
      }, [jobId])

      function onSubmit(data) {
            delete data['taskList']


            if (data.saveDraft === true) {
                  if (data.pendingJobDocuments && !isEmpty(data.pendingJobDocuments)) {
                        uploadDocumentAndSaveDraft(data)
                  } else {
                        saveDraft(data);
                  }
            } else if (data.saveDraft === false) {
                  if (data.pendingJobDocuments && !isEmpty(data.pendingJobDocuments)) {
                        uploadDocumentAndSaveDraft(data);
                  } else {
                        saveJob(data)
                  }
            }

      }

      function uploadDocumentAndSaveDraft(data) {
            setLoading(true)
            console.log(loading)
            var formData = new FormData();
            data.pendingJobDocuments.map((file, i) => {
                  var fileBlob = file.file
                  formData.append("fileContents", fileBlob)
            })

            function onSuccess(response) {
                  if (response.errorMessages != null) {
                        showErrorNotification(response.errorMessages[0])
                        setLoading(false)
                  } else {
                        let jobDocuments = data.jobDocs || []
                        if (response && response.length > 0) {
                              response.map((file, i) => {
                                    let jobDocu = {
                                          docType: data.pendingJobDocuments[i].fields[0].value,
                                          fileName: file.fileName,
                                          fileDescription: data.pendingJobDocuments[i].fields[1].value,
                                          id: file.id,
                                          fileSize: file.fileSize
                                    }
                                    jobDocuments.push(jobDocu)
                              })
                        }
                        data.jobDocs = jobDocuments;
                        if (data.saveDraft === true) {
                              saveDraft(data)
                        } else {
                              saveJob(data)
                        }
                  }
            }

            function onError(error) {
                  showErrorNotification(error.message)
            }

            createRecord.execute(
                  SCOJobApiurls.DOCUMENT_UPLOAD,
                  formData,
                  onSuccess,
                  onError
            )
      }

      function saveJob(data) {
            setLoading(true)
            console.log(loading)
            function onSuccess(response) {
                  if (response.errorMessages.length > 0) {
                        showErrorNotification(response.errorMessages)
                        setLoading(false)
                  } else {
                        showSuccessNotification(response.jobRefNo + ' created successfully')
                        history.push({
                              pathname: '/cal-sco/job'
                        })
                  }
            }

            function onError(error) {
                  setLoading(false)
                  console.log('connection-request error', error.response.data.errorMessages)
                  showErrorNotification(error.response.data.errorMessages)
            }

            // creation API
            createRecord.execute(
                  SCOJobApiurls.HDR_CREATE_JOB,
                  data,
                  onSuccess,
                  onError
            )
      }

      function saveDraft(data) {
            setLoading(true)
            console.log('Saving draft >>>>> ' + loading)
            function onSuccess(response) {
                  setLoading(false)
                  if (response.errorMessages.length > 0) {
                        showErrorNotification(response.errorMessages)
                  } else {
                        showSuccessNotification(response.jobRefNo + ' drafted successfully')
                        history.push({
                              pathname: '/cal-sco/job'
                        })
                  }
            }

            function onError(error) {
                  setLoading(false);
                  console.log('connection-request error', error.response.data.errorMessages)
                  showErrorNotification(error.response.data.errorMessages)
            }

            // creation API
            createRecord.execute(
                  SCOJobApiurls.HDR_CREATE_JOB,
                  data,
                  onSuccess,
                  onError
            )
      }



      return (
            <Grid>
                  <CngViewForm
                        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        history={history}
                        showNotification={showNotification}
                        bodySection={
                              <JobFormProperties.FormFields
                                    history={history}
                                    showNotification={showNotification}
                                    loading={loading}
                                    jobReqRefNo={id}
                                    data={cloneData}
                              />
                        }
                        formikProps={{
                              ...JobFormProperties.formikProps,
                              validationSchema,
                              onSubmit: onSubmit
                        }}
                  />
            </Grid>
      )
}

export default NewJobPage