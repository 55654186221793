import Namespace from 'src/constants/locale/Namespace'
import SCOJob from 'src/constants/locale/key/SCOJob'
import { useTranslation } from 'cng-web-lib'

const SCOJobConfigTranslationText = () => {
    const { translate } = useTranslation([
        Namespace.UI_COMPONENT,
        Namespace.SCO_JOB
    ])

    let scoJobConfigTitle = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_TITLE
    )

    let scoJobConfigName = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_NAME
    )

    let scoCreatedBy = translate(
        Namespace.SCO_JOB,
        SCOJob.CREATED_BY
    )

    let scoCreatedOn = translate(
        Namespace.SCO_JOB,
        SCOJob.CREATED_ON
    )

    let scoJobConfigWorkFlowTitle = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_WORKFLOW_TITLE
    )

    let scoJobConfigTaskSequenceTitle = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_TASK_SEQUENCE_TITLE
    )

    let scoJobConfigTaskType = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_TASK_TYPE
    )

    let scoJobConfigTaskDescription = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_TASK_DESCRIPTION
    )

    let scoJobConfigEstimatedDuration = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_ESTIMATED_DURATION
    )

    let scoJobConfigContactList = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_CONTACT_LIST
    )

    let scoJobConfigEmailAddress = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_EMAIL_ADDRESS
    )

    let scoAddJobConfigModuleTitle = translate(
        Namespace.SCO_JOB,
        SCOJob.ADD_JOB_CONFIGURATION_MODULE_TITLE
    )

    let scoJobConfigTableModuleTitle = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_TABLE_NAME
    )

    let scoViewJobConfigModuleTitle = translate(
        Namespace.SCO_JOB,
        SCOJob.VIEW_JOB_CONFIGURATION
    )

    let scoEditJobConfigModuleTitle = translate(
        Namespace.SCO_JOB,
        SCOJob.EDIT_JOB_CONFIGURATION
    )

    let scoDisacardJobConfigTitle = translate(
        Namespace.SCO_JOB,
        SCOJob.DISCARD_JOB_CONFIG_TITLE
    )

    let scoSchemaRequiredFieldMsg = translate(
        Namespace.SCO_JOB,
        SCOJob.FIELD_REQUIRED
    )

    let scoCreateNewJob = translate(
        Namespace.SCO_JOB,
        SCOJob.CREATE_NEW_JOB
    )

    let scoContactListTitle = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_CONTACT_LIST
    )

    let scoFormError = translate(
        Namespace.SCO_JOB,
        SCOJob.FORM_ERROR
    )

    let scoJobAttribute = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_ATTRIBUTE
    )

    let scoAttribute = translate(
        Namespace.SCO_JOB,
        SCOJob.ATTRIBUTE
    )

    let scoNoAttributeConfigured = translate(
        Namespace.SCO_JOB,
        SCOJob.NO_ATTRIBUTE_CONFIGURED
    )

    let scoSelectAttribute = translate(
        Namespace.SCO_JOB,
        SCOJob.SELECT_ATTRIBUTE
    )
    let scoJobTaskAttribute = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_CONFIGURATION_TASK_ATTRIBUTE
    )

    return {
        scoJobConfigTitle,
        scoJobConfigName,
        scoCreatedBy,
        scoCreatedOn,
        scoJobConfigWorkFlowTitle,
        scoJobConfigTaskSequenceTitle,
        scoJobConfigTaskType,
        scoJobConfigTaskDescription,
        scoJobConfigEstimatedDuration,
        scoJobConfigContactList,
        scoJobConfigEmailAddress,
        scoAddJobConfigModuleTitle,
        scoJobConfigTableModuleTitle,
        scoViewJobConfigModuleTitle,
        scoEditJobConfigModuleTitle,
        scoDisacardJobConfigTitle,
        scoSchemaRequiredFieldMsg,
        scoCreateNewJob,
        scoContactListTitle,
        scoFormError,
        scoJobAttribute,
        scoAttribute,
        scoNoAttributeConfigured,
        scoSelectAttribute,
        scoJobTaskAttribute
    }
}

export default SCOJobConfigTranslationText
