import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'


import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SCOJobAttributeTranslationText from './SCOJobAttributeTranslationText'
import SCOJobAttributeApiUrls from 'src/apiUrls/SCOJobAttributeApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import CustomAttributeUrls from 'src/apiUrls/sco-jm-custom-attr/CustomAttributeUrls'

const {
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup,
  }
} = components


const {
  filter: {
    EQUAL
  }
} = constants


function JobAttributeConfigTable(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.CAL_SCO
  ])
  const fetchCustomLookup = useFetchCustomLookup();
  const translatedTextsObject = SCOJobAttributeTranslationText();
  const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
  const partyId = getPartyID();





  const columns = [
    {
      field: "attributeType",
      title: translatedTextsObject.scoAttributeType,
      customLookup: () => {
        return fetchCustomLookup(
          SCOAdminApiUrls.GET_CODE_MASTER_LIST,
          {
            codeType: 'JOB_ATTR_TYPE'
          },
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      }
    },
    {
      field: "attributeName",
      title: translatedTextsObject.scoAttributeName,
    },
    {
      field: "inputTypeId",
      title: translatedTextsObject.scoAttributeDataType,
      customLookup: () => {
        return fetchCustomLookup(
          SCOAdminApiUrls.GET_CODE_MASTER_LIST,
          {
            codeType: 'COM_INPUT_TYPE'
          },
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      }
    },
    {
      field: "customAttributeId",
      title: translatedTextsObject.scoCustomAttribute,
      customLookup: () => {
        return fetchCustomLookup(
          CustomAttributeUrls.CUSTOM_ATTR_GET,
          {},
          'content',
          'attributeName',
          'id',
          (error) => console.error(error)
        )
      }
    },
    {
      field: "createdDate",
      title: translatedTextsObject.scoAttributeCreatedDate,
      hidden: true,
      defaultSort: 'desc',
      type: 'datetime'
    }
  ]

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/create`}
              columns={columns}
              del={{ url: SCOJobAttributeApiUrls.DELETE_JOB_ATTRIBUTE }}
              editRoute={`${pathname}/edit`}
              fetch={{
                url: SCOJobAttributeApiUrls.GET,
              }}

              fetchFilters={
                [
                  {
                    field: 'partyId',
                    operator: EQUAL,
                    value: partyId
                  }
                ]
              }

              idAccessor="id"
              notification={notification}
            //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
            //viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default JobAttributeConfigTable
