import {
    Grid,
    Paper
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import SCOJobConfigTranslationText from 'src/views/scojob/jobconfig/SCOJobConfigTranslationText'
import { components, useServices } from 'cng-web-lib'

const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField
        },
    },
    CngGridItem,
} = components

const initialValues = Object.freeze({
    jobConfigurationName: ''
})



const FormBody = (props) => {
    const { errors, touched } = useFormikContext()

    const id = props.id
    const disabled = props.disabled
    const mode = props.mode
    const [jobConfigurationName, setJobConfigurationName] = useState([])
    const { fetchRecords } = useServices()
    const [shouldRender, setShouldRender] = useState(true)

    const getFieldError = (itemIndex, fieldPath) => {
        if (errors) {
            let pathArr = fieldPath.split('.')
            if (errors[pathArr[0]] && errors[pathArr[0]][itemIndex]
                && errors[pathArr[0]][itemIndex][pathArr[1]]) {
                if (touched) {
                    if (touched[pathArr[0]] && touched[pathArr[0]][itemIndex]
                        && touched[pathArr[0]][itemIndex][pathArr[1]]) {
                        // return true
                        return errors[pathArr[0]][itemIndex][pathArr[1]]
                    } else {
                        return null
                    }
                }
            }
        }
        return null
    }


    const translatedTextsObject = SCOJobConfigTranslationText()

    const handleInputChange = (e) => {
        const { name, value } = e.target
    }

    return (
        <Grid>
            <Paper>
                <Grid container spacing={2} style={{ paddingTop: 10, paddingLeft: 25, paddingRight: 15, paddingBottom: 15 }}>
                    <AccordionHeaderComponent title={translatedTextsObject.scoJobConfigName} />
                    <Grid container xs={12} spacing={2}>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                name='jobConfigurationName'
                                label={translatedTextsObject.scoJobConfigName}
                                required
                                onChange={e => handleInputChange(e)}
                                disabled={mode == 'view' ? true : false}
                            />
                        </CngGridItem>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )

}


const JobConfigurationNameComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default JobConfigurationNameComponent