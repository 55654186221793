import {
      Card,
      Grid,
      IconButton,
      Accordion,
      AccordionDetails,
      AccordionSummary,
      Box,
      Button, Switch,
      FormGroup, FormControlLabel
} from '@material-ui/core'
// import Switch from '@mui/material/Switch';

// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { components, constants } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'

import SCOJobTranslationText from 'src/views/scojob/job/SCOJobTranslationText'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AccordionFooterControlComponent from 'src/views/common/ui/AccordionFooterControlComponet'
import JobStatusListComponent from './JobStatusListComponent'
import JobTaskRateCardComponent from './JobTaskRateCardComponent'
import JobTaskStatusHistoryComponent from './JobTaskStatusHistoryComponent'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import DeleteIcon from '@material-ui/icons/Delete';
import InventoryDocument from './InventoryDocument'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import AttributeDialog from './AttributeDialog';
import InventoryFormProperties from './InventoryFormProperties';
import InventoryDetails from './InventoryDetails';

const INPUT_TYPE_CODES = {
      DATE: "DT",
      DATE_TIME: "DTTM",
      TEXT: "TXT",
      NUM: "NUM",
      CUSTOM_ATTR: "CATTR"
}

const {
      form: {
            field: {
                  CngTextField,
                  CngSelectField,
                  CngDateTimeField,
                  CngDateField,
                  CngLookupAutocompleteField

            },
      },
      table: {
            CngLocalModeDialogFormTable,
            useFetchCodeMaintenanceLookup
      },
      CngGridItem, CngTabs
} = components

const {
      filter: { EQUAL }
} = constants

const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId

function fieldsShouldHideTaskType(taskType, fields) {
      if (taskType !== 'DEL' && taskType !== 'PU' && taskType === 'INV') {
            if (fields === 'estimatedDuration' || fields === 'userAssigned' || fields === 'remarks' || fields === 'dueDate') {
                  return false
            } else {
                  return true
            }
      } 
      if(fields === 'recipientName' || fields === 'recipientPostalCode'|| fields === 'recipientAddress'|| fields === 'recipientEmailAddress' || fields === 'recipientContactNumber'){
            if(taskType === 'DEL' || taskType === 'PU'){
                  return false
            }
            else{
                  return true
            }
      
      }else {
            return false
      }
}

function fieldsLabelChangeForPickUp(taskType, recipient, sender) {
      if (taskType === 'PU') {
            return sender
      } else {
            return recipient
      }
}

const displayAttrValueField = (attribute, index, attrIndex, disabled) => {
      console.log(attribute)
      let code = attribute.configDTO.inputType.code;
      let field;
      switch (code) {
            case INPUT_TYPE_CODES.DATE:
                  field = <CngDateField
                        name={`jobTaskItems[${index}].jobTaskAttributes[${attrIndex}].value`}
                        label={"Value"}
                        disabled={disabled}
                  />
                  break;
            case INPUT_TYPE_CODES.DATE_TIME:
                  field = <CngDateTimeField
                        name={`jobTaskItems[${index}].jobTaskAttributes[${attrIndex}].value`}
                        label={"Value"}
                        disabled={disabled}
                  />
                  break;
            case INPUT_TYPE_CODES.NUM:
                  field = <CngTextField
                        type="number"
                        disabled={disabled}
                        name={`jobTaskItems[${index}].jobTaskAttributes[${attrIndex}].value`}
                        label={"Value"}
                  />
                  break;
            case INPUT_TYPE_CODES.CUSTOM_ATTR:
                  field = <CngLookupAutocompleteField
                    label={"Value"}
                    name={`jobTaskItems[${index}].jobTaskAttributes[${attrIndex}].value`}
                    options={attribute.configDTO.customAttribute.customAttributeValueList}
                    getOptionLabel={(option) => option.value}
                  />
                  break;
            default:
                  field = <CngTextField
                        disabled={disabled}
                        name={`jobTaskItems[${index}].jobTaskAttributes[${attrIndex}].value`}
                        label={"Value"}
                  />
      }
      return field;
}

function shouldHideIsActive(isActiveTask, isDeleted, taskList) {
      if (isActiveTask === true && isDeleted == false) {
            return false
      } else if (isActiveTask == false && isDeleted == true) {
            return false
      } else {
            return true
      }
}


function shouldHideTask(workFlow) {
      var check = false
      if (workFlow.taskType === null || workFlow.taskType === '' || workFlow.taskDesc === '') {
            if (workFlow.taskStatus !== 'NOTSTARTED') {
                  check = true
            }
      }
      if (workFlow.jobTaskAttributes.length !== 0) {
            for (let attribute of workFlow.jobTaskAttributes) {
                  if (attribute.value === '') {
                        check = true;
                        break;
                  }
            }
      }
      if (check) {
            return check
      }

      if (workFlow.jobTaskContact.jobTaskContactEmailList.length !== 0) {
            for (let email of workFlow.jobTaskContact.jobTaskContactEmailList) {
                  if (email.emailAddress === '') {
                        check = true
                        break;
                  }
            }
      }
      if (check) {
            return check
      }

      if (workFlow.taskType !== null && workFlow.taskDesc !== '' && workFlow.taskStatus === 'NOTSTARTED') {
            check = false
      } else {
            check = true
      }


      if (check) {
            return check
      } else {
            return false
      }
}



const displayDeleteRestoreBtn = (isActiveTask, index, onDeleteTask, onRestoreTask, translatedTextsObject) => {
      let field;
      switch (isActiveTask) {
            case true:
                  field = <Button variant="contained"
                        style={{ backgroundColor: '#e57f7f', color: '#ffffff' }}
                        align="center"
                        onClick={() => {
                              onDeleteTask(index)
                        }
                        }
                  >
                        {translatedTextsObject.scoJobDeleteTask}
                  </Button>
                  break;
            case false:
                  field = <Button variant="contained"
                        style={{ backgroundColor: '#5e81ff', color: '#ffffff' }}
                        align="center"
                        onClick={() => {
                              onRestoreTask(index)
                        }
                        }
                  >
                        {translatedTextsObject.scoJobRestoreTask}
                  </Button>
                  break;
      }
      return field;
}


function checkTaskTypeDisabled(disabled, jobStatus, id) {
      if (id === '') {
            if (disabled) {
                  return true
            } else {
                  return false
            }
      } else if (id !== '') {
            if (id === null) {
                  return false
            } else {
                  return true
            }
      } else if (jobStatus === 'DRFT' || jobStatus === undefined) {
            return true
      } else {
            return false
      }
}



function JobTaskDetailsComponents({ isActiveTask, taskList, jobId, jobStatus, handleEmailInputChange, addEmail, handleDropdownChange, handleTaskTypeDropdownChange,
      handleInputChange, getFieldErrorForEmail, handleWorkFlowListDetailsExpand, timezoneList, handleDateChange, handleRemoveEmail, disabled, userAssignedList, showNotification,
      removeTaskAttribute, openAttributeDialog, isOpen, setFieldValue, setIsOpen, attrOptions, filteredAttrOptions, setFilteredAttrConfigOptions, setNewFilteredOptions, jobTaskIndex, selectedMoveType, setSelectedMoveType, movementType, setMovementType,
      taskSequenceOptions, handleTaskSequenceChange, onDeleteTask, onRestoreTask, handleRateCardChange, handleRateOwnerAutoCompleteChange, isClone }) {
      const translatedTextsObject = SCOJobTranslationText()

      const [checked, setChecked] = React.useState(false);

      console.log(taskList)




      //splitting isActiveTask and isDeleted here.
      //should hide does not work as it will cause UI bug issue.
      return (
            taskList.map((workFlow, index) => {
                  if (isActiveTask == true && workFlow.isDeleted == false) {
                        return (
                              <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={12} sm={12}>
                                          <Card>
                                                <Accordion expanded={workFlow.workFlowItemExpanded} key={workFlow.workFlowAccordionId}>
                                                      <AccordionSummary aria-controls="jobTaskDetailsComponentContent" id={'jobTaskDetailsComponentActiveTaskKey'}>
                                                            <Grid container xs={12} sm={12} spacing={2}>
                                                                  <CngGridItem xs={12} sm={4}>
                                                                        <CngSelectField
                                                                              type='number'
                                                                              name={`jobTaskItems[${index}].taskSequence`}
                                                                              label={translatedTextsObject.scoJobTaskListTaskSequence}
                                                                              required
                                                                              onChange={(val, evt) => handleTaskSequenceChange(val, evt, index)}
                                                                              disabled={disabled}
                                                                              items={taskSequenceOptions}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4}>
                                                                        <CngLookupAutocompleteField
                                                                              key={`jobTaskItems[${index}].taskType`}
                                                                              name={`jobTaskItems[${index}].taskType`}
                                                                              label={translatedTextsObject.scoJobTaskType}
                                                                              required
                                                                              onChange={(val, evt) => {
                                                                                    handleTaskTypeDropdownChange(val, evt, index, 'taskType');
                                                                              }}
                                                                              disabled={checkTaskTypeDisabled(disabled, jobStatus, workFlow.id)}
                                                                              lookupProps={{
                                                                                    url: SCOJobApiUrls.GET_JOB_TASK_TYPE,
                                                                                    label: 'name',
                                                                                    value: 'code',
                                                                              }}
                                                                        />
                                                                  </CngGridItem>


                                                                  <CngGridItem xs={12} sm={4}>
                                                                        <CngTextField
                                                                              name={`jobTaskItems[${index}].taskDesc`}
                                                                              label={translatedTextsObject.scoJobTaskDescription}
                                                                              required
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>


                                                                  <CngGridItem xs={12} sm={4} >
                                                                        <JobStatusListComponent
                                                                              taskList={taskList}
                                                                              index={index}
                                                                              jobStatus={jobStatus}
                                                                              handleDropdownChange={handleDropdownChange}
                                                                              disabled={disabled}
                                                                              isClone={isClone}
                                                                        />

                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={workFlow.taskType === 'INV' ? false : true}>
                                                                        <CngSelectField
                                                                              name={`jobTaskItems[${index}].movementType.id`}
                                                                              label={"Movement Type"}
                                                                              required
                                                                              fetch={
                                                                                    {
                                                                                          url: SCOJobApiUrls.GET_MOVEMENT_TYPE,
                                                                                          textAccessor: "name",
                                                                                          idAccessor: "id"
                                                                                    }
                                                                              }
                                                                              disabled={workFlow.id === null && workFlow.id !== '' ? false : true}
                                                                        />
                                                                  </CngGridItem>

                                                                  {jobId !== undefined || jobStatus !== 'DRFT' || isClone ? 
                                                                        <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'timezoneId')}>
                                                                              <CngLookupAutocompleteField
                                                                                    name={`jobTaskItems[${index}].timezoneId`}
                                                                                    label={translatedTextsObject.scoJobTimeZone}
                                                                                    onChange={(val) => handleDropdownChange(val, index, 'timezoneId')}
                                                                                    disabled={disabled}
                                                                                    lookupProps={{
                                                                                          url: SCOJobApiUrls.GETTIMEZONE,
                                                                                          label: 'name',
                                                                                          value: 'id'
                                                                                    }}
                                                                              />

                                                                        </CngGridItem> : <br/>
                                                                        
                                                                  }
                                                                  {jobId !== undefined || jobStatus !== 'DRFT' || isClone ? 
                                                                        <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'localEventTimestamp')}>
                                                                              <CngDateTimeField
                                                                                    name={`jobTaskItems[${index}].localEventTimestamp`}
                                                                                    label={translatedTextsObject.scoJobLocalEventTimestamp}
                                                                                    format='YYYY-MM-DD HH:mm'
                                                                                    onChange={e => handleDateChange(e, index, 'localEventTimestamp')}
                                                                                    disabled={disabled}
                                                                              />
                                                                        </CngGridItem> : <br/>
                                                                        
                                                                  }
                                                                  {/* Update Inventory */}
                                                                  {(workFlow.taskType !== 'INV' || workFlow.taskStatus === 'NOTSTARTED') || jobId === undefined || jobStatus === 'DRFT' || jobStatus === undefined ? <br /> :
                                                                         workFlow.movementType !== null && workFlow.movementType.code !== 'OUT' ?
                                                                              <CngGridItem xs={12} sm={12}>
                                                                                    <InventoryDocument
                                                                                          showNotification={showNotification}
                                                                                          jobId={jobId}
                                                                                          selectedMoveType={selectedMoveType}
                                                                                          setSelectedMoveType={setSelectedMoveType}
                                                                                          movementType={movementType}
                                                                                          setMovementType={setMovementType}
                                                                                          taskStatus = {workFlow.taskStatus}
                                                                                          jobStatus={jobStatus}
                                                                                    />
                                                                              </CngGridItem>

                                                                              :

                                                                              // Toggle button
                                                                              <CngGridItem xs={12} sm={12}>
                                                                                    {/* {/* Tabs */}
                                                                                    <CngTabs
                                                                                          headerColor='primary'
                                                                                          tabs={[
                                                                                                {
                                                                                                      tabName: "Upload Excel",
                                                                                                      tabContent: (
                                                                                                            <InventoryDocument
                                                                                                                  showNotification={showNotification}
                                                                                                                  jobId={jobId}
                                                                                                                  selectedMoveType={selectedMoveType}
                                                                                                                  setSelectedMoveType={setSelectedMoveType}
                                                                                                                  movementType={movementType}
                                                                                                                  setMovementType={setMovementType}
                                                                                                                  taskStatus = {workFlow.taskStatus}
                                                                                                                  jobStatus={jobStatus}
                                                                                                            />
                                                                                                      )
                                                                                                },
                                                                                                {
                                                                                                      tabName: "Upload Form",
                                                                                                      tabContent: (
                                                                                                            // "Upload Form"
                                                                                                            <InventoryDetails
                                                                                                                  showNotification={showNotification}
                                                                                                                  jobId={jobId}
                                                                                                                  movementType={selectedMoveType}
                                                                                                                  taskStatus = {workFlow.taskStatus}
                                                                                                                  jobStatus={jobStatus}
                                                                                                            />

                                                                                                      )
                                                                                                }
                                                                                          ]}
                                                                                    />
                                                                              </CngGridItem>
                                                                  }

                                                            </Grid>
                                                      </AccordionSummary>
                                                      <AccordionDetails>

                                                            <JobTaskRateCardComponent
                                                                  taskList={taskList}
                                                                  index={index}
                                                                  handleRateCardChange={handleRateCardChange}
                                                                  disabled={disabled}
                                                                  setFieldValue={setFieldValue}
                                                                  handleRateOwnerAutoCompleteChange={handleRateOwnerAutoCompleteChange}
                                                            />

                                                      </AccordionDetails>

                                                      <AccordionDetails>
                                                            <Grid container xs={12} sm={12} spacing={2}>

                                                                  <CngGridItem xs={12} sm={12} >
                                                                        <h5>
                                                                              Other Info<br />
                                                                        </h5>
                                                                  </CngGridItem>
                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'estimatedDuration')}>
                                                                        <CngTextField
                                                                              type='number'
                                                                              name={`jobTaskItems[${index}].estimatedDuration`}
                                                                              label={translatedTextsObject.scoJobEstimatedDuration}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={true}
                                                                        />
                                                                  </CngGridItem>
                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'dueDate')}>
                                                                        <CngDateField
                                                                              name={`jobTaskItems[${index}].dueDate`}
                                                                              label={"Due Date"}
                                                                              format='YYYY-MM-DD'
                                                                        />
                                                                  </CngGridItem>
                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'userAssigned')}>
                                                                        <CngLookupAutocompleteField
                                                                              name={`jobTaskItems[${index}].userAssigned`}
                                                                              label={translatedTextsObject.scoJobUserAssign}
                                                                              disabled={disabled}
                                                                              onChange={(val) => { handleDropdownChange(val, index, 'userAssigned') }}
                                                                              lookupProps={{
                                                                                    url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
                                                                                    filters: [
                                                                                          {
                                                                                                field: 'partyId',
                                                                                                operator: EQUAL,
                                                                                                value: getPartyID()
                                                                                          }
                                                                                    ],
                                                                                    label: 'userProfile.loginId',
                                                                                    value: 'id'
                                                                              }}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={12} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'remarks')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].remarks`}
                                                                              label={translatedTextsObject.scoJobRemarks}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                              multiline
                                                                              rows={2}
                                                                              inputProps={{ maxLength: 200 }}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'recipientName')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].addresseeName`}
                                                                              label={fieldsLabelChangeForPickUp(workFlow.taskType, translatedTextsObject.scoJobRecipientName, translatedTextsObject.scoJobSenderName)}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'recipientAddress')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].addresseeAddress`}
                                                                              label={fieldsLabelChangeForPickUp(workFlow.taskType, translatedTextsObject.scoJobRecipientAddress, translatedTextsObject.scoJobSenderAddress)}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'recipientPostalCode')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].addresseePostalCode`}
                                                                              label={fieldsLabelChangeForPickUp(workFlow.taskType, translatedTextsObject.scoJobRecipientPostalCode, translatedTextsObject.scoJobSenderPostalCode)}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4}>

                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'recipientEmailAddress')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].addresseeEmailAddress`}
                                                                              label={fieldsLabelChangeForPickUp(workFlow.taskType, translatedTextsObject.scoJobRecipientEmailAdd, translatedTextsObject.scoJobSenderEmailAdd)}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'recipientContactNumber')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].addresseeContactNumber`}
                                                                              label={fieldsLabelChangeForPickUp(workFlow.taskType, translatedTextsObject.scoJobRecipientContactNum, translatedTextsObject.scoJobSenderContactNum)}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={12}>
                                                                        <Card className="inner-card-group">
                                                                              <CngGridItem xs={12} sm={12} style={{ paddingBottom: 20 }}>
                                                                                    <AccordionHeaderComponent hideMandatory={true} title={"Task Attributes"} />
                                                                              </CngGridItem>
                                                                              <AttributeDialog
                                                                                    reference={`jobTaskItems[${index}].jobTaskAttributes`}
                                                                                    isOpen={isOpen}
                                                                                    setFieldValue={setFieldValue}
                                                                                    attributeList={workFlow.jobTaskAttributes}
                                                                                    setIsOpen={setIsOpen}
                                                                                    attrOptions={attrOptions}
                                                                                    filteredAttrOptions={filteredAttrOptions}
                                                                                    setFilteredAttrConfigOptions={setFilteredAttrConfigOptions}
                                                                                    setNewFilteredOptions={setNewFilteredOptions}
                                                                                    index={jobTaskIndex}
                                                                                    taskList={taskList}
                                                                              />
                                                                              {workFlow.jobTaskAttributes != null ?
                                                                                    workFlow.jobTaskAttributes.map((attribute, attrIndex) => {
                                                                                          return (
                                                                                                <Box mb={2} width={`100%`} key={attribute.idKey}>
                                                                                                      <Grid container spacing={2}>
                                                                                                            <CngGridItem xs={12} sm={4}>
                                                                                                                  <CngTextField
                                                                                                                        name={`jobTaskItems[${index}].jobTaskAttributes[${attrIndex}].configDTO.attributeName`}
                                                                                                                        label={translatedTextsObject.scoAttribute}
                                                                                                                        disabled={true}
                                                                                                                  />
                                                                                                            </CngGridItem>
                                                                                                            <CngGridItem xs={12} sm={4}>
                                                                                                                  {displayAttrValueField(attribute, index, attrIndex, disabled)}
                                                                                                            </CngGridItem>
                                                                                                            <CngGridItem xs={12} sm={4} shouldHide={disabled}>
                                                                                                                  <Box style={{ textAlign: 'left' }}>
                                                                                                                        <IconButton aria-label="remove" disabled={false} onClick={() => removeTaskAttribute(index, attrIndex)}>
                                                                                                                              <DeleteIcon />
                                                                                                                        </IconButton>
                                                                                                                  </Box>
                                                                                                            </CngGridItem>
                                                                                                      </Grid>
                                                                                                </Box>
                                                                                          )
                                                                                    }) : <br />
                                                                              }


                                                                              < CngGridItem xs={12} sm={4} shouldHide={disabled}>
                                                                                    <IconButton style={{ width: 100, height: 50 }} aria-label="add" disabled={false}
                                                                                          onClick={() => openAttributeDialog(workFlow.jobTaskAttributes, index)}>
                                                                                          <AddCircleOutlineIcon
                                                                                                fontSize="small"
                                                                                                style={{ fontSize: 20, textAlign: 'center', color: 'grey' }}
                                                                                          />
                                                                                          <span style={{ fontSize: 15, textAlign: 'center' }}>Add Attribute</span>
                                                                                    </IconButton>
                                                                              </CngGridItem>
                                                                        </Card>
                                                                  </CngGridItem>


                                                                  <CngGridItem xs={12} sm={12}>
                                                                        <Card className="inner-card-group">
                                                                              <CngGridItem xs={12} sm={12} style={{ paddingBottom: 20 }}>
                                                                                    <AccordionHeaderComponent hideMandatory={true} title={translatedTextsObject.scoContactListTitle} />
                                                                              </CngGridItem>

                                                                              <CngGridItem xs={12} sm={12}>
                                                                                    {workFlow.jobTaskContact != null && workFlow.jobTaskContact.jobTaskContactEmailList != null ?
                                                                                          workFlow.jobTaskContact.jobTaskContactEmailList.map((emailAddress, indexEmail) => {
                                                                                                return (
                                                                                                      <Grid container xs={12} sm={12} spacing={2} style={{ paddingBottom: 10, paddingLeft: 5 }} key={emailAddress.id}>
                                                                                                            <CngGridItem xs={4} sm={4} pt={2} style={{ paddingBottom: 10 }}>
                                                                                                                  <CngTextField
                                                                                                                        type='text'
                                                                                                                        disabled={disabled}
                                                                                                                        onChange={e => handleEmailInputChange(e, workFlow.jobTaskContact.jobTaskContactEmailList, indexEmail, index)}
                                                                                                                        name={`jobTaskItems[${index}].jobTaskContact.jobTaskContactEmailList[${indexEmail}].emailAddress`}
                                                                                                                        label={translatedTextsObject.scoJobTaskContactEmail}
                                                                                                                        required
                                                                                                                        error={getFieldErrorForEmail(index, `jobTaskItems.jobTaskContact.jobTaskContactEmailList.emailAddress`, indexEmail)}
                                                                                                                  />
                                                                                                            </CngGridItem>
                                                                                                            <CngGridItem xs={4} sm={4} pt={2} style={{ paddingBottom: 10 }} shouldHide={disabled}>
                                                                                                                  <IconButton aria-label="remove" >
                                                                                                                        <DeleteIcon onClick={() => handleRemoveEmail(index, indexEmail)} />
                                                                                                                  </IconButton>
                                                                                                            </CngGridItem>
                                                                                                      </Grid>
                                                                                                )
                                                                                          }) : <br />}
                                                                                    <br />

                                                                                    <CngGridItem xs={12} sm={4} shouldHide={disabled}>
                                                                                          <IconButton style={{ width: 100, height: 50 }} aria-label="add" disabled={disabled}
                                                                                                onClick={() => addEmail(index, taskList[index])}>
                                                                                                <AddCircleOutlineIcon
                                                                                                      fontSize="small"
                                                                                                      style={{ fontSize: 20, textAlign: 'center', color: 'grey' }}
                                                                                                />
                                                                                                <span style={{ fontSize: 15, textAlign: 'center' }}>Add Contact</span>
                                                                                          </IconButton>
                                                                                    </CngGridItem>
                                                                              </CngGridItem>
                                                                        </Card>
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={12}>
                                                                        <JobTaskStatusHistoryComponent
                                                                              taskId={workFlow.id}
                                                                              jobStatus={jobStatus}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={12} style={{ textAlign: "center" }} shouldHide={shouldHideTask(workFlow)}>
                                                                        {displayDeleteRestoreBtn(isActiveTask, index, onDeleteTask, onRestoreTask, translatedTextsObject)}
                                                                  </CngGridItem>
                                                            </Grid>
                                                      </AccordionDetails>

                                                </Accordion>
                                                <AccordionFooterControlComponent
                                                      handleAccordionExpand={handleWorkFlowListDetailsExpand(index)}
                                                      expanded={workFlow.workFlowItemExpanded}
                                                      footerText={translatedTextsObject.scoJobAdditionalTaskDetails}
                                                />
                                          </Card>
                                    </CngGridItem >
                              </Grid >
                        )
                  } else if (isActiveTask == false && workFlow.isDeleted == true) {
                        return (
                              <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={12} sm={12}>
                                          <Card>
                                                <Accordion expanded={workFlow.workFlowItemExpanded} key={workFlow.workFlowAccordionId}>
                                                      <AccordionSummary aria-controls="jobTaskDetailsComponentContent" id={'jobTaskDetailsComponentDeletedTaskKey'}>
                                                            <Grid container xs={12} sm={12} spacing={2}>
                                                                  <CngGridItem xs={12} sm={4}>
                                                                        <CngSelectField
                                                                              type='number'
                                                                              name={`jobTaskItems[${index}].taskSequence`}
                                                                              label={translatedTextsObject.scoJobTaskListTaskSequence}
                                                                              required
                                                                              onChange={(val, evt) => handleTaskSequenceChange(val, evt, index)}
                                                                              disabled={disabled}
                                                                              items={taskSequenceOptions}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4}>
                                                                        <CngLookupAutocompleteField
                                                                              key={`jobTaskItems[${index}].taskType`}
                                                                              name={`jobTaskItems[${index}].taskType`}
                                                                              label={translatedTextsObject.scoJobTaskType}
                                                                              required
                                                                              onChange={(val, evt) => {
                                                                                    handleTaskTypeDropdownChange(val, evt, index, 'taskType');
                                                                              }}
                                                                              disabled={checkTaskTypeDisabled(disabled, jobStatus, workFlow.id)}
                                                                              lookupProps={{
                                                                                    url: SCOJobApiUrls.GET_JOB_TASK_TYPE,
                                                                                    label: 'name',
                                                                                    value: 'code',
                                                                              }}
                                                                        />
                                                                  </CngGridItem>


                                                                  <CngGridItem xs={12} sm={4}>
                                                                        <CngTextField
                                                                              name={`jobTaskItems[${index}].taskDesc`}
                                                                              label={translatedTextsObject.scoJobTaskDescription}
                                                                              required
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>


                                                                  <CngGridItem xs={12} sm={4} >
                                                                        <JobStatusListComponent
                                                                              taskList={taskList}
                                                                              index={index}
                                                                              jobStatus={jobStatus}
                                                                              handleDropdownChange={handleDropdownChange}
                                                                              disabled={disabled}
                                                                              isClone={isClone}
                                                                        />

                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={workFlow.taskType === 'INV' ? false : true}>
                                                                        <CngSelectField
                                                                              name={`jobTaskItems[${index}].movementType`}
                                                                              label={"Movement Type"}
                                                                              required
                                                                              fetch={
                                                                                    {
                                                                                          url: SCOJobApiUrls.GET_MOVEMENT_TYPE,
                                                                                          textAccessor: "name",
                                                                                          idAccessor: "id"
                                                                                    }
                                                                              }
                                                                              disabled={workFlow.id === null && workFlow.id !== '' ? false : true}
                                                                        />
                                                                  </CngGridItem>


                                                                  {jobId !== undefined || jobStatus !== 'DRFT' || isClone ? 
                                                                        <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'timezoneId')}>
                                                                              <CngLookupAutocompleteField
                                                                                    name={`jobTaskItems[${index}].timezoneId`}
                                                                                    label={translatedTextsObject.scoJobTimeZone}
                                                                                    onChange={(val) => handleDropdownChange(val, index, 'timezoneId')}
                                                                                    disabled={disabled}
                                                                                    lookupProps={{
                                                                                          url: SCOJobApiUrls.GETTIMEZONE,
                                                                                          label: 'name',
                                                                                          value: 'id'
                                                                                    }}
                                                                              />

                                                                        </CngGridItem> : <br />
                                                                        
                                                                  }
                                                                  {jobId !== undefined || jobStatus !== 'DRFT' || isClone ? 
                                                                        <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'localEventTimestamp')}>
                                                                              <CngDateTimeField
                                                                                    name={`jobTaskItems[${index}].localEventTimestamp`}
                                                                                    label={translatedTextsObject.scoJobLocalEventTimestamp}
                                                                                    format='YYYY-MM-DD HH:mm'
                                                                                    onChange={e => handleDateChange(e, index, 'localEventTimestamp')}
                                                                                    disabled={disabled}
                                                                              />
                                                                        </CngGridItem> : <br />
                                                                        
                                                                  }


                                                                  {(workFlow.taskType !== 'INV' || workFlow.taskStatus !== 'PROGRESS') || jobId === undefined || jobStatus === 'DRFT'  || jobStatus === undefined ? <br /> :
                                                                        <CngGridItem xs={12} sm={12} >
                                                                              <InventoryDocument
                                                                                    showNotification={showNotification}
                                                                                    jobId={jobId}
                                                                                    selectedMoveType={selectedMoveType}
                                                                                    setSelectedMoveType={setSelectedMoveType}
                                                                                    movementType={movementType}
                                                                                    setMovementType={setMovementType}
                                                                                    jobStatus={jobStatus}
                                                                              />
                                                                        </CngGridItem>


                                                                  }
                                                            </Grid>
                                                      </AccordionSummary>
                                                      <AccordionDetails>

                                                            <JobTaskRateCardComponent
                                                                  taskList={taskList}
                                                                  index={index}
                                                                  handleRateCardChange={handleRateCardChange}
                                                                  disabled={disabled}
                                                                  setFieldValue={setFieldValue}
                                                            />

                                                      </AccordionDetails>

                                                      <AccordionDetails>
                                                            <Grid container xs={12} sm={12} spacing={2}>
                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'estimatedDuration')}>
                                                                        <CngTextField
                                                                              type='number'
                                                                              name={`jobTaskItems[${index}].estimatedDuration`}
                                                                              label={translatedTextsObject.scoJobEstimatedDuration}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'userAssigned')}>
                                                                        <CngLookupAutocompleteField
                                                                              name={`jobTaskItems[${index}].userAssigned`}
                                                                              label={translatedTextsObject.scoJobUserAssign}
                                                                              disabled={disabled}
                                                                              onChange={(val) => handleDropdownChange(val, index, 'userAssigned')}
                                                                              lookupProps={{
                                                                                    url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
                                                                                    filters: [
                                                                                          {
                                                                                                field: 'partyId',
                                                                                                operator: EQUAL,
                                                                                                value: getPartyID()
                                                                                          }
                                                                                    ],
                                                                                    label: 'userProfile.loginId',
                                                                                    value: 'id'
                                                                              }}
                                                                        />
                                                                  </CngGridItem>
                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'remarks')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].remarks`}
                                                                              label={translatedTextsObject.scoJobRemarks}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'recipientName')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].addresseeName`}
                                                                              label={fieldsLabelChangeForPickUp(workFlow.taskType, translatedTextsObject.scoJobRecipientName, translatedTextsObject.scoJobSenderName)}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'recipientAddress')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].addresseeAddress`}
                                                                              label={fieldsLabelChangeForPickUp(workFlow.taskType, translatedTextsObject.scoJobRecipientAddress, translatedTextsObject.scoJobSenderAddress)}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'recipientPostalCode')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].addresseePostalCode`}
                                                                              label={fieldsLabelChangeForPickUp(workFlow.taskType, translatedTextsObject.scoJobRecipientPostalCode, translatedTextsObject.scoJobSenderPostalCode)}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4}>

                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'recipientEmailAddress')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].addresseeEmailAddress`}
                                                                              label={fieldsLabelChangeForPickUp(workFlow.taskType, translatedTextsObject.scoJobRecipientEmailAdd, translatedTextsObject.scoJobSenderEmailAdd)}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={4} shouldHide={fieldsShouldHideTaskType(workFlow.taskType, 'recipientContactNumber')}>
                                                                        <CngTextField
                                                                              type='text'
                                                                              name={`jobTaskItems[${index}].addresseeContactNumber`}
                                                                              label={fieldsLabelChangeForPickUp(workFlow.taskType, translatedTextsObject.scoJobRecipientContactNum, translatedTextsObject.scoJobSenderContactNum)}
                                                                              onChange={e => handleInputChange(e, index)}
                                                                              disabled={disabled}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={12}>
                                                                        <Card className="inner-card-group">
                                                                              <CngGridItem xs={12} sm={12} style={{ paddingBottom: 20 }}>
                                                                                    <AccordionHeaderComponent hideMandatory={true} title={"Task Attributes"} />
                                                                              </CngGridItem>
                                                                              <AttributeDialog
                                                                                    reference={`jobTaskItems[${index}].jobTaskAttributes`}
                                                                                    isOpen={isOpen}
                                                                                    setFieldValue={setFieldValue}
                                                                                    attributeList={workFlow.jobTaskAttributes}
                                                                                    setIsOpen={setIsOpen}
                                                                                    attrOptions={attrOptions}
                                                                                    filteredAttrOptions={filteredAttrOptions}
                                                                                    setFilteredAttrConfigOptions={setFilteredAttrConfigOptions}
                                                                                    setNewFilteredOptions={setNewFilteredOptions}
                                                                                    index={jobTaskIndex}
                                                                                    taskList={taskList}
                                                                              />
                                                                              {workFlow.jobTaskAttributes != null ?
                                                                                    workFlow.jobTaskAttributes.map((attribute, attrIndex) => {
                                                                                          return (
                                                                                                <Box mb={2} width={`100%`} key={attribute.idKey}>
                                                                                                      <Grid container spacing={2}>
                                                                                                            <CngGridItem xs={12} sm={4}>
                                                                                                                  <CngTextField
                                                                                                                        name={`jobTaskItems[${index}].jobTaskAttributes[${attrIndex}].configDTO.attributeName`}
                                                                                                                        label={translatedTextsObject.scoAttribute}
                                                                                                                        disabled={disabled}
                                                                                                                  />
                                                                                                            </CngGridItem>
                                                                                                            <CngGridItem xs={12} sm={4}>
                                                                                                                  {displayAttrValueField(attribute, index, attrIndex, disabled)}
                                                                                                            </CngGridItem>
                                                                                                            <CngGridItem xs={12} sm={4} shouldHide={disabled}>
                                                                                                                  <Box style={{ textAlign: 'left' }}>
                                                                                                                        <IconButton aria-label="remove" disabled={false} onClick={() => removeTaskAttribute(index, attrIndex)}>
                                                                                                                              <DeleteIcon />
                                                                                                                        </IconButton>
                                                                                                                  </Box>
                                                                                                            </CngGridItem>
                                                                                                      </Grid>
                                                                                                </Box>
                                                                                          )
                                                                                    }) : <br />
                                                                              }


                                                                              < CngGridItem xs={12} sm={4} shouldHide={disabled}>
                                                                                    <IconButton style={{ width: 100, height: 50 }} aria-label="add" disabled={false}
                                                                                          onClick={() => openAttributeDialog(workFlow.jobTaskAttributes, index)}>
                                                                                          <AddCircleOutlineIcon
                                                                                                fontSize="small"
                                                                                                style={{ fontSize: 20, textAlign: 'center', color: 'grey' }}
                                                                                          />
                                                                                          <span style={{ fontSize: 15, textAlign: 'center' }}>Add Attribute</span>
                                                                                    </IconButton>
                                                                              </CngGridItem>
                                                                        </Card>
                                                                  </CngGridItem>


                                                                  <CngGridItem xs={12} sm={12}>
                                                                        <Card className="inner-card-group">
                                                                              <CngGridItem xs={12} sm={12} style={{ paddingBottom: 20 }}>
                                                                                    <AccordionHeaderComponent hideMandatory={true} title={translatedTextsObject.scoContactListTitle} />
                                                                              </CngGridItem>

                                                                              <CngGridItem xs={12} sm={12}>
                                                                                    {workFlow.jobTaskContact != null && workFlow.jobTaskContact.jobTaskContactEmailList != null ?
                                                                                          workFlow.jobTaskContact.jobTaskContactEmailList.map((emailAddress, indexEmail) => {
                                                                                                return (
                                                                                                      <Grid container xs={12} sm={12} spacing={2} style={{ paddingBottom: 10, paddingLeft: 5 }} key={emailAddress.id}>
                                                                                                            <CngGridItem xs={4} sm={4} pt={2} style={{ paddingBottom: 10 }}>
                                                                                                                  <CngTextField
                                                                                                                        type='text'
                                                                                                                        disabled={disabled}
                                                                                                                        onChange={e => handleEmailInputChange(e, workFlow.jobTaskContact.jobTaskContactEmailList, indexEmail, index)}
                                                                                                                        name={`jobTaskItems[${index}].jobTaskContact.jobTaskContactEmailList[${indexEmail}].emailAddress`}
                                                                                                                        label={translatedTextsObject.scoJobTaskContactEmail}
                                                                                                                        required
                                                                                                                        error={getFieldErrorForEmail(index, `jobTaskItems.jobTaskContact.jobTaskContactEmailList.emailAddress`, indexEmail)}
                                                                                                                  />
                                                                                                            </CngGridItem>
                                                                                                            <CngGridItem xs={4} sm={4} pt={2} style={{ paddingBottom: 10 }} shouldHide={disabled}>
                                                                                                                  <IconButton aria-label="remove" >
                                                                                                                        <DeleteIcon onClick={() => handleRemoveEmail(index, indexEmail)} />
                                                                                                                  </IconButton>
                                                                                                            </CngGridItem>
                                                                                                      </Grid>
                                                                                                )
                                                                                          }) : <br />}
                                                                                    <br />

                                                                                    <CngGridItem xs={12} sm={4} shouldHide={disabled}>
                                                                                          <IconButton style={{ width: 100, height: 50 }} aria-label="add" disabled={disabled}
                                                                                                onClick={() => addEmail(index, taskList[index])}>
                                                                                                <AddCircleOutlineIcon
                                                                                                      fontSize="small"
                                                                                                      style={{ fontSize: 20, textAlign: 'center', color: 'grey' }}
                                                                                                />
                                                                                                <span style={{ fontSize: 15, textAlign: 'center' }}>Add Contact</span>
                                                                                          </IconButton>
                                                                                    </CngGridItem>
                                                                              </CngGridItem>
                                                                        </Card>
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={12}>
                                                                        <JobTaskStatusHistoryComponent
                                                                              taskId={workFlow.id}
                                                                              jobStatus={jobStatus}
                                                                        />
                                                                  </CngGridItem>

                                                                  <CngGridItem xs={12} sm={12} style={{ textAlign: "center" }} shouldHide={shouldHideTask(workFlow)} >
                                                                        {displayDeleteRestoreBtn(isActiveTask, index, onDeleteTask, onRestoreTask, translatedTextsObject)}
                                                                  </CngGridItem>
                                                            </Grid>
                                                      </AccordionDetails>

                                                </Accordion>
                                                <AccordionFooterControlComponent
                                                      handleAccordionExpand={handleWorkFlowListDetailsExpand(index)}
                                                      expanded={workFlow.workFlowItemExpanded}
                                                      footerText={translatedTextsObject.scoJobAdditionalTaskDetails}
                                                />
                                          </Card>
                                    </CngGridItem >
                              </Grid >
                        )
                  } else {
                        return null
                  }
            })
      )
}

export default JobTaskDetailsComponents