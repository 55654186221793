import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid
} from '@material-ui/core'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useState, useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'
import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import KebabMenuButton from 'src/components/button/KebabMenuButton'

const {
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    }
  }
} = components

const DocumentDetailsComponents = (props) => {
  const { id, reference, uploadedFiles, isDraft, onClickDelete } = props
  const [files, setFiles] = useState([])
  const { securedSendRequest } = useServices()
  const [errorMsg, setErrorMsg] = useState('')



  const onClickDownload = (id, reference) => {
    if (reference == 'input') {
      securedSendRequest.execute(
        "POST",
        SCOJobApiUrls.DOWNLOAD_INPUT_DOC_CONVERSION_DOCUMENTS,
        {
          docConversionId: id
        },
        (data) => {
          var link = data.data.content[0].fileLink
          window.open(link, '_blank').focus()

        },
        (error) => {
          console.log(error)
        },
        onComplete()
      )
    } else if (reference == 'output') {
      securedSendRequest.execute(
        "POST",
        SCOJobApiUrls.DOWNLOAD_OUTPUT_DOC_CONVERSION_DOCUMENTS,
        {
          docConversionId: id
        },
        (data) => {
          var link = data.data.content[0].fileLink
          window.open(link, '_blank').focus()
        },
        (error) => {
          console.log(error)
        },
        onComplete()

      )
    }
  }

  function onComplete() {
    console.log('complete')
  }



  useEffect(() => {
    if (uploadedFiles !== null && uploadedFiles !== undefined && uploadedFiles.length > 0) {
      setFiles(uploadedFiles)
      if (reference == 'output') {
        setErrorMsg(uploadedFiles[0].errorMsg)
      }
    }
  }, [uploadedFiles])





  if (reference == 'input') {
    return (

      <Grid container spacing={2}>

        <Grid Item xs={12} sm={12}>
          <TableContainer component={Paper}>
            <Table id="table">
              <TableBody>
                {files.map((elem, i) => {
                  return (<TableRow key={`row-${reference}-${i}`}>

                    <TableCell className={`file-format`}> <FileCopyIcon /></TableCell>
                    <TableCell>
                      <DocumentInfoDecoratorComponent name={elem.fileName} size={elem.fileSize} />
                    </TableCell>

                    <TableCell align='right'>
                      <KebabMenuButton
                        hasDownload={isDraft == true ? false : true}
                        onDownloadClick={() => onClickDownload(elem.id, reference)}
                        hasDelete={isDraft == true ? true : false}
                        onDeleteClick={() => onClickDelete(i)}
                      />
                    </TableCell>


                  </TableRow>)
                })
                }
              </TableBody >
            </Table>
          </TableContainer>




        </Grid>
      </Grid >

    )
  } else if (reference == 'output') {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <p style={{ color: '#FF808B' }}>
            {errorMsg}
          </p>

        </Grid>
        <Grid Item xs={12} sm={12}>
          <TableContainer component={Paper}>
            <Table id="table">
              <TableBody>
                {files.map((elem, i) => {
                  if (elem.fileName != "") {
                    return (
                      <TableRow key={`row-${reference}-${i}`}>

                        <TableCell className={`file-format`}> <FileCopyIcon /></TableCell>
                        <TableCell>
                          <DocumentInfoDecoratorComponent name={elem.fileName} size={elem.fileSize} />
                        </TableCell>

                        <TableCell align='right'>
                          <KebabMenuButton
                            hasDownload={true}
                            onDownloadClick={() => onClickDownload(elem.id, reference)}
                          />
                        </TableCell>


                      </TableRow>)
                  }
                }
                )

                }
              </TableBody >
            </Table>
          </TableContainer>
        </Grid>
      </Grid >
    )

  } else {
    return (<br />)
  }

}

export default DocumentDetailsComponents
