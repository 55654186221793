import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_SCOJob'
import TablePage from './TablePage'
import AddPage from './AddPage'
import ViewPage from './ViewPage'
import EditPage from './EditPage'

import SCODocumentTranslationText from './SCODocumentTranslationText'


// * Module Title---------------------------------------------------------------
function ModuleTitle() {
      const translatedTextsObject = SCODocumentTranslationText();
      return translatedTextsObject.moduleTitle;
}

function WrappedTablePage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <TablePage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}



function WrappedAddPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <AddPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedViewPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <ViewPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}


function WrappedEditPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <EditPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}


export {
      WrappedTablePage as TablePage,
      WrappedAddPage as AddPage,
      WrappedViewPage as ViewPage,
      WrappedEditPage as EditPage
}