import Namespace from 'src/constants/locale/Namespace'
import SCOJob from 'src/constants/locale/key/SCOJob'
import { useTranslation } from 'cng-web-lib'


const SCOJobTranslationText = () => {
      const { translate } = useTranslation([
            Namespace.UI_COMPONENT,
            Namespace.SCO_JOB_CONFIG
      ])

      let scoJobTitle = translate(
            Namespace.SCO_JOB,
            SCOJob.MY_JOB
      )

      let scoDraftJobTitle = translate(
            Namespace.SCO_JOB,
            SCOJob.DRAFT_JOB
      )

      let scoJobId = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_ID
      )

      let scoJobType = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_TYPE
      )

      let scoJobDescription = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_DESCRIPTION
      )

      let scoShipRefNO = translate(
            Namespace.SCO_JOB,
            SCOJob.SHIPPER_REF_NO
      )

      let scoCompleteness = translate(
            Namespace.SCO_JOB,
            SCOJob.COMPLETENESS
      )

      let scoJobDetailsTitle = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_DETAILS
      )

      let scoJobTaskListTitle = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_TASK_LIST
      )

      let scoJobTaskListTaskSequence = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_CONFIGURATION_TASK_SEQUENCE_TITLE
      )

      let scoJobTaskType = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_CONFIGURATION_TASK_TYPE
      )

      let scoJobTaskDescription = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_CONFIGURATION_TASK_DESCRIPTION
      )
      //---------------------
      let scoJobStatus = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_STATUS
      )
      let scoJobStatuses = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_STATUSES
      )
      let scoJobTimeZone = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_TIMEZONE
      )
      let scoJobUserAssign = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_USER_ASSIGN
      )
      let scoJobRemarks = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_REMARKS
      )
      let scoJobRecipientName = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_RECIPIENT_NAME
      )
      let scoJobRecipientAddress = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_RECIPIENT_ADDRESS
      )
      let scoJobRecipientPostalCode = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_RECIPIENT_POSTAL_CODE
      )
      let scoJobRecipientEmailAdd = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_RECIPIENT_EMAILL_ADDRESS
      )
      let scoJobRecipientContactNum = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_RECIPIENT_CONTACT_NUM
      )

      let scoJobTaskContactEmail = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_CONFIGURATION_EMAIL_ADDRESS
      )

      let scoJobAdditionalTaskDetails = translate(
            Namespace.SCO_JOB,
            SCOJob.ADDITIONAL_TASK_DETAILS
      )

      let scoJobEstimatedDuration = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_CONFIGURATION_ESTIMATED_DURATION
      )

      let scoJobConfiguration = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_CONFIGURATION_TITLE
      )

      let scoJobOrigin = translate(
            Namespace.SCO_JOB,
            SCOJob.ORIGIN
      )

      let scoJobDestination = translate(
            Namespace.SCO_JOB,
            SCOJob.DESTINATION
      )

      let scoJobCreatedDate = translate(
            Namespace.SCO_JOB,
            SCOJob.CREATED_DATE
      )

      let scoJobDocumentTitle = translate(
            Namespace.SCO_JOB,
            SCOJob.DOCUMENTS
      )

      let scoJobDocumentInstructions = translate(
            Namespace.SCO_JOB,
            SCOJob.DOCUMENTS_INSTRUCTION
      )

      let scoJobDocumentAttach = translate(
            Namespace.SCO_JOB,
            SCOJob.ATTACH_DOCUMENT
      )

      let scoJobLocalEventTimestamp = translate(
            Namespace.SCO_JOB,
            SCOJob.LOCAL_EVENT_TIMESTAMP
      )

      let scoJobUpdatedBy = translate(
            Namespace.SCO_JOB,
            SCOJob.UPDATED_BY
      )

      let scoJobDateAndTime = translate(
            Namespace.SCO_JOB,
            SCOJob.DATE_AND_TIME
      )

      let scoJobEventTimezone = translate(
            Namespace.SCO_JOB,
            SCOJob.EVENT_TIMEZONE
      )

      let scoAddJobTask = translate(
            Namespace.SCO_JOB,
            SCOJob.ADD_JOB_TASK
      )

      let scoJobTaskStatus = translate(
            Namespace.SCO_JOB,
            SCOJob.TASK_STATUS
      )

      let scoJobDiscardTitle = translate(
            Namespace.SCO_JOB,
            SCOJob.DISCARD_JOB_TITLE
      )

      let scoJobDiscardChanges = translate(
            Namespace.SCO_JOB,
            SCOJob.DISCARD_JOB
      )

      let scoContactListTitle = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_CONFIGURATION_CONTACT_LIST
      )

      let scoFormError = translate(
            Namespace.SCO_JOB,
            SCOJob.FORM_ERROR
      )

      let scoCreatedBy = translate(
            Namespace.SCO_JOB,
            SCOJob.CREATED_BY
      )

      let scoIsDraft = translate(
            Namespace.SCO_JOB,
            SCOJob.IS_DRAFT
      )

      let scoPortOfLoading = translate(
            Namespace.SCO_JOB,
            SCOJob.PORT_OF_LOADING
      )

      let scoPortOfDischarge = translate(
            Namespace.SCO_JOB,
            SCOJob.PORT_OF_DISCHARGE
      )

      let scoCustomer = translate(
            Namespace.SCO_JOB,
            SCOJob.CUSTOMER
      )

      let scoRateCard = translate(
            Namespace.SCO_JOB,
            SCOJob.RATE_CARD
      )

      let scoRateOwner = translate(
            Namespace.SCO_JOB,
            SCOJob.RATE_OWNER
      )

      let scoJobAttribute = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_ATTRIBUTE
      )

      let scoAttribute = translate(
            Namespace.SCO_JOB,
            SCOJob.ATTRIBUTE
      )

      let scoSelectAttribute = translate(
            Namespace.SCO_JOB,
            SCOJob.SELECT_ATTRIBUTE
      )

      let scoAttributeValue = translate(
            Namespace.SCO_JOB,
            SCOJob.ATTRIBUTE_VALUE
      )


      let scoJobSenderName = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_SENDER_NAME
      )
      let scoJobSenderAddress = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_SENDER_ADDRESS
      )
      let scoJobSenderPostalCode = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_SENDER_POSTAL_CODE
      )
      let scoJobSenderEmailAdd = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_SENDER_EMAILL_ADDRESS
      )
      let scoJobSenderContactNum = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_SENDER_CONTACT_NUM
      )

      let scoJobDeleteTaskConfirm = translate(
            Namespace.SCO_JOB,
            SCOJob.CONFIRM_DELETE_TASK
      )

      let scoJobRestoreTaskConfirm = translate(
            Namespace.SCO_JOB,
            SCOJob.CONFIRM_RESTORE_TASK
      )

      let scoJobDeleteTask = translate(
            Namespace.SCO_JOB,
            SCOJob.DELETE_TASK
      )

      let scoJobRestoreTask = translate(
            Namespace.SCO_JOB,
            SCOJob.RESTORE_TASK
      )

      let scoJobActiveTasks = translate(
            Namespace.SCO_JOB,
            SCOJob.ACTIVE_TASKS
      )

      let scoJobDeletedTasks = translate(
            Namespace.SCO_JOB,
            SCOJob.DELETED_TASKS
      )

      let scoJobDownloadTemplate = translate(
            Namespace.SCO_JOB,
            SCOJob.DOWNLOAD_TEMPLATE
      )
      let scoActive = translate(
            Namespace.SCO_JOB,
            SCOJob.ACTIVE
      )
      let scoCompleted = translate(
            Namespace.SCO_JOB,
            SCOJob.COMPLETED
      )

      let scoMovementType = translate(
            Namespace.SCO_JOB,
            SCOJob.MOVEMENT_TYPE
      )

      let skuNum = translate(
            Namespace.SCO_JOB,
            SCOJob.SKU_NUMBER
      )


      let skuDesc = translate(
            Namespace.SCO_JOB,
            SCOJob.SKU_DESC
      )

      let lotNum = translate(
            Namespace.SCO_JOB,
            SCOJob.LOT_NUMBER
      )

      let updateInvFormTitle = translate(
            Namespace.SCO_JOB,
            SCOJob.UPDATE_INV_FORM_TITLE
      )

      let buyer = translate(
            Namespace.SCO_JOB,
            SCOJob.BUYER
      )

      let fromDutyStatus = translate(
            Namespace.SCO_JOB,
            SCOJob.FROM_DUTY_STATUS
      )

      let fromPermitType = translate(
            Namespace.SCO_JOB,
            SCOJob.FROM_PERMIT_TYPE
      )

      let location = translate(
            Namespace.SCO_JOB,
            SCOJob.LOCATION
      )

      let operator = translate(
            Namespace.SCO_JOB,
            SCOJob.OPERATOR
      )

      let quantity = translate(
            Namespace.SCO_JOB,
            SCOJob.QUANTITY
      )

      let uom_capital = translate(
            Namespace.SCO_JOB,
            SCOJob.UOM_CAPITAL
      )

      let totalQuantity = translate(
            Namespace.SCO_JOB,
            SCOJob.TOTAL_QUANTITY
      )
      let availableQtyLoose = translate(
            Namespace.SCO_JOB,
            SCOJob.AVAILABLE_QUANTITY_LOOSE
      )
      let scoRefNo = translate(
            Namespace.SCOJob,
            SCOJob.REFERENCE_NUMBER
      )
      let outsourceTo = translate(
            Namespace.SCOJob,
            SCOJob.OUTSOURCE_TO
      )
      let scoJobTaskCompleted= translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_TASK_COMPLETED
      )
      let scoCancelled= translate(
            Namespace.SCO_JOB,
            SCOJob.CANCELLED
      )
      let scoCancelJob= translate(
            Namespace.SCO_JOB,
            SCOJob.CANCEL_JOB
      )
      let scoCloneJob= translate(
            Namespace.SCO_JOB,
            SCOJob.CLONE_JOB
      )

      return {
            scoJobTitle,
            scoDraftJobTitle,
            scoJobId,
            scoJobType,
            scoJobDescription,
            scoShipRefNO,
            scoCompleteness,
            scoJobDetailsTitle,
            scoJobTaskListTitle,
            scoJobTaskListTaskSequence,
            scoJobTaskType,
            scoJobTaskDescription,
            scoJobStatus,
            scoJobStatuses,
            scoJobTimeZone,
            scoJobUserAssign,
            scoJobRemarks,
            scoJobRecipientName,
            scoJobRecipientAddress,
            scoJobRecipientPostalCode,
            scoJobRecipientEmailAdd,
            scoJobRecipientContactNum,
            scoJobTaskContactEmail,
            scoJobAdditionalTaskDetails,
            scoJobEstimatedDuration,
            scoJobConfiguration,
            scoJobOrigin,
            scoJobDestination,
            scoJobCreatedDate,
            scoJobDocumentTitle,
            scoJobDocumentInstructions,
            scoJobDocumentAttach,
            scoJobLocalEventTimestamp,
            scoJobUpdatedBy,
            scoJobDateAndTime,
            scoJobEventTimezone,
            scoAddJobTask,
            scoJobTaskStatus,
            scoJobDiscardTitle,
            scoJobDiscardChanges,
            scoContactListTitle,
            scoFormError,
            scoCreatedBy,
            scoIsDraft,
            scoPortOfLoading,
            scoPortOfDischarge,
            scoCustomer,
            scoRateCard,
            scoRateOwner,
            scoJobAttribute,
            scoAttribute,
            scoSelectAttribute,
            scoAttributeValue,
            scoJobSenderName,
            scoJobSenderAddress,
            scoJobSenderPostalCode,
            scoJobSenderEmailAdd,
            scoJobSenderContactNum,
            scoJobDeleteTaskConfirm,
            scoJobRestoreTaskConfirm,
            scoJobDeleteTask,
            scoJobRestoreTask,
            scoJobActiveTasks,
            scoJobDeletedTasks,
            scoJobDownloadTemplate,
            scoActive,
            scoCompleted,
            scoMovementType,
            skuNum,
            skuDesc,
            lotNum,
            updateInvFormTitle,
            buyer,
            fromDutyStatus,
            fromPermitType,
            location,
            operator,
            uom_capital,
            quantity,
            totalQuantity,
            availableQtyLoose,
            scoRefNo,
            outsourceTo,
            scoJobTaskCompleted,
            scoCancelled,
            scoCancelJob,
            scoCloneJob
      }
}

export default SCOJobTranslationText