import { Typography, Button } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import SCOJobTranslationText from '../SCOJobTranslationText'

const {
    CngDialog
} = components

const DialogConfirmation = ({ dialogOpen, taskDeleteOrRestore, setDialogOpen, procDeleteTask, procRestoreTask, taskIndex }) => {
    const translatedTextsObject = SCOJobTranslationText()


    return (
        <CngDialog
            open={dialogOpen}
            fullWidth
            maxWidth='sm'
            dialogContent={
                <>
                    <Typography color='textPrimary' style={{ whiteSpace: 'pre-line', textAlign: 'center', fontWeight: 'bold' }}>
                        {taskDeleteOrRestore ? translatedTextsObject.scoJobDeleteTaskConfirm : translatedTextsObject.scoJobRestoreTaskConfirm}
                    </Typography>
                </>
            }

            dialogAction={
                <div>
                    {
                        taskDeleteOrRestore ?
                            <Button variant='contained' color='primary' style={{ marginRight: '1em' }}
                                onClick={() => {
                                    procDeleteTask(taskIndex)
                                    setDialogOpen(false)
                                }}
                            >
                                Confirm
                            </Button>
                            :
                            <Button variant='contained' color='primary' style={{ marginRight: '1em' }}
                                onClick={() => {
                                    procRestoreTask(taskIndex)
                                    setDialogOpen(false)
                                }}
                            >
                                Confirm
                            </Button>
                    }
                    <Button variant='contained'
                        onClick={() => {
                            setDialogOpen(false)
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            }


        />
    );
}





export default DialogConfirmation;