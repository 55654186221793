import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'

import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import SCODocumentTranslationText from './SCODocumentTranslationText'


const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup
      }
} = components


function TablePage(props) {
      const {
            location: { pathname },
            showNotification,
      } = props

      const translatedTextObject = SCODocumentTranslationText()
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();

      const columns = [
            {
                  field: "urcNo",
                  title: translatedTextObject.ucrNo,
                  filtering: false
            },
            {
                  field: "fileType",
                  title: translatedTextObject.outputDocType,
                  sorting: true,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                              {
                                    "codeType": "DOC_CONVERSION_FILE_TYPE",
                                    "status": true
                              },
                              'content',
                              'name',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "noOfInputDocuments",
                  title: translatedTextObject.numOfInputDocs,
                  filtering: false
            },
            {
                  field: "createdDate",
                  title: translatedTextObject.createdDate,
                  type: "date",
                  filtering: false
            },
            {
                  field: "status",
                  title: translatedTextObject.status,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                              {
                                    "codeType": "DOC_CONVERSION_STATUS",
                                    "status": true
                              },
                              'content',
                              'name',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            }
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    editRoute={`${pathname}/edit`}
                                    fetch={{ url: SCOJobApiUrls.GET_DOC_CONVERSION }}
                                    idAccessor="id"
                                    notification={notification}
                                    addRoute={`${pathname}/create`}
                                    del={{ url: SCOJobApiUrls.DELETE_DOC_CONVERSION }}
                                    viewRoute={`${pathname}/view`}
                                    editButtonProps={{
                                          hideIf: (rowData) => {
                                                return rowData.statusCode != 'DRFT'
                                          }
                                    }}
                                    viewButtonProps={{
                                          hideIf: (rowData) => {
                                                return (rowData.statusCode == 'DRFT' || rowData.statusCode == 'PROG')
                                          }
                                    }}
                              />
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default TablePage