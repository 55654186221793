import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_SCOJob'
import SCOJobRequestTranslationText from './SCOJobRequestTranslationText'
import TablePage from './TablePage'

function WrappedTablePage(props) {
      const translatedTextsObject = SCOJobRequestTranslationText()
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={translatedTextsObject.scoJobRequestTitle}
                  renderPage={(showSnackbar) => (
                        <TablePage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

export {
      WrappedTablePage as TablePage
}