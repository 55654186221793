import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'

import AccordionFooterControlComponent from 'src/views/common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import SCOJobTranslationText from '../SCOJobTranslationText'
import { v4 as uuid } from 'uuid'
import Utils from 'src/views/common/utils/Utils'
import { useFieldArray } from 'react-hook-form'

import JobDocumentDetailsComponents from './JobDocumentDetailsComponents'
import JobDocumentDetails from './JobDocumentDetails'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'


const {
    form: {
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem,
    dropzone: { CngFileUpload, HelperTextArea },
    table: { useDefaultNotification }
} = components

const initialValues = {
    jobDocs: {
        description: '',
        docType: '',
        ownerType: 'JOB'
    }
}

const FormBody = (props) => {
    const notification = props.showNotification

    const { fields: pendingDocField } = useFieldArray({
        name: 'pendingJobDocuments'
    })

    const { fields: docField } = useFieldArray({
        name: 'jobDocuments'
    })

    const { fields: deletedDocIds } = useFieldArray({
        name: 'deletedDocIds'
    })

    const translatedTextsObject = SCOJobTranslationText();

    const { fetchRecords, securedSendRequest } = useServices()


    const [pendingDocs, setPendingDocs] = useState([])  //for the file list below drop zone
    const [uploadedDocs, setUploadedDocs] = useState([]) //for the file list above the drop zone
    const [deletedDocs, setDeletedDocs] = useState([])
    const [documentExpanded, setDocumentExpanded] = useState(false)
    const [fieldConfig, setFieldConfig] = useState([])
    const [fieldDict, setFieldDict] = useState([]) //for display that fits the wireframe more in uploadedDocs section
    const { errors, touched, setFieldValue } = useFormikContext()
    const docTypeKeyRef = useRef(uuid())

    const { success: showSuccessNotification, error: showErrorNotification } = useDefaultNotification(props.showNotification)
    const [shouldRender, setShouldRender] = useState(false)
    const jobStatus = props.jobStatus

    function handleDisableUploadDoc(jobStatus){
        if(jobStatus==='CANC' ){
            console.log("Inside document - handleDisableUploadDoc...... with Job Status :: "+ JSON.stringify(jobStatus))
            return true
        }
    }

    const handleJobDocumentExpand = () => {
        setDocumentExpanded(!documentExpanded);
    };


    const removeUploadedRowClick = (id) => {
        let jobDocId = uploadedDocs[id].id
        const deletedDocList = [...deletedDocs]
        deletedDocList.push(jobDocId)
        setDeletedDocs(deletedDocList)
        setFieldValue('deletedDocIds', deletedDocList)



        uploadedDocs.splice(id, 1);
        const newFiles = [].concat([], uploadedDocs)
        setUploadedDocs(newFiles)
        setFieldValue('jobDocuments', newFiles)
        docTypeKeyRef.current = uuid()
    }

    const handleUploadedInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...uploadedDocs]
        list[index][name.split('.')[1]] = value
        console.log(list)
        setUploadedDocs(list)
        setFieldValue('jobDocuments', list)
    }

    const handleUploadedDropdownChange = (val, evt, index, name) => {
        if (evt) {
            const { value } = evt
            const list = [...uploadedDocs]
            list[index][name] = value
            setUploadedDocs(list)
            setFieldValue('jobDocuments', list)
        }
    }

    const editRowClick = (id) => {
        const newFiles = [].concat([], uploadedDocs)
        newFiles[id].editable = true
        setUploadedDocs(newFiles)
        setFieldValue('jobDocuments', newFiles)
    }

    const downloadUploadedRowClick = id => {
        downloadFile(uploadedDocs[id])
    }

    function downloadFile(doc) {
        securedSendRequest.execute(
            "POST",
            SCOJobApiUrls.DOCUMENT_DOWNLOAD,
            {
                id: doc.id
            },
            (data) => {
                console.log('Success ')
                var blob = data.data
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = doc.fileName
                document.body.appendChild(link)
                link.click();
                link.parentNode.removeChild(link)
            },
            (error) => {
                console.log(error)
            },
            onComplete,
            {
                responseType: 'blob'
            }
        )
    }

    function onComplete() {
        console.log('complete')
    }

    const getFieldError = (itemIndex, fieldPath) => {
        if (errors) {
            let pathArr = fieldPath.split('.')

            if (
                errors[pathArr[0]] &&
                errors[pathArr[0]][itemIndex] &&
                errors[pathArr[0]][itemIndex][pathArr[1]]
            ) {
                if (touched) {
                    if (
                        touched[pathArr[0]] &&
                        touched[pathArr[0]][itemIndex] &&
                        touched[pathArr[0]][itemIndex][pathArr[1]]
                    ) {
                        // return true
                        return errors[pathArr[0]][itemIndex][pathArr[1]]
                    } else {
                        return null
                    }
                }
            }
        }
        return null
    }

    const showErrorMessages = (errorMessages) => {
        showErrorNotification(errorMessages[0])
    }

    const moreActions = [
        {
            action: 'remove',
            name: 'Delete',
            icon: ['fal', 'trash']
        }
    ]

    function fetchDocuments() {
        fetchRecords.execute(
            SCOJobApiUrls.DOCUMENT_GETBYJOBID,
            { customData: { jobId: props.jobId } },
            (data) => {
                setPendingDocs(pendingDocField)
                let uploadedDocList = docField
                if (uploadedDocList != null) {
                    let uploadedDocListWithEditable = data.map((v) => ({ ...v, editable: false }))
                    setUploadedDocs(uploadedDocListWithEditable)
                    setFieldValue('jobDocuments', uploadedDocListWithEditable)
                }
            }
        )
    }



    useEffect(() => {
        console.log('doc!')
        fetchRecords.execute(
            SCOJobApiUrls.DOCUMENT_GETDOCTYPE,
            {},
            onLoadDDLType
        )
    }, [props.jobId])

    function onLoadDDLType(data) {
        let docTypeOptionList = []
        if (data.length == 0) {
            console.log('null')
        } else {
            data.map((singleDocTypeOption) => {
                var detailedOption = {
                    value: singleDocTypeOption.code,
                    label: singleDocTypeOption.name
                }
                docTypeOptionList.push(detailedOption)
            })
        }


        let currentFieldConfig = [
            {
                fieldType: 'dropDown',
                fieldName: 'docType',
                label: 'File type',
                mandatory: true,
                error: false,
                errorMessage: 'Required',
                validationRule: '',
                dropDownLov: docTypeOptionList
            },
            {
                fieldType: 'textInput',
                fieldName: 'description',
                label: 'Description',
                mandatory: false,
                error: false,
                errorMessage: 'Max 1024 characters',
                validationRule: /^.{0,1024}$/
            }
        ]

        setFieldConfig(currentFieldConfig)


        if (props.jobId != null || props.jobId != '') {
            setPendingDocs(pendingDocField)
            fetchDocuments()
            setShouldRender(true)
        } else {
            console.log('doc should render')
            setShouldRender(true)
        }
    }

    const setSelectedFiles = (file) => {
        //long file name
        let processedFileObjs = []
        file.forEach((item) => {
            processedFileObjs.push(item)
        })
        setFieldValue('pendingJobDocuments', processedFileObjs)
    }

    if (!shouldRender) {
        return null
    }



    return (
        <Card>
            <Accordion expanded={documentExpanded} style={{marginBottom:20}}> 
                <AccordionSummary
                    aria-controls='jobDocumentContent'
                    id='jobDocumentComponentHeader'>
                    <Grid container>
                        <Grid container xs={12} sm={12} spacing={2} >
                            <CngGridItem xs={12} sm={12}>
                                <AccordionHeaderComponent title={translatedTextsObject.scoJobDocumentTitle} />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails >
                    <Grid container xs={12} sm={12} spacing={2}>
                        <CngGridItem xs={12} sm={12} shouldHide={uploadedDocs.length > 0 ? false : true}>
                            <JobDocumentDetailsComponents
                                tile={uploadedDocs}
                                removeRowClick={removeUploadedRowClick}
                                handleInputChange={handleUploadedInputChange}
                                handleDropdownChange={handleUploadedDropdownChange}
                                editRowClick={editRowClick}
                                downloadRowClick={downloadUploadedRowClick}
                                getFieldError={getFieldError}
                                docTypeKeyRef={docTypeKeyRef.current}
                                fieldDict={fieldDict}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={12}>
                            <CngFileUpload
                                accept={[
                                    '.pdf',
                                    '.xls',
                                    '.xlsx',
                                    '.doc',
                                    '.docx',
                                    '.png',
                                    '.jpg',
                                    '.jpeg',
                                    '.ppt',
                                    '.pptx'
                                ]}
                                maxSize={10485760}
                                maxFiles={5}
                                onFileSelect={setSelectedFiles}
                                showFormFields={true}
                                files={pendingDocs}
                                fieldConfig={fieldConfig}
                                moreActions={moreActions}
                                onDropRejected={(error) => showErrorMessages(error)}
                                renderHelperText={() => {
                                    return (
                                      <HelperTextArea
                                        accept={[
                                            '.pdf',
                                            '.xls',
                                            '.xlsx',
                                            '.doc',
                                            '.docx',
                                            '.png',
                                            '.jpg',
                                            '.jpeg',
                                            '.ppt',
                                            '.pptx'
                                        ]}
                                        maxFiles={5}
                                        maxSize={1048576}
                                      />
                                    )
                                }}
                                disabled = {handleDisableUploadDoc(jobStatus)}
                            />
                        </CngGridItem>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <AccordionFooterControlComponent
                handleAccordionExpand={handleJobDocumentExpand}
                expanded={documentExpanded}
                footerText={translatedTextsObject.scoJobDocumentAttach}
            />
        </Card >
    )
}


const JobDocumentComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default JobDocumentComponent