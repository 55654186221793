//import CreateNewJobConfigPage from './CreateNewJobConfigPage'
import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import JobAttributeConfigTable from './JobAttributeConfigTable'
//import ManageJobConfigsTable from './ManageJobConfigsTable'
//import ViewPage from './ViewPage'
import EditPage from './EditPage'
import CreatePage from './CreatePage'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_SCOJobAttribute'
import SCOJobAttributeTranslationText from './SCOJobAttributeTranslationText'


function WrappedTablePage(props) {
    const translatedTextsObject = SCOJobAttributeTranslationText();
    const breadcrumbNameMap = useBreadcrumbNameMap()


    return (
        <CngBasePage
            moduleTitle={translatedTextsObject.scoJobAttributeConfiguration}
            renderPage={(showSnackbar) => (
                <JobAttributeConfigTable showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}


function WrappedAddPage(props) {
    const translatedTextsObject = SCOJobAttributeTranslationText();
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={translatedTextsObject.scoJobAttributeConfiguratio}
            renderPage={(showSnackbar) => (
                <CreatePage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}


function WrappedEditPage(props) {
    const translatedTextsObject = SCOJobAttributeTranslationText();
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={translatedTextsObject.scoJobAttributeConfiguratio}
            renderPage={(showSnackbar) => (
                <EditPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}


/*
function WrappedViewPage(props) {
    const translatedTextsObject = SCOJobConfigTranslationText();
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={translatedTextsObject.scoViewJobConfigModuleTitle}
            renderPage={(showSnackbar) => (
                <ViewPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}



*/


export {
    WrappedTablePage as TablePage,
    WrappedAddPage as AddPage,
    WrappedEditPage as EditPage
    /*
    WrappedViewPage as ViewPage,
    
    */
}
