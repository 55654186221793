import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'

import SCOJobTranslationText from 'src/views/scojob/job/SCOJobTranslationText'
import { useFormContext } from 'react-hook-form'
import InventoryFormSchema from '../validationSchemas/InventoryFormSchema'
import { getPartyID } from 'src/store/intelligent-advisory'


const {
      form: {
            adapter: {
                  useFormAdapter: { useFormikContext }
            },
            field: {
                  CngTextField,
                  CngSelectField,
                  CngLookupAutocompleteField
            },
      },
      CngGridItem
} = components

const {
      filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
      id: '',
      skuId: '',
      skuDesc: '',
      lotNumber: '',
      quantity: '',
      uomId: '',
})


const FORMIK_PROPS = {
      initialValues: { ...DEFAULT_INITIAL_VALUES },
      validationSchema: InventoryFormSchema,
}

{/* The Inv pop up form fields unable to use Yup validation. Using regex instead */}
function Fields({ showNotification, dutyStatusId, permitTypeId}) {
      const { fetchRecords } = useServices();
      const translatedTextsObject = SCOJobTranslationText();
      const { setFieldValue } = useFormikContext()
      const { getValues } = useFormContext()
      const partyId = getPartyID()
      const [skuUOMList, setSkuUOMList] = useState([]);
      const [setSkuId, setSelectedSkuId] = useState();
      const [fetchLotNumberKey, setFetchLotNumberKey] = useState()

      useEffect(() => {
            if (getValues('skuId') !== '') {
                  getUOMforSelectedSKU()
            }
            if(getValues('skuId') !== '' && dutyStatusId.value !== '' || getValues('lotNumber') !== ''){
                  getAvailableLooseQty()
            }

            return () => {
                  console.log('')
              }
      }, [getValues('skuId'), dutyStatusId, permitTypeId, getValues('lotNumber')])


      function getUOMforSelectedSKU() {
            const onSuccess = (response) => {
                  console.log('this is response ' + response)
                  const options = response.map((record) => {
                        return {
                              text: record.uomName + " " + "(" + record.quantity + ")",
                              value: record.uomId
                        };
                  });
                  setSkuUOMList(options);
                  if(dutyStatusId.label == 'Duty Paid') {
                        response.map((uom) => uom.baseUomId === uom.uomId && setFieldValue('uomId', uom.baseUomId))
                  } else if(dutyStatusId.label == 'Duty Unpaid') {
                        const wholeUom = response.find((uom) => (uom.baseUomId === uom.uomId && uom.wholeUomId != null))
                        if(wholeUom === undefined) {
                              setFieldValue('uomId', null, true)
                        } else {
                              setFieldValue('uomId', wholeUom.wholeUomId, true)
                        }
                  }
            }

            const onError = (error) => {
                  console.log("Error:[" + JSON.stringify(error) + "]")
            }

            const onComplete = (done) => {
                  console.log("Finally:[" + JSON.stringify(done) + "]")
            }

            fetchRecords.execute(
                  SCOInvMgmtApiUrls.GET_UOM_BY_SKU_ID,
                  {
                        filters: [
                              {
                                    field: 'skuId',
                                    operator: 'EQUAL',
                                    value: getValues('skuId')
                              }
                        ]
                  },
                  onSuccess,
                  onError,
                  onComplete
            );

      }

      //Retrieve the available Loose Qty from selected DutyStatus, permitType, sku and lotNumber
      function getAvailableLooseQty(){
            console.log("Inside getAvailable Loose Qty");
            const onSuccess = (response) => {
                  console.log('Response for qty:::: ' + response.quantity)
                  setFieldValue('availableQty', response.quantity)
            }

            const onError = (error) => {
                  console.log("Get Qty Error:[" + JSON.stringify(error) + "]")
            }

            const onComplete = (done) => {
                  console.log("Get Qty: Finally:[" + JSON.stringify(done) + "]")
            }

            //Retrieve Records
            fetchRecords.execute(
                  SCOInvMgmtApiUrls.GET_AVAILABLE_LOOSE_QTY,
                  {
                        filters: [
                              {
                                    field: 'skuId',
                                    operator: 'EQUAL',
                                    value: getValues('skuId')
                              },
                              {
                                    field: 'dutyStatusId',
                                    operator: 'EQUAL',
                                    value: dutyStatusId.value
                              },
                              {
                                    field: 'permitTypeId',
                                    operator: 'EQUAL',
                                    value: permitTypeId.value
                              },
                              {
                                    field: 'lotNumber',
                                    operator: 'EQUAL',
                                    value: getValues('lotNumber')
                              }
                        ]
                  },
                  onSuccess,
                  onError,
                  onComplete
            );
      }
  
      return (
            <Grid container xs={12} sm={12} spacing={2}>
                  <CngGridItem  xs={12} sm={12}>
                        <h5  style={{ fontWeight: 600, fontSize: '16px', lineHeight: '1.334', letterSpacing: '-0.05px' }}>Search SKU by SKU Number</h5>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={12} >
                        <CngLookupAutocompleteField
                              name={`skuId`}
                              required
                              label={translatedTextsObject.skuNum}
                              lookupProps={{
                                    url: SCOInvMgmtApiUrls.GET_SKU,
                                    filters: [
                                          {
                                                field: 'partyId',
                                                operator: EQUAL,
                                                value: partyId
                                          }
                                    ],
                                    label: 'skuNum',
                                    value: 'id'
                              }}
                              onChange={(e, val) => {
                                    console.log('this is e ' + e)
                                    if(val != null){
                                          setFieldValue('skuDesc', val.data.skuDesc)
                                    }else{
                                          setFieldValue('skuDesc', '')
                                    }
                                    setFieldValue('skuId', e)
                                    setFieldValue('lotNumber', '')
                                    setSelectedSkuId(e)
                                    setFetchLotNumberKey(e)
                              }}
                        />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4} >
                        <CngLookupAutocompleteField
                              name={`lotNumber`}
                              label={translatedTextsObject.lotNum}
                              key={fetchLotNumberKey}
                              lookupProps={{
                                    url: SCOInvMgmtApiUrls.GET_LOT_NUMBER_BY_SKU_ID,
                                    filters: [
                                          {
                                                field: 'skuId',
                                                operator: EQUAL,
                                                value: parseInt(getValues('skuId'))
                                          },
                                          {
                                                field: 'dutyStatusId',
                                                operator: 'EQUAL',
                                                value: dutyStatusId.value
                                          },
                                          {
                                                field: 'permitTypeId',
                                                operator: 'EQUAL',
                                                value: permitTypeId.value
                                          }
                                    ],
                                    label: 'lotNumber',
                                    value: 'lotNumber'
                              }}
                              textFieldProps = {{helperText: 'FIFO inventory method applies if Lot Number is empty.'}}
                        />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                        <CngTextField
                              name={`skuDesc`}
                              required
                              label={translatedTextsObject.skuDesc}
                              disabled={true}
                        />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                        {/* Empty on purpose */}
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                        <CngTextField
                              name={`availableQty`}
                              label={translatedTextsObject.availableQtyLoose}
                              disabled={true}
                              helperText="Please fill in the mandatory fields in Inventory details first."
                              key={`availableQty` + getValues('availableQty')}
                        />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                        <CngTextField
                              name={`quantity`}
                              label={translatedTextsObject.quantity}
                              required
                              inputProps={{ 
                                    min: 1,
                                    inputMode: 'numeric',
                                    pattern: '^[1-9][0-9]*$'//Accepts any character that is zero to 9. First digit cannot have 0
                              }}
                              helperText="Please input a positive number. Minimum quantity = 1."
                        />
                  </CngGridItem>


                  <CngGridItem xs={12} sm={4}>
                        <CngSelectField
                              name={'uomId'}
                              label={translatedTextsObject.uom_capital}
                              required
                              key={`uom` + getValues('skuId')}
                              items={skuUOMList}
                        />
                  </CngGridItem>
            </Grid>
      );

}

const InventoryFormProperties = Object.freeze({
      formikProps: FORMIK_PROPS,
      Fields: Fields
})

export default InventoryFormProperties