import React from 'react'
import { components, Yup } from 'cng-web-lib'
import TranslationText from "../TranslationText"
import { Card, CardContent, Grid } from '@material-ui/core'

const {
  form: {
    field: {
      CngTextField
    }
  }, CngGridItem
} = components

function Fields({ ...props }) {
  const { disabled } = props

  const {
    value
  } = TranslationText()


  return (<>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid container spacing={2}>
                  <CngGridItem xs={12} sm={12}>
                    <CngTextField
                      name='value'
                      label={value}
                      disabled={disabled}
                      required
                    />
                  </CngGridItem>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
      </Grid>
    </>

  )
}


function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const DEFAULT_INITIAL_VALUES = {
  value: ''
}

const ValidationSchema = () => {
  return Yup.object({

  })
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}


const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties