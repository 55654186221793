import { Yup } from 'cng-web-lib'

const FIELDS_MAX_LENGH = {
  MAX_1024: 1024
}

function JobDocumentValidationSchema() {
  const mandatoryErrMsg = 'Required/Mandatory.'
  const fileDescCharLimit = 'File Description cannot be more than 1024 characters.'

  return Yup.object({

    jobDocuments: Yup.array(
      Yup.object({
        docType: Yup.string().required(mandatoryErrMsg).nullable(),
        fileDescription: Yup.string().required(mandatoryErrMsg).nullable().max(FIELDS_MAX_LENGH.MAX_1024, fileDescCharLimit)
      })
    ).nullable(),
    // pendingjobDocuments: Yup.array(
    //   Yup.object({
    //     docType: Yup.string().required(mandatoryErrMsg).nullable(),
    //     fileDescription: Yup.string().required(mandatoryErrMsg).nullable().max(FIELDS_MAX_LENGH.MAX_1024, fileDescCharLimit)
    //   })
    // ).nullable(),

  })
}

export default JobDocumentValidationSchema


