import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import { Card, CardContent, Divider } from '@material-ui/core'
import TranslationText from '../TranslationText'
import FormProperties from './FormProperties'
import CustomAttributeUrls from "../../../../apiUrls/sco-jm-custom-attr/CustomAttributeUrls";

const {
    card: {CngSimpleCardHeader},
    table: {
        CngServerModeDialogFormTable, useDefaultNotification
    }
} = components


const CrudTable = ({ customAttributeId, isViewOnly, showNotification }) => {

    const translatedTextObject = TranslationText()
    const notification = useDefaultNotification(showNotification)

    const cngTableRef = useRef()

    const columns = [
        {
            field: 'value',
            title: translatedTextObject.value
        }
    ]

    return (
        <Card>
            <CngSimpleCardHeader title={translatedTextObject.customAttrValueHeader}/>
            <Divider/>
            <CardContent>
                <CngServerModeDialogFormTable
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    disabled={isViewOnly}
                    showNotification={showNotification}
                    tableColumns={columns}
                    pageSize={10}
                    formProperties={{
                        ...FormProperties, formikProps: {
                            ...FormProperties.formikProps, initialValues: {
                                ...FormProperties.formikProps.initialValues, customAttributeId : customAttributeId
                            }
                        }
                    }}
                    toClientDataFormat={(serverData) => {
                        return serverData
                    }}
                    toServerDataFormat={(localDatum) => {
                        return localDatum
                    }}
                    fetch={{
                        url: CustomAttributeUrls.CUSTOM_ATTR_VALUE_GET, body: {
                            customAttributeId: customAttributeId
                        }
                    }}
                    create={{
                        url: CustomAttributeUrls.CUSTOM_ATTR_VALUE_CREATE, body: {
                            customAttributeId: customAttributeId,
                        },
                        onSuccess: () => {
                            notification.success("Create record has succeeded.")
                            if(cngTableRef.current.onQueryChange)
                                cngTableRef.current.onQueryChange()
                        }
                    }}
                    update={{ url: CustomAttributeUrls.CUSTOM_ATTR_VALUE_UPDATE,
                        onSuccess: () => {
                            notification.success("Update record has succeeded.")
                            if(cngTableRef.current.onQueryChange)
                                cngTableRef.current.onQueryChange()
                        }
                    }}
                    del={{ url: CustomAttributeUrls.CUSTOM_ATTR_VALUE_DELETE,
                        onSuccess: () => {
                            notification.success("Delete record has succeeded.")
                            if(cngTableRef.current.onQueryChange)
                                cngTableRef.current.onQueryChange()
                        }
                    }}
                    idAccessor='id'
                    tableRef = {cngTableRef}
                />
            </CardContent>
        </Card>

    )
}


export default CrudTable


