import Namespace from 'src/constants/locale/Namespace'
import SCOJob from 'src/constants/locale/key/SCOJob'
import { useTranslation } from 'cng-web-lib'


const SCODocumentTranslationText = () => {
      const { translate } = useTranslation([
            Namespace.UI_COMPONENT,
            Namespace.SCO_JOB
      ])
      const moduleTitle = translate(Namespace.SCO_JOB, SCOJob.DOCUMENT_CONVERSION)
      const editDetailsTitle = translate(Namespace.SCO_JOB, SCOJob.DOCUMENT_CONVERSION_DETAILS)
      const createDetailsTitle = translate(Namespace.SCO_JOB, SCOJob.CREATE_DOCUMENT_CONVERSION)
      const uploadDocument = translate(Namespace.SCO_JOB, SCOJob.UPLOAD_DOCUMENT)
      const outputDocType = translate(Namespace.SCO_JOB, SCOJob.OUTPUT_DOC_TYPE)
      const results = translate(Namespace.SCO_JOB, SCOJob.RESULTS)
      const numOfInputDocs = translate(Namespace.SCO_JOB, SCOJob.NUM_OF_INPUT_DOCS)
      const uploadingDoc = translate(Namespace.SCO_JOB, SCOJob.UPLOADING_DOC)
      const uploadComplete = translate(Namespace.SCO_JOB, SCOJob.UPLOAD_COMPLETE)
      const conversionInProgress = translate(Namespace.SCO_JOB, SCOJob.DOCUMENT_CONVERSION_IP)
      const createdDate = translate(Namespace.SCO_JOB, SCOJob.CREATED_DATE)
      const status = translate(Namespace.SCO_JOB, SCOJob.JOB_STATUS)
      const inputDocument = translate(Namespace.SCO_JOB, SCOJob.INPUT_DOCUMENT)
      const ucrNo = translate(Namespace.SCO_JOB, SCOJob.URC_NO)

      return {
            moduleTitle,
            editDetailsTitle,
            createDetailsTitle,
            uploadDocument,
            outputDocType,
            results,
            numOfInputDocs,
            uploadingDoc,
            uploadComplete,
            conversionInProgress,
            createdDate,
            status,
            inputDocument,
            ucrNo
      }

}

export default SCODocumentTranslationText