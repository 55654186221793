import { components, useServices, constants } from 'cng-web-lib'
import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'

import pathMap from 'src/paths/PathMap_SCO_Job'
import DocumentConversion_EditFormProperties from './DocumentConversion_EditFormProperties'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import DialogPopup from './DialogPopup';


const {
	form: {
		CngEditForm,
		CngEditFormButtonSection
	},
	button: {
		CngSecondaryButton
	},
	CngGridItem
} = components

const { NotificationType } = constants

function EditPage({ history, showNotification, props }) {
	const { id } = useParams()
	const [isOpen, setIsOpen] = useState(false)
	const [dialogMessage, setDialogMessage] = useState('Uploading Document')
	const [hideBackButton, setHideBackButton] = useState(false)
	const [isCreate, setIsCreate] = useState(true)
	const [inputDocuments, setInputDocuments] = useState([])
	const { fetchRecords, securedSendRequest } = useServices()

	const updateUrlRef = useRef(SCOJobApiUrls.UPDATE_DOC_CONVERSION)

	const saveDraft = async () => {
		updateUrlRef.current = SCOJobApiUrls.UPDATE_SAVE_DRAFT_DOC_CONVERSION
		setIsCreate(false)
	}

	function onSubmit(data) {
		return new Promise((resolve) => {
			console.log(JSON.stringify(data))
			if (data && data.files.length > 0) {
				var formData = new FormData()
				data.files.map((file, i) => {
					var fileBlob = file.file
					formData.append('fileContents', fileBlob)
				})
				var idValues = ""
				data.inputDocuments.map((input, i) => {
					idValues = idValues + input.id + ","

				})
				formData.append('inputDocuments', idValues)
				formData.append('fileType', data.fileType)
				formData.append('id', data.id)
				setIsOpen(true)

				securedSendRequest.execute('POST', updateUrlRef.current, formData,
					(response) => {
						console.log(JSON.stringify(response))
						setDialogMessage(isCreate == true ? "Upload Completed. Document Conversion In Progress" : "Upload Completed.")
						setHideBackButton(true)
						resolve()

					},
					(error) => {
						console.log(error)
						showNotification(NotificationType.ERROR, 'Error. Please contact System Admin.')
					},
					() => {
						//complete
						resolve()
					}
				)

			} else {
				//complete
				if (data.inputDocuments.length == 0) {
					resolve()
					showNotification(NotificationType.ERROR, 'Minimum 1 file is needed.')
				} else {
					var formData = new FormData()
					var idValues = ""
					data.inputDocuments.map((input, i) => {
						idValues = idValues + input.id + ","

					})
					formData.append('fileContents', null)
					formData.append('inputDocuments', idValues)
					formData.append('fileType', data.fileType)
					formData.append('id', data.id)

					setIsOpen(true)
					securedSendRequest.execute('POST', updateUrlRef.current, formData,
						(response) => {
							console.log(JSON.stringify(response))
							setDialogMessage(isCreate == true ? "Upload Completed. Document Conversion In Progress" : "Upload Completed.")
							setHideBackButton(true)
							resolve()
						},
						(error) => {
							console.log(error)
							showNotification(NotificationType.ERROR, 'Error. Please contact System Admin.')
						},
						() => {
							//complete
							resolve()
						}
					)
				}
			}

		})

	}




	function fetchInputDocuments() {
		fetchRecords.execute(
			SCOJobApiUrls.GET_INPUT_DOCUMENT,
			{
				customData: {
					docConversionId: id
				}
			},
			(data) => {
				setInputDocuments(data.content)
			}
		)
	}


	useEffect(() => {
		if (id != null) {
			fetchInputDocuments()
		}
	}, [])



	return (

		<>
			<CngEditForm
				fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
				showNotification={showNotification}
				bodySection={
					<>
						<DocumentConversion_EditFormProperties.Fields
							disabled={false}
							showNotification={showNotification}
							id={id}
							inputDocuments={inputDocuments}
						/>
					</>
				}
				renderButtonSection={() => (
					<CngEditFormButtonSection primaryButtonLabel={'Submit'}>
						<CngGridItem>
							<CngSecondaryButton
								type='submit'
								onClick={() => { saveDraft() }}>
								Save Draft
							</CngSecondaryButton>
						</CngGridItem>
						<CngGridItem>
							<CngSecondaryButton
								onClick={() => { history.push(pathMap.SCO_JOB_DOC_CONVERSION) }}>
								Back
							</CngSecondaryButton>
						</CngGridItem>
					</CngEditFormButtonSection>
				)}

				formikProps={{
					initialValues: {
						id: id,
						fileType: "",
						files: [],
						inputDocuments: []
					},
					onSubmit: onSubmit
				}}
				fetch={{
					id: id,
					url: `${SCOJobApiUrls.GET_DOC_CONVERSION_BY_ID}`
				}}
				id={id}
			/>
			<DialogPopup
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				dialogMessage={dialogMessage}
				hideBackButton={hideBackButton}
				history={history}
			/>
		</>


	)

}

export default EditPage
