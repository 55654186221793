import Namespace from 'src/constants/locale/Namespace'
import SCOJob from 'src/constants/locale/key/SCOJob'
import { useTranslation } from 'cng-web-lib'



const SCOJobRequestTranslationText = () => {
      const { translate } = useTranslation( [
            Namespace.UI_COMPONENT,
            Namespace.SCO_JOB
      ])

      let scoJobRequestTitle = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_REQUEST
      )

      let scoJobRequestSent = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_REQUEST_SENT
      )

      let scoJobRequestReceived = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_REQUEST_RECEIVED
      )

      let scoJobReqRefNo = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_REQ_REF_NO
      )

      let vendor = translate(
            Namespace.SCO_JOB,
            SCOJob.VENDOR
      )

      let requestStatus = translate(
            Namespace.SCO_JOB,
            SCOJob.REQUEST_STATUS
      )

      let requestor = translate(
            Namespace.SCO_JOB,
            SCOJob.REQUESTOR
      )

      let scoJobDescription = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_DESCRIPTION
      )

      let scoJobId = translate(
            Namespace.SCO_JOB,
            SCOJob.JOB_ID
      )

      return {
            scoJobRequestTitle,
            scoJobRequestSent,
            scoJobRequestReceived,
            scoJobReqRefNo,
            vendor,
            requestStatus,
            requestor,
            scoJobDescription,
            scoJobId
      }


}

export default SCOJobRequestTranslationText