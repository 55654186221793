import { Yup } from 'cng-web-lib'


const FIELDS_MAX_LENGH = {
      MAX_200: 200,
      MAX_50: 50,
}

function JobDetailsSchema() {
      const mandatoryErrMsg = 'Required/Mandatory'
      const exceedJobDescCharLimit = 'Job Description cannot be more than 200 characters.'
      const exceedShipRefCharLimit = 'Shipper Reference Number cannot be more than 50 characters.'

      return Yup.object({
            jobTypeId: Yup.string().nullable().required(mandatoryErrMsg),
            jobDescription: Yup.string().required(mandatoryErrMsg).max(FIELDS_MAX_LENGH.MAX_200, exceedJobDescCharLimit),
            shpRefNo: Yup.string().max(FIELDS_MAX_LENGH.MAX_50, exceedShipRefCharLimit),
            origin: Yup.string().nullable(),
            destination: Yup.string().nullable(),
            portOfLoading: Yup.number().nullable(),
            portOfDischarge: Yup.number().nullable(),
            createdDate: Yup.string(),
      })
}

export default JobDetailsSchema