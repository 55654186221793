import {
    components
} from 'cng-web-lib';
import React from 'react';
import SCOJobAttributeApiUrls from 'src/apiUrls/SCOJobAttributeApiUrls'
import FormProperties from './JobAttribute_FormProperties'
import SCOJobAttributePathMap from 'src/paths/PathMap_SCO_JobAttribute'



const { form: { CngAddForm } } = components

function CreatePage({ history, showNotification }) {
    return (

        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            history={history}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                />
            }
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            buttonText="Create"
            create={{
                url: SCOJobAttributeApiUrls.CREATE_JOB_ATTRIBUTE,
                successRedirect: SCOJobAttributePathMap.SCO_JOB_ATTRIBUTE_LIST
            }}
        />
    )

}

export default CreatePage
