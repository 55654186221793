import {
      Table,
      TableBody,
      TableCell,
      TableContainer,
      TableRow,
      Paper
  } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useServices } from 'cng-web-lib'
import SCOJobTranslationText from 'src/views/scojob/job/SCOJobTranslationText'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'


function JobTaskStatusHistoryComponent ({taskId, jobStatus}) {
      const translatedTextsObject = SCOJobTranslationText()
      const { fetchRecords } = useServices()
      const [taskHistory, setTaskHistory] = useState([])
      useEffect(() => {
            let id = taskId
            fetchRecords.execute(
                  SCOJobApiUrls.GET_TASK_HISTORY,
                  { customData: { taskId: taskId } },
                  (data) => {
                        setTaskHistory(data)
                  }
            )
      }, [taskId])


      if(jobStatus === 'ACTV') {
            return (
                  <TableContainer component={Paper}>
                        <Table id={`statushistorytable-${taskId}`}>
                              <TableBody>
                                    <TableRow key={`statusHistory-${taskId}`}>
                                          <TableCell>{translatedTextsObject.scoJobStatus}</TableCell>
                                          <TableCell>{translatedTextsObject.scoJobDateAndTime}</TableCell>
                                          <TableCell>{translatedTextsObject.scoJobEventTimezone}</TableCell>
                                          <TableCell>{translatedTextsObject.scoJobUpdatedBy}</TableCell>
                                    </TableRow>
                                    {taskHistory.map((elem, i) => {
                                          return (
                                                <TableRow key={`historyrow-${i}`}>
                                                      <TableCell>{elem.status}</TableCell>
                                                      <TableCell>{elem.eventTimestamp}</TableCell>
                                                      <TableCell>{elem.eventTimezone}</TableCell>
                                                      <TableCell>{elem.updatedBy}</TableCell>
                                                </TableRow>
                                          )
                                    })}
                              </TableBody>
                        </Table>
                  </TableContainer>
                  
            )
      } else {
            return (<br/>)
      }

}

export default JobTaskStatusHistoryComponent