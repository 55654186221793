import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import NewJobConfigFormProperties from './NewJobConfigFormProperties'
import SCOJobConfigApiurls from 'src/apiUrls/SCOJobApiUrls'


const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function ViewPage({ history, showNotification }) {
  const { id } = useParams()
  const [loading, setLoading] = useState(false);

  //forNotification
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <NewJobConfigFormProperties.FormFields
          history={history}
          showNotification={showNotification}
          disabled={true}
          loading={loading}
          id={id}
          mode={'view'}
        />
      }
      formikProps={NewJobConfigFormProperties.formikProps}
      toClientDataFormat={NewJobConfigFormProperties.toClientDataFormat}
      toServerDataFormat={NewJobConfigFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: SCOJobConfigApiurls.GET
      }}

    />
  )


}

export default ViewPage
