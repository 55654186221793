import { Card, CardContent, Grid, Checkbox, Box } from '@material-ui/core'

import {
      components, constants
} from 'cng-web-lib'


import CalScoApiUrls from 'src/apiUrls/SCOJobApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import React from 'react'
import SCOJobTranslationText from './SCOJobTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup,
      },
      CngTabs
} = components

const {
      filter: {
            EQUAL, NOT_EQUAL, IN
      }
} = constants


function TablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props

      const notification = useDefaultNotification(showNotification)

      const fetchCustomLookup = useFetchCustomLookup();
      const translatedTextsObject = SCOJobTranslationText();
      const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
      const partyId = getPartyID();

      const columns = [
            {
                  field: "jobRefNo",
                  title: translatedTextsObject.scoJobId,
                  defaultSort: 'desc'
            },
            {
                  field: "jobDescription",
                  title: translatedTextsObject.scoJobDescription
            },
            {
                  field: "jobTypeId",
                  title: translatedTextsObject.scoJobType,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${process.env.REACT_APP_SCO_ORIGIN_URL}/jobconfig/get`,
                              {
                                    partyId: partyId
                              },
                              'content',
                              'jobConfigurationName',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "shpRefNo",
                  title: translatedTextsObject.scoShipRefNO
            },
            {
                  field: "createdBy",
                  title: translatedTextsObject.scoCreatedBy,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
                              {
                                    "partyId": partyId
                              },
                              'content',
                              'userProfile.loginId', //label accessor
                              'id', //value accessor 
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "jobTaskCompleted",
                  title: translatedTextsObject.scoJobTaskCompleted,
                  filtering: false,
                  sorting: false
            },
            {
                  field: "jobStatusId",
                  title: translatedTextsObject.scoJobStatuses,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                              {
                                    // To only display both Active and Draft Status
                                    customData: {
                                          baseFilterDTO: {
                                                filterProperties: [
                                                      {
                                                            fieldName: 'codeType',
                                                            operatorType: EQUAL.toUpperCase(),
                                                            value1: 'SCO_JOB_STATUS'
                                                      },
                                                      {
                                                            fieldName: 'code',
                                                            operatorType: IN.toUpperCase(),
                                                            value1: 'ACTV|DRFT'
                                                      }
                                                ],
                                                filterType: 'AND',
                                                sortProperties: []
                                          }
                                    },
                              },
                              'content',
                              'name', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  }

            }
      ];

      const completedColumns = [
            {
                  field: "jobRefNo",
                  title: translatedTextsObject.scoJobId,
                  defaultSort: 'desc'
            },
            {
                  field: "jobDescription",
                  title: translatedTextsObject.scoJobDescription
            },
            {
                  field: "jobTypeId",
                  title: translatedTextsObject.scoJobType,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${process.env.REACT_APP_SCO_ORIGIN_URL}/jobconfig/get`,
                              {
                                    partyId: partyId
                              },
                              'content',
                              'jobConfigurationName',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "shpRefNo",
                  title: translatedTextsObject.scoShipRefNO
            },
            {
                  field: "createdBy",
                  title: translatedTextsObject.scoCreatedBy,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
                              {
                                    "partyId": partyId
                              },
                              'content',
                              'userProfile.loginId', //label accessor
                              'id', //value accessor 
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "jobCompleteness",
                  title: translatedTextsObject.scoCompleteness,
                  filtering: false,
                  sorting: false
            },
            {
                  field: "jobStatusId",
                  title: translatedTextsObject.scoJobStatuses,
                  filtering: false,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                              {
                                    "codeType": "SCO_JOB_STATUS",
                              },
                              'content',
                              'name', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  }
            }
      ]

      return (
            // <Card>
            <Box>
                  {/* <Grid container spacing={3}> */}
                  <CngTabs
                        headerColor='primary'
                        tabs={[
                              {
                                    tabName: translatedTextsObject.scoActive,
                                    tabContent: (
                                          <CngCrudTable
                                                {...props}
                                                fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                                columns={columns}
                                                del={{
                                                      url: CalScoApiUrls.DELETEJOB
                                                }}
                                                editRoute={`${pathname}/edit`}
                                                fetch={{ url: CalScoApiUrls.GETMYJOB }}
                                                idAccessor="id"
                                                fetchFilters={[{field: 'jobStatus', operator: IN, value: 'ACTV|DRFT'}]}
                                                notification={notification}
                                          //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
                                          />
                                    )
                              },
                              {
                                    tabName: translatedTextsObject.scoCompleted,
                                    tabContent: (
                                          <CngCrudTable
                                                {...props}
                                                fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                                columns={completedColumns}
                                                del={{
                                                      url: CalScoApiUrls.DELETEJOB
                                                }}
                                                editRoute={`${pathname}/edit`}
                                                fetch={{ url: CalScoApiUrls.GETMYJOB }}
                                                fetchFilters={[{ field: 'jobStatus', operator: EQUAL, value: 'COMPL' }]}
                                                idAccessor="id"
                                                notification={notification}
                                          //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
                                          />
                                    )
                              },
                              {
                                    tabName: translatedTextsObject.scoCancelled,
                                    tabContent: (
                                          <CngCrudTable
                                                {...props}
                                                fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                                columns={completedColumns}
                                                del={{
                                                      url: CalScoApiUrls.DELETEJOB
                                                }}
                                                editRoute={`${pathname}/edit`}
                                                fetch={{ url: CalScoApiUrls.GETMYJOB }}
                                                fetchFilters={[{ field: 'jobStatus', operator: EQUAL, value: 'CANC' }]}
                                                idAccessor="id"
                                                notification={notification}
                                          //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
                                          />
                                    )
                              }
                        ]}
                  />
                  {/* </Grid> */}
            </Box>
            // </Card >
      )


}

export default TablePage