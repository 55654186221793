import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import NewJobConfigFormProperties from './NewJobConfigFormProperties'
import SCOJobConfigApiurls from 'src/apiUrls/SCOJobApiUrls'
import JobConfigValidationSchema from './JobConfigValidationSchema'


const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function EditPage({ history, showNotification }) {
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const { updateRecord } = useServices()
  const validationSchema = JobConfigValidationSchema()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onError(error) {
    console.log('connection-request error', error.message)
    setLoading(false);
    showErrorNotification(error.response.data.errorMessages)
  }

  function onSuccess(response) {
    setLoading(false);
    console.log('Success', response)
    showSuccessNotification('Job Configuration updated succcessfully.')
    history.push({
      pathname: '/cal-sco/jobconfig'
    })
  }

  function onSubmit(data) {
    setLoading(true)
    console.log('Update Job Config data : ' + JSON.stringify(data))
    updateRecord.execute(
      SCOJobConfigApiurls.UPDATE,
      data,
      onSuccess,
      onError
    )

  }

  return (
    <Grid>
      <CngViewForm
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        history={history}
        showNotification={showNotification}
        bodySection={
          <NewJobConfigFormProperties.FormFields
            history={history}
            showNotification={showNotification}
            disabled={false}
            loading={loading}
            id={id}
            mode={'edit'}
          />
        }
        formikProps={{
          ...NewJobConfigFormProperties.formikProps,
          validationSchema,
          onSubmit: onSubmit,
        }}
        fetch={{
          id: id,
          url: SCOJobConfigApiurls.GET
        }}
      />
    </Grid>
  )
}

export default EditPage
