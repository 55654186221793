import JobConfigNameSchema from './validationSchemas/JobConfigNameSchema'
import JobConfigWorkflowSchema from './validationSchemas/JobConfigWorkflowSchema'



function jobConfigValidationSchema() {
      return JobConfigNameSchema()
            .concat(JobConfigWorkflowSchema())

}

export default jobConfigValidationSchema