import React from 'react'
import { components } from 'cng-web-lib'
import {Card, CardContent, Grid} from '@material-ui/core'
import TranslationText from './TranslationText'
import CustomAttributeUrls from "../../../apiUrls/sco-jm-custom-attr/CustomAttributeUrls";
import SCOAdminApiUrls from '../../../apiUrls/SCOAdminApiUrls'

const {
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup
  }
} = components

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const fetchCustomLookup = useFetchCustomLookup()
  const notification = useDefaultNotification(showNotification)

  const translatedTextObject = TranslationText()

  const columns = [
    {
      field: 'categoryId',
      title: translatedTextObject.category,
      customLookup: () => {
        return fetchCustomLookup(
          `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
          {
            codeType: 'JOB_ATTR_TYPE'
          },
          'content',
          'name',
          'id',
          (error) => notification.error(error.message)
        )
      }
    },
    {
      field: 'attributeName',
      title: translatedTextObject.attributeName
    }
  ]

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              columns={columns}
              disabled={false}
              fetch={{ url: CustomAttributeUrls.CUSTOM_ATTR_GET }}
              idAccessor='id'
              notification={notification}
              viewRoute={`${pathname}/view`}
              editRoute={`${pathname}/edit`}
              addRoute={`${pathname}/add`}
              del={{ url: CustomAttributeUrls.CUSTOM_ATTR_DELETE }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage

