import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { components, constants, useTranslation, Yup } from 'cng-web-lib'
import { default as React } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SCOJobConfigTranslationText from './SCOJobConfigTranslationText'
import { v4 as uuid } from 'uuid'

const {
    locale: {
        key: {
            CommonValidationMessageKeys,
        },
    },
} = constants
const { FormState } = constants
const {
    form: {
        field: { 
            CngSelectField,
            CngLookupAutocompleteField
        },
        adapter: {
            useFormAdapter: { useFormikContext }
        },
        CngForm
    },
    CngGridItem,
    CngDialog,
    button: { CngPrimaryButton, CngSecondaryButton }

} = components



const WorkflowAttributeDialog = ({reference, isOpen, setFieldValue, taskAttributeList, setIsOpen,
     attrOptions, filteredAttrOptions, setNewFilteredOptions, index, workflowList, setWorkflowList }) => {
    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);

    const fieldsTranslatedTextObject = SCOJobConfigTranslationText();
    const requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)

    const onSubmit = (values) => {
        let chosenAttrVal = values.chosenAttr;
        let chosenAttr = attrOptions.find(attr => attr.id == chosenAttrVal);

        const attrItem = {
            configDTO: chosenAttr,
            value: "",
            idKey: uuid()
        }

        const list = [...workflowList]
        const attrList = list[index].taskAttributeList
        const newList = [...attrList, attrItem];
        list[index].taskAttributeList = newList
        setNewFilteredOptions = newList
        setFieldValue("workFlowItems", list, true)
        setWorkflowList(list)
        
        
        setIsOpen(false);
    };

    return (
        <CngDialog
            open={isOpen}
            fullWidth
            maxWidth='md'
            customDialogContent={
                <Paper>
                    <Box m={3} mt={3}>
                        <Typography variant="h5" className='font-bolder'>{fieldsTranslatedTextObject.scoSelectAttribute}</Typography>
                        <Box mt={2}>

                            <CngForm
                                formikProps={{
                                    initialValues: { chosenAttr: '' },
                                    validationSchema: Yup.object({ chosenAttr: Yup.string().required(requiredMessage) }),
                                    onSubmit: onSubmit
                                }}
                                formState={FormState.COMPLETED}
                                renderButtonSection={() => <></>}
                                renderBodySection={() =>
                                    <FormFields
                                        filteredAttrOptions={filteredAttrOptions}
                                        setIsOpen={setIsOpen}
                                        fieldsTranslatedTextObject={fieldsTranslatedTextObject}
                                    />
                                }
                            />

                        </Box>
                    </Box>
                </Paper>
            }

        />
    );
}



const FormFields = ({ filteredAttrOptions, setIsOpen, fieldsTranslatedTextObject }) => {
    const { submitForm } = useFormikContext()

    return (
        <Grid container alignItems='center' justify='center'>
            <CngGridItem xs={12}>
                <CngLookupAutocompleteField
                    label={fieldsTranslatedTextObject.scoAttribute}
                    name='chosenAttr'
                    options={filteredAttrOptions}
                    getOptionLabel={(option) => option.text}
                    required
                />
            </CngGridItem>
            <Box mt={2}>
                <CngPrimaryButton style={{ marginRight: '10px' }}
                    type="button"
                    onClick={submitForm}
                >
                    Add
                </CngPrimaryButton>
                <CngSecondaryButton
                    onClick={() => setIsOpen(false)}>
                    {"Cancel"}
                </CngSecondaryButton>
            </Box>
        </Grid>
    );
}

export default WorkflowAttributeDialog