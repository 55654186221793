import { components, useServices, constants } from 'cng-web-lib';
import React, { useState, useRef } from 'react';

import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import FormProperties from './DocumentConversion_FormProperties';
import PathMap_SCO_Job from 'src/paths/PathMap_SCO_Job'
import DialogPopup from './DialogPopup';


const {
    form: {
        CngAddForm,
        CngAddFormButtonSection
    },
    button: {
        CngSecondaryButton
    },
    CngGridItem
} = components

const { NotificationType } = constants




function CreatePage({ history, showNotification }) {
    const [isOpen, setIsOpen] = useState(false)
    const [isCreate, setIsCreate] = useState(true)
    const [dialogMessage, setDialogMessage] = useState('Uploading Document')
    const [hideBackButton, setHideBackButton] = useState(false)

    const { securedSendRequest } = useServices()
    const createUrlRef = useRef(SCOJobApiUrls.ADD_DOC_CONVERSION)

    const saveDraft = async () => {
        createUrlRef.current = SCOJobApiUrls.DRAFT_DOC_CONVERSION
        setIsCreate(false)
        onSubmit()
    }




    function onSubmit(data) {
        return new Promise((resolve) => {
            if (data && data.files.length > 0) {
                console.log('data : ' + JSON.stringify(data))
                var formData = new FormData()
                data.files.map((file, i) => {
                    var fileBlob = file.file
                    formData.append('fileContents', fileBlob)
                })
                formData.append('fileType', data.fileType)
                setIsOpen(true)
                securedSendRequest.execute('POST', createUrlRef.current, formData,
                    (response) => {
                        console.log(JSON.stringify(response))
                        setDialogMessage(isCreate == true ? "Upload Completed. Document Conversion In Progress" : "Upload Completed.")
                        setHideBackButton(true)
                        resolve()

                    },
                    (error) => {
                        console.log(error)
                        showNotification(NotificationType.ERROR, 'Error. Please contact System Admin.')
                    },
                    () => {
                        //complete
                        resolve()
                    }
                )
            } else if (data && data.files.length == 0) {
                //complete
                resolve()
                showNotification(NotificationType.ERROR, 'Minimum 1 file is needed.')
            }

        })

    }



    return (
        <>
            <CngAddForm
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                showNotification={showNotification}
                history={history}
                bodySection={
                    <FormProperties.Fields
                        disabled={false}
                        showNotification={showNotification}
                    />
                }
                formikProps={{
                    initialValues: {
                        files: []
                    },
                    onSubmit: onSubmit
                }}
                toClientDataFormat={FormProperties.toClientDataFormat}
                toServerDataFormat={FormProperties.toServerDataFormat}
                renderButtonSection={() => (
                    <CngAddFormButtonSection
                        shouldHideResetButton={true}
                        primaryButtonLabel={'Submit'}>
                        <CngGridItem>
                            <CngSecondaryButton
                                type='submit'
                                onClick={() => {
                                    saveDraft()
                                }
                                }>
                                Save Draft
                            </CngSecondaryButton>
                        </CngGridItem>
                        <CngGridItem>
                            <CngSecondaryButton
                                onClick={() => {
                                    history.push(PathMap_SCO_Job.SCO_JOB_DOC_CONVERSION)
                                }
                                }>
                                Cancel
                            </CngSecondaryButton>
                        </CngGridItem>
                    </CngAddFormButtonSection>
                )}

            />



            <DialogPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                dialogMessage={dialogMessage}
                hideBackButton={hideBackButton}
                history={history}
            />
        </>




    )

}

export default CreatePage
