import {
      Grid,
      Paper
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import SCOJobTranslationText from 'src/views/scojob/job/SCOJobTranslationText'
import { components, constants, useServices } from 'cng-web-lib'
import SCOJobApiurls from 'src/apiUrls/SCOJobApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import { v4 as uuid } from 'uuid'

const {
      form: {
            adapter: {
                  useFormAdapter: { useField, useFormikContext }
            },
            field: {
                  CngTextField,
                  CngSelectField,
                  CngDateTimeField,
                  CngLookupAutocompleteField
            }
      },
      CngGridItem,
} = components


const {
      filter: { EQUAL }
} = constants

const initialValues = Object.freeze({
      jobTypeId: '',
      jobDescription: '',
      shpRefNo: '',
      jobRefNo: '',
      origin: '',
      destination: '',
      createdDate: '',
      portOfLoading: '',
      portOfDischarge: '',
      jobStatus: '',
      jobStatusId: '',
      customerCompanyId: '',
      rateCardId: '',
      rateOwner: ''
})

const FormBody = props => {
      
      const { fetchRecords } = useServices();
      const { setFieldValue } = useFormikContext()
      const translatedTextsObject = SCOJobTranslationText()
      const [originCountry, setOriginCountry] = useState(props.origin ? props.origin : null)
      const [destinationCountry, setDestinationCountry] = useState(props.destination ? props.destination : null)

      const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
      const partyId = getPartyID();
      const [originPOL, setOriginPOL] = useState([]);
      const [destinationPOD, setDestinationPOD] = useState([]);
      

      const [customer, setCustomer] = useState(null);
      const [rateCard, setRateCard] = useState([]);
      const [rateCardList, setRateCardList] = useState([]);
      const [rateOwner, setRateOwner] = useState([]);
      const [customerKey, setCustomerKey] = useState(null)
      const [jobConfigKey, setJobConfigKey] = useState()

      useEffect(() => {
            if(props.isClone){
                  setJobConfigKey(uuid())
            }
        }, [props.isClone])

      const handleInputChange = (e) => {
            const { name, value } = e.target
      }

      const handleCustomerChange = (customerValue) => {
            setFieldValue('rateCardId', '')
            setFieldValue('rateOwner', '')
            setRateCard([])
            setRateCardList([])
            setRateOwner([])

            if (customerValue != null) {
                  const { value } = customerValue
                  setCustomer(value ? value : "")
                  getRateCardByCustomer(customerValue);
            } else {
                  setCustomer('')
            }

      }

      const handleRateCardChange = (rateCardValue) => {
            if (rateCardValue != null) {
                  var selected = rateCardList.filter(l => l.id === rateCardValue)
                  if (selected.length > 0) {
                        const options = selected.map((rc) => {
                              return { text: rc.issuerDisplayName, value: rc.issuerAccountNo }
                        })
                        setRateOwner(options);
                        setFieldValue('rateOwner', selected[0].issuerAccountNo)
                  }
                  setFieldValue('rateCardId', rateCardValue)
            } else {
                  setFieldValue('rateOwner', '')
                  setRateOwner([])
            }

      }

      const editOriginValue = (countryValue) => {
            setFieldValue('portOfLoading', '');
            setOriginPOL([])

            //Will delete after changes has been implemented by framework side
            // if (!countryValue) {

            // }
      }

      const editDestinationValue = (countryValue) => {
            setFieldValue('portOfDischarge', '');
            setDestinationPOD([])

            //Will delete after changes has been implemented by framework side
            // if (!countryValue) {

            // }
      }

      const getOriginPOL = () => {
            const onSuccess = (response) => {
                  const options = response.content.map((port) => {
                        //temp fix for 313477 until framework fix the autocomplete lookup field
                        // return { text: port.nameWithoutDiacritics, value: port.id }
                        return { label: port.nameWithoutDiacritics, value: port.id }
                  })
                  setOriginPOL(options);
            }
            const onError = (error) => {
                  console.log("Error:[" + JSON.stringify(error) + "]")
            }

            fetchRecords.execute(
                  SCOJobApiurls.GET_LOCODE,
                  {
                        filters: [
                              {
                                    field: 'countryCode',
                                    operator: EQUAL,
                                    value: originCountry
                              }
                        ]
                  },
                  onSuccess,
                  onError
            )
      }


      const getDestinationPOD = () => {
            const onSuccess = (response) => {
                  const options = response.content.map((port) => {
                        //temp fix for 313477 until framework fix the autocomplete lookup field
                        // return { text: port.nameWithoutDiacritics, value: port.id }
                        return { label: port.nameWithoutDiacritics, value: port.id }
                  })
                  setDestinationPOD(options);
            }
            const onError = (error) => {
                  console.log("Error:[" + JSON.stringify(error) + "]")
            }

            fetchRecords.execute(
                  SCOJobApiurls.GET_LOCODE,
                  {
                        filters: [
                              {
                                    field: 'countryCode',
                                    operator: EQUAL,
                                    value: destinationCountry
                              }
                        ]
                  },
                  onSuccess,
                  onError
            )
      }

      const getRateCardByCustomer = (custVal) => {
            const onSuccess = (response) => {
                  const options = response.content.map((rc) => {
                        return { label: rc.serviceName, value: rc.id }
                  })
                  setRateCard(options)
                  setRateCardList(response.content)
                  let selectedRO;
                  if (props.rateCardId != undefined && props.rateCardId != null) {
                        setFieldValue('rateCardId', props.rateCardId)
                        selectedRO = response.content.filter(l => l.id === props.rateCardId)

                        if (selectedRO.length > 0) {
                              const optionsRO = selectedRO.map((rc) => {
                                    return { text: rc.issuerDisplayName, value: rc.issuerAccountNo }
                              })
                              setRateOwner(optionsRO);
                              setFieldValue('rateOwner', selectedRO[0].issuerAccountNo)
                        }
                  }
            }
            const onError = (error) => {
                  console.log("Error:[" + JSON.stringify(error) + "]")
            }

            fetchRecords.execute(
                  SCOJobApiurls.GET_RATECARD_BY_CUST,
                  {
                        filters: [
                              {
                                    field: 'id',
                                    operator: EQUAL,
                                    value: custVal
                              }
                        ]
                  },
                  onSuccess,
                  onError
            )
      }

      useEffect(() => {
            getOriginPOL();
      }, [originCountry])

      useEffect(() => {
            getDestinationPOD()
      }, [destinationCountry])

      

      useEffect(() => {
            if (props.customerCompanyId != undefined && props.rateCardId != undefined) {
                  setCustomerKey(uuid())
                  setCustomer(props.customerCompanyId)
                  getRateCardByCustomer(props.customerCompanyId)
            }
            if (props.isClone && props.customerCompanyId != undefined) {
                  let value = props.customerCompanyId
                  setCustomerKey(uuid())
                  setCustomer(value)
                  getRateCardByCustomer(props.customerCompanyId)
                  setFieldValue('customerCompanyId', props.customerCompanyId)
            }
      }, [props.customerCompanyId, props.rateCardId])

      useEffect(() => {
            if(props.jobReqDetails != null && props.jobReqDetails.jobDescription != null) {
                  setCustomerKey(uuid())
                  setCustomer(props.jobReqDetails.customerCompanyId)     
                  setFieldValue('jobDescription', props.jobReqDetails.jobDescription, true)
                  setFieldValue('customerCompanyId', props.jobReqDetails.customerCompanyId, true)
                  getRateCardByCustomer(props.jobReqDetails.customerCompanyId)
            }
      }, [customer, props.jobReqDetails])

      


      return (
            <Grid>
                  <Paper>
                        <Grid container style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 15, paddingBottom: 15 }}>
                              <AccordionHeaderComponent title={translatedTextsObject.scoJobDetailsTitle} />
                              <Grid container spacing={2} style={{ padding: 5 }}>
                                    <CngGridItem xs={12} sm={4}>
                                          <CngLookupAutocompleteField
                                                name='jobTypeId'
                                                label={translatedTextsObject.scoJobConfiguration}
                                                type='number'
                                                key={jobConfigKey}
                                                required
                                                lookupProps={
                                                      props.jobId === undefined ?
                                                      {
                                                            url: `${process.env.REACT_APP_SCO_ORIGIN_URL}/jobconfig/get`,
                                                            label: "jobConfigurationName",
                                                            value: "id",
                                                            filters: [
                                                                  {
                                                                        field:'isDeleted',
                                                                        operator: EQUAL,
                                                                        value: 'false'
                                                                  },
                                                                  {
                                                                        field:'partyId',
                                                                        operator: EQUAL,
                                                                        value: partyId
                                                                  }
                                                            ]
                                                      }
                                                      :
                                                      {
                                                            url: `${process.env.REACT_APP_SCO_ORIGIN_URL}/jobconfig/get`,
                                                            label: "jobConfigurationName",
                                                            value: "id"
                                                      }
                                                }
                                                disabled={props.jobId === undefined ? false : true}
                                                onChange={e => { props.parentHandleJobConfigChange(e, e) }}
                                          />
                                    </CngGridItem>


                                    <CngGridItem xs={12} sm={4}>
                                          <CngTextField
                                                name='jobDescription'
                                                label={translatedTextsObject.scoJobDescription}
                                                required
                                                onChange={e => handleInputChange(e)}

                                          />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={4}>
                                          <CngTextField
                                                name='shpRefNo'
                                                label={translatedTextsObject.scoRefNo}
                                                onChange={e => handleInputChange(e)}
                                          />
                                    </CngGridItem>
                              </Grid>

                              {/* 359241_UI_enhancement_for_Create_Edit_New_Job 
                                    Temporary move the fields to a new grid row for story requirements 
                              */}
                              <Grid container spacing={2} style={{ padding: 5 }}>
                                    {props.jobId === undefined || props.isClone ?
                                          <CngGridItem xs={12} sm={4} /> :
                                          <CngGridItem xs={12} sm={4}>
                                                <CngTextField
                                                      name='jobRefNo'
                                                      label={translatedTextsObject.scoJobId}
                                                      disabled={true}
                                                />
                                          </CngGridItem>
                                    }
                                    {/* Created Date */}
                                    {props.jobId === undefined || props.isClone ?
                                          <CngGridItem xs={12} sm={4} /> :
                                          <CngGridItem xs={12} sm={4}>
                                                <CngDateTimeField
                                                      name='createdDate'
                                                      label={translatedTextsObject.scoJobCreatedDate}
                                                      format='YYYY-MM-DD HH:mm'
                                                      disabled={true}
                                                />
                                          </CngGridItem>
                                    }

                                    {/* Job Status */}
                                    {props.jobId === undefined || props.isClone ?
                                          <></> :
                                                <CngGridItem xs={12} sm={4}>
                                                      <CngLookupAutocompleteField
                                                            name='jobStatusId'
                                                            label={translatedTextsObject.scoJobStatuses}
                                                            disabled={true}
                                                            lookupProps={{
                                                                  url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                                                  label: 'name',
                                                                  value: 'id'
                                                            }}
                                                      />
                                                </CngGridItem>
                                    }
                              </Grid>

                              {/* Hide the row of fields */}
                              <Grid container spacing={2} style={{ padding: 5 }} hidden={true}>
                        
                                    <CngGridItem xs={12} sm={4}>
                                          <CngLookupAutocompleteField
                                                name='origin'
                                                label={translatedTextsObject.scoJobOrigin}
                                                onChange={(val) => {
                                                      setOriginCountry(val);
                                                      editOriginValue(val);
                                                }}
                                                lookupProps={{
                                                      url: SCOAdminApiUrls.GET_COUNTRY_LIST,
                                                      label: 'name',
                                                      value: 'code'
                                                }}
                                          />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={4}>
                                          <CngLookupAutocompleteField
                                                name='destination'
                                                label={translatedTextsObject.scoJobDestination}
                                                onChange={(val) => {
                                                      setDestinationCountry(val);
                                                      editDestinationValue(val);
                                                }}
                                                lookupProps={{
                                                      url: SCOAdminApiUrls.GET_COUNTRY_LIST,
                                                      label: 'name',
                                                      value: 'code'
                                                }}
                                          />
                                    </CngGridItem>
                              </Grid>

                              <Grid container spacing={2} style={{ padding: 5 }} hidden={true}>
                                    
                                    {props.jobId === undefined ?
                                          <CngGridItem xs={12} sm={4} /> :
                                          <CngGridItem xs={12} sm={4}>
                                                <CngDateTimeField
                                                      name='createdDate'
                                                      label={translatedTextsObject.scoJobCreatedDate}
                                                      format='YYYY-MM-DD HH:mm'
                                                      disabled={true}
                                                />
                                          </CngGridItem>
                                    }

                                    <CngGridItem xs={12} sm={4} hidden={true}>
                                          {originCountry ?
                                                <CngLookupAutocompleteField
                                                      name='portOfLoading'
                                                      label={translatedTextsObject.scoPortOfLoading}
                                                      type='number'
                                                      //temp fix for Bugfix for 313477
                                                      //TODO: https://dev.azure.com/crimsonlogic/Gets-Next%20Generation%20Calista/_workitems/edit/313499313499 [Platform][NextGen][UI]
                                                      //Request for AutoCompleteField without lookup function
                                                      options= {originPOL}
                                                      disabled={originPOL.length > 0 ? false : true}
                                                      lookupProps={{
                                                            url: SCOJobApiurls.GET_LOCODE,
                                                            filters: [
                                                                        {
                                                                              field: 'countryCode',
                                                                              operator: EQUAL,
                                                                              value: originCountry
                                                                        }
                                                            ],
                                                            label: 'nameWithoutDiacritics',
                                                            value: 'id'
                                                      }}
                                                      // hidden ={true}

                                                />
                                                :
                                                <CngLookupAutocompleteField
                                                      name='portOfLoading'
                                                      label={translatedTextsObject.scoPortOfLoading}
                                                      type='number'
                                                      disabled={true}
                                                      // hidden ={true}
                                                />
                                          }
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={4} hidden={true}>
                                          {destinationCountry ?
                                                <CngLookupAutocompleteField
                                                      name='portOfDischarge'
                                                      label={translatedTextsObject.scoPortOfDischarge}
                                                      type='number'
                                                      
                                                      //temp fix for Bugfix for 313477
                                                      //TODO: https://dev.azure.com/crimsonlogic/Gets-Next%20Generation%20Calista/_workitems/edit/313499313499 [Platform][NextGen][UI] 
                                                      //Request for AutoCompleteField without lookup function
                                                      options= {destinationPOD}
                                                      disabled={destinationPOD.length > 0 ? false : true}
                                                      lookupProps={{
                                                            url: SCOJobApiurls.GET_LOCODE,
                                                            filters:[
                                                                        {
                                                                              field: 'countryCode',
                                                                              operator: EQUAL,
                                                                              value: destinationCountry
                                                                        }
                                                            ],
                                                            label: 'nameWithoutDiacritics',
                                                            value: 'id'
                                                      }}
                                                />
                                                :
                                                <CngLookupAutocompleteField
                                                      name='portOfDischarge'
                                                      label={translatedTextsObject.scoPortOfDischarge}
                                                      type='number'
                                                      disabled={true}
                                                />
                                          }
                                    </CngGridItem>
                              </Grid>
                              {props.jobId === undefined ?
                                    <></> :
                                    <Grid container spacing={2} style={{ padding: 5 }} hidden={true}>
                                          <CngGridItem xs={12} sm={4}>
                                                <CngLookupAutocompleteField
                                                      name='jobStatusId'
                                                      label={translatedTextsObject.scoJobStatuses}
                                                      disabled={true}
                                                      lookupProps={{
                                                            url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                                            label: 'name',
                                                            value: 'id'
                                                      }}
                                                />
                                          </CngGridItem>
                                    </Grid>
                              }
                              <Grid container spacing={2} style={{ padding: 5 }}>
                                    <CngGridItem xs={12} sm={4}>
                                          <CngLookupAutocompleteField
                                                key={'customerCompanyId-'+customerKey}
                                                name='customerCompanyId'
                                                label={translatedTextsObject.scoCustomer}
                                                disabled={props.jobId === undefined && props.jobReqDetails.customerCompanyId === undefined ? false : true}
                                                onChange={e => {
                                                      setCustomer(e);
                                                      handleCustomerChange(e);
                                                }}
                                                lookupProps={{
                                                      url: CecAccountApiUrls.GET_ACCOUNT_CUSTOMER,
                                                      label: 'displayName',
                                                      value: 'id'
                                                }}
                                          />
                                    </CngGridItem>
                                    {/* Hide */}
                                    <CngGridItem xs={12} sm={4}>
                                          {rateCard.length > 0 ?
                                                <CngLookupAutocompleteField
                                                      name='rateCardId'
                                                      label={translatedTextsObject.scoRateCard}
                                                      onChange={(e) => {
                                                            handleRateCardChange(e)
                                                      }}
                                                      disabled={props.jobId === undefined ? false : true}
                                                      hidden = {true}
                                                      options={rateCard}
                                                      lookupProps={{
                                                            url: SCOJobApiurls.GET_RATECARD_BY_CUST,
                                                            label: 'serviceName',
                                                            value: 'id',
                                                            filters:
                                                                  [{
                                                                        field: 'id',
                                                                        operator: EQUAL,
                                                                        value: customer
                                                                  }]
                                                      }}
                                                />
                                                :
                                                <CngSelectField
                                                      name='rateCardId'
                                                      label={translatedTextsObject.scoRateCard}
                                                      type='number'
                                                      hidden = {true}
                                                      disabled={true}
                                                />
                                          }
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                          <CngSelectField
                                                name='rateOwner'
                                                label={translatedTextsObject.scoRateOwner}
                                                type='text'
                                                disabled={true}
                                                hidden = {true}
                                                items={rateOwner}
                                          />
                                    </CngGridItem>

                              </Grid>
                        </Grid>
                  </Paper>
            </Grid>
      )

}


const JobDetailsComponents = Object.freeze({
      FormBody: FormBody,
      initialValues: initialValues
})

export default JobDetailsComponents