import { components, useServices } from 'cng-web-lib'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import SCOJobAttributeApiUrls from 'src/apiUrls/SCOJobAttributeApiUrls'
import FormProperties from './JobAttribute_FormProperties'
import SCOJobAttributePathMap from 'src/paths/PathMap_SCO_JobAttribute'

const {
    button: {
        CngPrimaryButton,
        CngSecondaryButton
    },
    form: {
        CngEditForm,
        CngEditFormButtonSection
    },
    CngGridItem
} = components


function EditPage({ history, showNotification }) {
    const { id } = useParams()



    return (

        <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                    id={id}
                />
            }
            renderButtonSection={() => (
                <CngEditFormButtonSection>
                    <CngGridItem>
                        <CngSecondaryButton
                            onClick={() => {
                                history.push(SCOJobAttributePathMap.SCO_JOB_ATTRIBUTE_LIST)
                            }
                            }>
                            Cancel
                        </CngSecondaryButton>
                    </CngGridItem>
                </CngEditFormButtonSection>

            )}
            formikProps={{
                ...FormProperties.formikProps,
            }}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{
                url: `${SCOJobAttributeApiUrls.GET_JOB_ATTRIBUTE_BY_ID}`
            }}
            update={{
                url: SCOJobAttributeApiUrls.UPDATE_JOB_ATTRIBUTE,
                successRedirect: SCOJobAttributePathMap.SCO_JOB_ATTRIBUTE_LIST
            }}
            id={id}
        />
    )

}

export default EditPage
