import React from 'react'
import { BasePage as CngBasePage } from 'cng-web-lib'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_SCOJMCustomAttr'
import TranslationText from './TranslationText'
import TablePage from "./Custom_Attr_TablePage"
import ViewPage from "./Custom_Attr_ViewPage"
import AddPage from "./Custom_Attr_AddPage"
import EditPage from "./Custom_Attr_EditPage"

function ModuleTitle() {
  const translatedTextsObject = TranslationText()
  return translatedTextsObject.moduleTitle
}

function WrappedTablePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={ModuleTitle()}
      renderPage={(showSnackbar) => (
        <TablePage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={ModuleTitle()}
      renderPage={(showSnackbar) => (
        <ViewPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={ModuleTitle()}
      renderPage={(showSnackbar) => (
        <AddPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}
function WrappedEditPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={ModuleTitle()}
      renderPage={(showSnackbar) => (
        <EditPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedTablePage as TablePage,
  WrappedViewPage as ViewPage,
  WrappedAddPage as AddPage,
  WrappedEditPage as EditPage
}