import { Yup } from 'cng-web-lib'


const FIELDS_MAX_LENGH = {
      TASK_SEQUENCE: 99999,
      MAX_200: 200,
      MAX_100: 100,
      MAX_255: 255
}


function JobTaskSchema() {

      const estimatedDurationErrMsg = 'Please enter only numeric values from 1 onwards.'
      const sequenceErrMsg = 'Sequence cannot be duplicated/less than 1.'
      const mandatoryErrMsg = 'Required/Mandatory.'
      const mandatoryTaskSequenceMsg = 'Required/Mandatory/No Duplicate.'
      const wrongEmailFormat = 'Must be in email format.'
      const phoneRegx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/
      const postalRegx = /^[0-9]*$/
      const invalidPhoneMsg = "Invalid Phone Number."
      const invalidPostalMsg = "Invalid Postal Code."
      const exceedCharactersLimit = 'Exceed Characters limit.'

      return Yup.object({
            jobTaskItems: Yup.array(
                  Yup.object({
                        taskSequence: Yup.number().required(mandatoryTaskSequenceMsg).min(1, sequenceErrMsg).max(FIELDS_MAX_LENGH.TASK_SEQUENCE, "Sequence cannot be duplicated/more than 99999."),
                        taskType: Yup.string().required(mandatoryErrMsg).nullable(),
                        taskDesc: Yup.string().required(mandatoryErrMsg).max(FIELDS_MAX_LENGH.MAX_200, "Task Description cannot be more than 200 characters."),
                        estimatedDuration: Yup.number().integer().min(1, estimatedDurationErrMsg).nullable(true),
                        taskStatus: Yup.string().nullable(),
                        timezone: Yup.string().nullable(),
                        localEventTimestamp: Yup.string().nullable(),
                        userAssigned: Yup.string().nullable(),
                        remarks: Yup.string().nullable().max(FIELDS_MAX_LENGH.MAX_200, "Remarks cannot be more than 200 characters."),
                        addresseeName: Yup.string().nullable().max(FIELDS_MAX_LENGH.MAX_200, "Name cannot be more than 200 characters."),
                        addresseeAddress: Yup.string().nullable().max(FIELDS_MAX_LENGH.MAX_200, "Address cannot be more than 200 characters."),
                        addresseePostalCode: Yup.string().matches(postalRegx, invalidPostalMsg).nullable().max(FIELDS_MAX_LENGH.MAX_200, "Postal Code cannot be more than 200 characters."),
                        addresseeEmailAddress: Yup.string().email(wrongEmailFormat).nullable().max(FIELDS_MAX_LENGH.MAX_100, "Email Address cannot be more than 100 characters."),
                        addresseeContactNumber: Yup.string().matches(phoneRegx, invalidPhoneMsg).nullable().max(FIELDS_MAX_LENGH.MAX_200, "Contact Number cannot be more than 200 characters."),
                        jobTaskContact: Yup.object({
                              jobTaskContactEmailList: Yup.array(
                                    Yup.object({
                                          emailAddress: Yup.string().required(mandatoryErrMsg).nullable(false).email(wrongEmailFormat).max(FIELDS_MAX_LENGH.MAX_100, "Email Address cannot be more than 100 characters."),
                                    }),
                              )
                        },
                        ),
                        jobTaskAttributes: Yup.array(
                              Yup.object({
                                    value: Yup.string().nullable().max(FIELDS_MAX_LENGH.MAX_255, exceedCharactersLimit),
                              }),
                        )
                  })
            ),
      })
}

export default JobTaskSchema
