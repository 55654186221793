import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import DocumentDetailsComponents from './DocumentDetailsComponents'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import SCODocumentTranslationText from './SCODocumentTranslationText'


const {
    form: {
        field: { CngSelectField },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = {
    fileType: ""
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES }
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled, showNotification, id, inputDocuments, outputDocuments } = props;
    const fieldsTranslatedTextObject = SCODocumentTranslationText()

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container style={{ paddingBottom: 20 }}>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.inputDocument} />
                                <Grid item xs={12} sm={4}>
                                    <CngSelectField
                                        disabled={disabled}
                                        name="fileType"
                                        label={fieldsTranslatedTextObject.outputDocType}
                                        required
                                        fetch={
                                            {
                                                url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                                textAccessor: "name",
                                                body: {
                                                    codeType: 'DOC_CONVERSION_FILE_TYPE',
                                                    status: true
                                                }
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} />
                                <Grid item xs={12} sm={4} />
                            </Grid>

                            <DocumentDetailsComponents
                                uploadedFiles={inputDocuments}
                                reference={'input'}
                                isDraft={false}
                            />
                        </CardContent>
                    </Card>
                </CngGridItem>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.results} />
                                <DocumentDetailsComponents
                                    uploadedFiles={outputDocuments}
                                    reference={'output'}
                                    isDraft={false}
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
