import {
    Box,
    Card,
    Grid,
    IconButton
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'

import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FooterAddComponent from 'src/views/common/ui/FooterAddComponent'
import SCOJobConfigTranslationText from 'src/views/scojob/jobconfig/SCOJobConfigTranslationText'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SCOJobConfigApiUrls from 'src/apiUrls/SCOJobApiUrls'

import { useFieldArray, useFormContext } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import SCOJobAttributeApiUrls from 'src/apiUrls/SCOJobAttributeApiUrls';
import WorkflowAttributeDialog from 'src/views/scojob/jobconfig/WorkflowAttributeDialog';



const {
    form: {
        adapter: {
            useFormAdapter: { useFormikContext }
        },
        field: {
            CngTextField,
            CngLookupAutocompleteField,
            CngSelectField
        },
    },
    CngGridItem,
} = components


const initialValues = {
    workFlowItems: [
        {
            workFlowId: "",
            taskSequence: 1,
            taskType: "",
            taskDescription: "",
            movementType: "",
            estimatedDuration: "",
            emailItems: [],
            taskAttributeList: [],
        }
    ],
    deletedEmailList: [],
    deletedTaskList: [],
    deletedTaskAttrList: []
}

const FormBody = (props) => {
    const { errors, setFieldValue } = useFormikContext()
    const { fetchRecords } = useServices()
    const disabled = props.disabled


    const getFieldError = (itemIndex, fieldPath) => {
        if (errors) {
            let pathArr = fieldPath.split('.')
            if (errors[pathArr[0]] && errors[pathArr[0]][itemIndex]
                && errors[pathArr[0]][itemIndex][pathArr[1]]) {
                return errors[pathArr[0]][itemIndex][pathArr[1]]
            } else {
                return null
            }
        }
        return null
    }

    const getFieldErrorForEmail = (itemIndex, fieldPath, innerIndex) => {
        if (errors) {
            let pathArr = fieldPath.split('.')
            if (errors[pathArr[0]] && errors[pathArr[0]][itemIndex]
                && errors[pathArr[0]][itemIndex][pathArr[1]]
                && errors[pathArr[0]][itemIndex][pathArr[1]][innerIndex]
                && errors[pathArr[0]][itemIndex][pathArr[1]][innerIndex][pathArr[2]]) {
                return errors[pathArr[0]][itemIndex][pathArr[1]][innerIndex][pathArr[2]]
            } else {
                return null
            }
        }
        return null
    }



    const translatedTextsObject = SCOJobConfigTranslationText()

    const emailItem = {
        id: uuid(),
        emailId: "", // for react reference.
        emailAddress: ""
    }

    const workFlowItem = {
        workFlowId: "", // for react reference.
        id: uuid(),
        taskSequence: "",
        taskType: "",
        taskDescription: "",
        movementType: "",
        estimatedDuration: "",
        emailItems: [emailItem],
        taskAttributeList: [],
    }

    const [taskSequenceOptions, setTaskSequenceOptions] = useState([])
    const [workflowList, setWorkflowList] = useState([])
    const [deletedEmailIds, setDeletedEmailIds] = useState([])
    const [deletedTaskIds, setDeletedTaskIds] = useState([])
    const [loading, setLoading] = useState(false)
    const [shouldRender, setShouldRender] = useState(false)
    const { remove } = useFieldArray({ name: "workFlowItems" })
    const { trigger } = useFormContext()

    const [deletedTaskAttrIds, setDeletedTaskAttrIds] = useState([])
    const { fields: taskAttributeList } = useFieldArray({ name: 'taskAttributeList', keyName: 'key' });
    const [attrConfigOptions, setAttrConfigOptions] = useState([]);
    const [filteredAttrConfigOptions, setFilteredAttrConfigOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const [taskIndex, setTaskIndex] = useState()

    const {
        filter: { EQUAL }
    } = constants

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...workflowList]
        list[index][name.split('.')[1]] = value
        setWorkflowList(list)
        setFieldValue("workFlowItems", list)
    };

    const handleDropdownChange = (val, evt, index, name) => {
        if (evt) {
            const { value } = evt
            const list = [...workflowList]
            list[index][name] = value
            setWorkflowList(list)
            setFieldValue("workFlowItems", list)
            // trigger()
        }
    };

    const getJobAttribute = () => {
        const onSuccess = (response) => {
            const options = response.content.map((attr) => {
                return attr;
            })

            setAttrConfigOptions(options)
            let filteredAttr = options.map((opt) => {
                return { text: opt.attributeName, value: opt.id }
            })
            setFilteredAttrConfigOptions(filteredAttr);
        }

        fetchRecords.execute(
            SCOJobAttributeApiUrls.GET_JOB_ATTRIBUTE_OPTION,
            {
                filters: [
                    {
                        field: 'attributeType',
                        operator: EQUAL,
                        value: 'TASK'
                    }
                ]
            },
            onSuccess
        )
    }

    const getAttributeLists = () => {
        const onSuccess = (response) => {
            const attributesResp = response.content.map((attr) => {
                return { id: attr.id, attributeName: attr.attributeName }
            })
            setFieldValue("taskAttributeList", attributesResp)
            setNewFilteredOptions(attributesResp)
        }

        fetchRecords.execute(
            SCOJobConfigApiUrls.GETJOBATTRIBUTE,
            {
                filters: [
                    {
                        field: 'id',
                        operator: EQUAL,
                        value: props.id
                    }
                ]
            },
            onSuccess
        )
    }

    const openAttributeDialog = (taskAttributes, index) => {
        setNewFilteredOptions(taskAttributes);
        setTaskIndex(index);
        setIsOpen(true);

    }

    const setNewFilteredOptions = (newList) => {
        let filteredAttr = attrConfigOptions.filter(attrConfig => !newList.some(existingAttr => existingAttr.configDTO.id == attrConfig.id)).map((attrConfig) => {
            return { text: attrConfig.attributeName, value: attrConfig.id }
        });
        setFilteredAttrConfigOptions(filteredAttr);
    }
    //end of attributes


    function sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    // handle click event of the Remove button
    const handleRemoveContainer = (index) => {
        const list = [...workflowList]
        if (list[index].workFlowId !== "") {
            const deletedTaskList = [...deletedTaskIds]
            deletedTaskList.push(list[index].workFlowId)
            setDeletedTaskIds(deletedTaskList)
            setFieldValue("deletedTaskList", deletedTaskList, true)
        }
        for (let i = index + 1; i < list.length; i++) {
            let taskSeq = list[i].taskSequence - 1
            list[i]['taskSequence'] = taskSeq
            setFieldValue(`workFlowItems[${i}].taskSequence`, taskSeq, true)
        }

        list.splice(index, 1)
        remove(list[index])

        sortByKey(list, 'taskSequence')
        setWorkflowList(list)
        setFieldValue("workFlowItems", list, true)
    };

    const handleRemoveEmail = (index, indexEmail) => {
        const list = [...workflowList]
        if (list[index].emailItems[indexEmail].id !== "") {
            const deletedEmailList = [...deletedEmailIds]
            deletedEmailList.push(list[index].emailItems[indexEmail].id)
            setDeletedEmailIds(deletedEmailList)
            setFieldValue("deletedEmailList", deletedEmailList, true)
        }
        list[index].emailItems.splice(indexEmail, 1)
        remove(list[index].emailItems[indexEmail])

        setFieldValue("workFlowItems", list, true)
        setWorkflowList(list)
    }

    // handle click event of the Add button
    const handleAddContainer = index => {
        let totalTask = getTotalNoOfTask() + 1;
        console.log('this is total task ' + totalTask)
        if (props.mode != 'view') {
            const addWorkflowItem = { ...workFlowItem }
            addWorkflowItem.workFlowId = ""
            addWorkflowItem.taskSequence = totalTask
            addWorkflowItem.taskType = ""
            addWorkflowItem.taskDescription = ""
            addWorkflowItem.estimatedDuration = ""
            addWorkflowItem.emailItems = []
            addWorkflowItem.taskAttributeList = []
            setWorkflowList([...workflowList, addWorkflowItem])
            setFieldValue("workFlowItems", [...workflowList, addWorkflowItem], true)
        }
    };

    //handle click event of the Copy buttion
    const handleCopyContainer = index => {
        const list = [...workflowList]
        const copyWorkflow = list.slice(index, index + 1)[0]
        const copyWorkflowEmail = copyWorkflow.emailItems.map(i => ({ ...i }))
        const copyWorkflowAttribute = copyWorkflow.taskAttributeList.map(i => ({ ...i }))
        const copyWorkflowitem = { ...copyWorkflow, taskAttributeList: copyWorkflowAttribute, emailItems: copyWorkflowEmail }
        copyWorkflowitem.workFlowId = ""
        copyWorkflowitem.id = uuid()
        setWorkflowList([...workflowList, copyWorkflowitem]);
        setFieldValue("workFlowItems", [...workflowList, copyWorkflowitem], true)
    }

    const addEmail = (index, workflowItem) => {
        const workflowEmailList = workflowItem.emailItems
        const addNewEmailItem = emailItem
        workflowEmailList.push(addNewEmailItem)
        const list = [...workflowList]
        list[index].emailItems = workflowEmailList
        setFieldValue("workFlowItems", list, true)

    };

    const handleEmailInputChange = (e, workFlowEmailList, indexEmail, index) => { //i - workflow, index - emailItems
        const { name, value } = e.target
        const workFlowListEmailItems = [...workFlowEmailList]
        workFlowListEmailItems[indexEmail]['emailAddress'] = value
        const list = [...workflowList]
        list[index].emailItems = workFlowListEmailItems
        setFieldValue("workFlowItems", list)
    }

    function loadWorkflow() {
        setLoading(true)
        var parseId = parseInt(props.id)
        fetchRecords.execute(
            SCOJobConfigApiUrls.GETWORKFLOW,
            { customData: { jobId: parseId } },
            (data) => {
                let temp3 = [...data.jobConfigWorkFlow]
                //Adding this to delete the task attribute correctly.
                temp3.forEach(function (x) {
                    let taskAttributes = [...x.taskAttributeList]
                    taskAttributes.forEach(function (y) {
                        y.idKey = uuid()
                    })
                    x.taskAttributeList = taskAttributes
                })
                setWorkflowList(temp3)
                setLoading(false)

            }

        )
    }

    function loadSequences() {
        const list = [...workflowList]
        var taskArray = []
        for (let i = 1; i <= list.length; i++) {
            taskArray.push(i)
        }
        setTaskSequenceOptions(taskArray.map(x => ({
            value: x,
            text: x
        })))
    }

    function getTotalNoOfTask() {
        return workflowList.length
    }


    const handleTaskSequenceChange = (val, evt, index) => {
        setWorkflowList([])
        let value = val.target.value
        let newList = [...workflowList]
        if (index >= value) {  //move up
            newList[index]['taskSequence'] = value
            let valIncrement = parseInt(value) + 1
            for (let i = (value - 1); i < index; i++) {
                newList[i]['taskSequence'] = valIncrement
                setFieldValue(`workFlowItems[${i}].taskSequence`, valIncrement, true)
                valIncrement++
            }
        } else if (index < value) { // move down
            newList[index]['taskSequence'] = value
            for (let i = (index + 1); i < value; i++) {
                newList[i]['taskSequence'] = i
                setFieldValue(`workFlowItems[${i}].taskSequence`, i, true)
            }
        }
        sortByKey(newList, 'taskSequence')
        setWorkflowList(newList)
        setFieldValue("workFlowItems", newList, true)
    };

    const handleRemoveAttribute = (index, attrIndex, workflowList) => {

        console.log('index ' + index + ' attrIndex: ' + attrIndex)
        const list = [...workflowList]
        if (list[index].taskAttributeList[attrIndex].id !== "") {
            const deletedTaskAttrList = [...deletedTaskAttrIds];
            deletedTaskAttrList.push(list[index].taskAttributeList[attrIndex].id);
            setDeletedTaskAttrIds(deletedTaskAttrList);
            setFieldValue("deletedTaskAttrList", deletedTaskAttrList, true);

        }

        list[index].taskAttributeList.splice(attrIndex, 1);
        remove(list[index].taskAttributeList[attrIndex]);
        setWorkflowList(list);
        setFieldValue("workFlowItems", list, true)
    };

    useEffect(() => {
        getJobAttribute();
        getAttributeLists();
        if (props.mode == 'view' || props.mode == 'edit') {
            loadWorkflow()
        } else {
            const cont = { ...workFlowItem }
            setWorkflowList([cont])
            setFieldValue("workFlowItems", [cont])
            console.log("Test");
        }
    }, [props.mode], [props.id], [attrConfigOptions])

    useEffect(() => {
        if (workflowList != null) {

            setFieldValue("workFlowItems", workflowList, true)
            setShouldRender(true)
        }
        loadSequences()
    }, [workflowList])


    if (!shouldRender || loading) {
        return null;
    }

    return (
        <Card>
            <Box px={2} my={1.5}>
                <Grid container>
                    <AccordionHeaderComponent title={translatedTextsObject.scoJobConfigWorkFlowTitle} />
                </Grid>

                <Grid container xs={12} sm={12} spacing={2}>
                    {workflowList !== null ? workflowList.map((workflowItem, index) => {
                        return (
                            <Grid item xs={12} sm={12} key={workflowItem.workFlowId !== '' ? workflowItem.workFlowId : workflowItem.id}>
                                <Card className="inner-card-group">
                                    <Grid container xs={12} sm={12} spacing={2}>
                                        <CngGridItem xs={4} sm={4} style={{ paddingLeft: 20 }}>
                                            <CngSelectField
                                                name={`workFlowItems[${index}].taskSequence`}
                                                label={translatedTextsObject.scoJobConfigTaskSequenceTitle}
                                                //onChange={(val, evt) => handleTaskSequenceChange(val, evt, index, workflowList)}
                                                onChange={(val, evt) => handleTaskSequenceChange(val, evt, index)}
                                                disabled={disabled}
                                                required
                                                error={getFieldError(index, `workFlowItems.taskSequence`)}
                                                items={taskSequenceOptions}
                                                type='number'
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <CngLookupAutocompleteField
                                                name={`workFlowItems[${index}].taskType`}
                                                label={translatedTextsObject.scoJobConfigTaskType}
                                                required
                                                onChange={(val, evt) => handleDropdownChange(val, evt, index, 'taskType')}
                                                disabled={disabled}
                                                lookupProps={{
                                                    url: SCOJobConfigApiUrls.GET_JOB_TASK_TYPE,
                                                    label: 'name',
                                                    value: 'code'
                                                }}
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <CngTextField
                                                name={`workFlowItems[${index}].taskDescription`}
                                                label={translatedTextsObject.scoJobConfigTaskDescription}
                                                required
                                                onChange={e => handleInputChange(e, index)}
                                                disabled={disabled}
                                                error={getFieldError(index, `workFlowItems.taskDescription`)}
                                            //helperText={getFieldError(index, `workFlowItems.taskDescription`)}
                                            />
                                        </CngGridItem>
                                        {workflowItem.taskType === 'INV' ?
                                            <CngGridItem xs={4} sm={4} style={{paddingLeft:20}}>
                                                <CngSelectField
                                                    name={`workFlowItems[${index}].movementType`}
                                                    label={"Movement Type"}
                                                    required
                                                    fetch={
                                                        {
                                                            url: SCOJobConfigApiUrls.GET_MOVEMENT_TYPE,
                                                            textAccessor: "name",
                                                            idAccessor: "id"
                                                        }

                                                    }
                                                />
                                            </CngGridItem>
                                            :
                                            <CngGridItem xs={4} sm={4}>
                                            </CngGridItem>
                                        }

                                        <CngGridItem xs={4} sm={4} shouldHide={workflowItem.taskType === 'INV' ? true : false}>
                                            <CngTextField
                                                type='number'
                                                name={`workFlowItems[${index}].estimatedDuration`}
                                                label={translatedTextsObject.scoJobConfigEstimatedDuration}
                                                onChange={e => handleInputChange(e, index)}
                                                disabled={disabled}
                                                error={getFieldError(index, `workFlowItems.estimatedDuration`)}
                                            //helperText={getFieldError(index, `workFlowItems.estimatedDuration`)}
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                        </CngGridItem>
                                    </Grid>

                                    <Grid container xs={12} sm={12} spacing={2}>

                                        {/*work task attribute  */}
                                        <CngGridItem xs={12} sm={12}>
                                            <WorkflowAttributeDialog
                                                reference={`workFlowItems[${index}].taskAttributeList`}
                                                isOpen={isOpen}
                                                setFieldValue={setFieldValue}
                                                taskAttributeList={workFlowItem.taskAttributeList}
                                                setIsOpen={setIsOpen}
                                                attrOptions={attrConfigOptions}
                                                filteredAttrOptions={filteredAttrConfigOptions}
                                                setFilteredAttrConfigOptions={setFilteredAttrConfigOptions}
                                                setNewFilteredOptions={setNewFilteredOptions}
                                                index={taskIndex}
                                                workflowList={workflowList}
                                                setWorkflowList={setWorkflowList}

                                            />

                                            {/*work task attribute Display */}
                                            <Card className="inner-card-group">
                                                <CngGridItem xs={12} sm={12}>
                                                    <div style={{ paddingBottom: 10, fontWeight: 700 }}><h6>{translatedTextsObject.scoJobTaskAttribute} </h6></div>
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={12}>
                                                    {workflowItem.taskAttributeList != null ?
                                                        workflowItem.taskAttributeList.map((attribute, attrIndex) => {
                                                            return (
                                                                //key must be unique attr.idKey
                                                                <Box mb={2} width={`100%`} key={attribute.idKey}>
                                                                    <Grid container spacing={2}>
                                                                        <CngGridItem xs={12} sm={4}>
                                                                            <CngTextField
                                                                                name={`workFlowItems[${index}].taskAttributeList[${attrIndex}].configDTO.attributeName`}
                                                                                label={translatedTextsObject.scoAttribute}
                                                                                disabled={true}
                                                                            />
                                                                        </CngGridItem>
                                                                        <CngGridItem xs={12} sm={4} shouldHide={disabled}>
                                                                            <Box style={{ textAlign: 'left' }}>
                                                                                <IconButton aria-label="remove" disabled={false} onClick={() => handleRemoveAttribute(index, attrIndex, workflowList)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </CngGridItem>
                                                                    </Grid>
                                                                </Box>
                                                            )
                                                        }) : <br />
                                                    }
                                                    <CngGridItem xs={12} sm={4}>
                                                        <IconButton style={{ width: 100, height: 50 }} aria-label="add" disabled={false}
                                                            onClick={() => openAttributeDialog(workflowItem.taskAttributeList, index)}>
                                                            <AddCircleOutlineIcon
                                                                fontSize="small"
                                                                style={{ fontSize: 20, textAlign: 'center', color: 'grey' }}
                                                            />
                                                            <span style={{ fontSize: 15, textAlign: 'center' }}>Add Attribute</span>
                                                        </IconButton>
                                                    </CngGridItem>

                                                </CngGridItem>
                                            </Card>
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>

                                        </CngGridItem>

                                        <CngGridItem xs={12} sm={12}>
                                            <Card className="inner-card-group">
                                                <CngGridItem xs={12} sm={12}>
                                                    <div style={{ paddingBottom: 10 }}><h6>{translatedTextsObject.scoContactListTitle} </h6></div>


                                                </CngGridItem>
                                                <CngGridItem xs={12} sm={12}>
                                                    {workflowItem.emailItems != null ?
                                                        workflowItem.emailItems.map((emailAddress, indexEmail) => {
                                                            return (
                                                                <Grid container xs={12} sm={12} spacing={2} style={{ paddingBottom: 10, paddingLeft: 5 }} key={emailAddress.id !== '' ? emailAddress.id : emailAddress.emailId}>
                                                                    <CngGridItem xs={4} sm={4} pt={2} style={{ paddingBottom: 10 }}>
                                                                        <CngTextField
                                                                            type='text'
                                                                            disabled={disabled}
                                                                            onChange={e => handleEmailInputChange(e, workflowItem.emailItems, indexEmail, index)}
                                                                            name={`workFlowItems.${index}.emailItems.${indexEmail}.emailAddress`}
                                                                            label={translatedTextsObject.scoJobConfigEmailAddress}
                                                                            required
                                                                            error={getFieldErrorForEmail(index, `workFlowItems.emailItems.emailAddress`, indexEmail)}
                                                                        //helperText={getFieldErrorForEmail(index, `workFlowItems.emailItems.emailAddress`, indexEmail)}
                                                                        />
                                                                    </CngGridItem>
                                                                    <CngGridItem xs={4} sm={4} pt={2} style={{ paddingBottom: 10 }}>
                                                                        {props.mode == 'view' ? <br /> :
                                                                            <IconButton aria-label="remove" >
                                                                                <DeleteIcon onClick={() => handleRemoveEmail(index, indexEmail)} />
                                                                            </IconButton>
                                                                        }
                                                                    </CngGridItem>

                                                                </Grid>

                                                            )
                                                        }) : <br />}
                                                    <CngGridItem xs={4} sm={4}>
                                                        {props.mode == 'view' ? <br /> :
                                                            <IconButton style={{ width: 100, height: 50 }} aria-label="add" disabled={disabled}
                                                                onClick={() => addEmail(index, workflowItem)}>
                                                                <AddCircleOutlineIcon
                                                                    fontSize="small"
                                                                    style={{ fontSize: 20, textAlign: 'center', color: 'grey' }}
                                                                />
                                                                <span style={{ fontSize: 15, textAlign: 'center' }}>Add Contact</span>
                                                            </IconButton>
                                                        }
                                                    </CngGridItem>
                                                </CngGridItem>
                                            </Card>
                                        </CngGridItem>
                                        {props.mode == 'view' ? <br /> :
                                            <CngGridItem xs={4} sm={4}>
                                                <IconButton aria-label="copy" disabled={disabled}>
                                                    <FileCopyIcon onClick={() => handleCopyContainer(index)} />
                                                </IconButton>
                                                {workflowList.length > 1 &&
                                                    <IconButton aria-label="remove" disabled={disabled}>
                                                        <DeleteIcon onClick={() => handleRemoveContainer(index)} />
                                                    </IconButton>
                                                }
                                            </CngGridItem>
                                        }

                                        <CngGridItem xs={4} sm={4}>

                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>

                                        </CngGridItem>

                                    </Grid>

                                </Card>
                            </Grid>
                        )
                    }) : <br />}

                </Grid>
            </Box >
            {
                props.mode == 'view' ? <br /> :
                    < FooterAddComponent
                        handleAddChild={handleAddContainer}
                        footerText={translatedTextsObject.addAContainer}
                    />
            }

        </Card >
    )
}

const WorkflowComponent = ({
    FormBody: FormBody,
    initialValues: initialValues
})

export default WorkflowComponent