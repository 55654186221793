import { Box, Paper } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import { default as React } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import PathMap_SCO_Job from 'src/paths/PathMap_SCO_Job'


const { FormState } = constants
const {
    CngGridItem,
    CngDialog,
    button: { CngSecondaryButton }

} = components


const DialogPopup = ({ isOpen, setIsOpen, dialogMessage, hideBackButton, history }) => {



    return (
        <CngDialog
            open={isOpen}
            fullWidth
            maxWidth='sm'
            customDialogContent={
                <Paper style={{ textAlign: 'center' }}>
                    <Box m={3} mt={3}>
                        <CngGridItem xs={12} sm={12}>
                            {dialogMessage}
                        </CngGridItem>
                        <CngGridItem xs={12} sm={12} shouldHide={hideBackButton}>
                            <CircularProgress />

                        </CngGridItem>
                        <CngGridItem xs={12} sm={12} shouldHide={!hideBackButton}>
                            <CngSecondaryButton
                                onClick={() => {
                                    setIsOpen(false)
                                    history.push(PathMap_SCO_Job.SCO_JOB_DOC_CONVERSION)
                                }
                                }>
                                Back
                            </CngSecondaryButton>
                        </CngGridItem>

                    </Box>
                </Paper>
            }

        />
    );
}


export default DialogPopup;