import React, { useState, useEffect } from 'react'
import { Box, Paper } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'


import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import WarningDialog from 'src/components/dialog/WarningDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CngBackdrop from '../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'

import SCOJobTranslationText from 'src/views/scojob/job/SCOJobTranslationText'
import JobDetailsComponents from './components/JobDetailsComponents'
import JobTaskComponent from './components/JobTaskComponent'
import JobDocumentComponent from './components/JobDocumentComponent'
import JobButtonComponents from './components/JobButtonComponents'
import JobAttributeDialog from './components/JobAttributeDialog'

import SCOJobApiurls from 'src/apiUrls/SCOJobApiUrls'

const {
      form: {
            adapter: {
                  useFormAdapter: { useFormikContext }
            }
      },
      CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
      id: '',
      ...JobAttributeDialog.initialValues,
      ...JobDetailsComponents.initialValues,
      ...JobTaskComponent.initialValues,
      jobReqRefNo: '',
})

const FORMIK_PROPS = {
      initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({ history, showNotification, loading, id, jobReqRefNo, data}) {
      const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
      const [warningDialogOpen, setWarningDialogOpen] = useState(false)
      const { submitForm, setFieldValue, errors } = useFormikContext()

      let [jobTypeId, setJobTypeId] = useState('')
      const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
      const translatedTextsObject = SCOJobTranslationText()
      const [userAssignedList, setUserAssignedList] = useState([])
      const [jobAttributes, setJobAttributes] = useState(null)
      const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
      const { fetchRecords } = useServices()

      const [isClone, setIsClone] = useState(false)
      const [jobReqDetails, setJobReqDetails] = useState({})
      const [customerCompanyId, setCustomerCompanyId] = useState()
      const { setValue } = useFormContext()

      const handleJobConfigChange = (e) => {
            setJobTypeId(e ? e : "")
      }

      function isEmpty(obj) {
            return Object.keys(obj).length === 0;
      }
      
      useEffect(() => {
            if (data != undefined && data.length != 0) {
                  handleCloneRecords()
                  setIsClone(true)
            }
            console.log('jobFormProperties :: cloned data :: ', data)
      }, [data])

      const handleCloneRecords = () => {
            data.jobAttributes != null && setFieldValue('jobAttributeList', data.jobAttributes)
            data.jobCompleteness != null && setFieldValue('jobCompleteness', data.jobCompleteness)
            data.jobDescription != null && setFieldValue('jobDescription', data.jobDescription)
            data.jobStatusId != null && setFieldValue('jobStatusId', data.jobStatusId)
            data.jobTaskCompleted != null && setFieldValue('jobTaskCompleted', data.jobTaskCompleted)
            data.jobTypeId != null && setFieldValue('jobTypeId', data.jobTypeId)
            data.origin != null && setFieldValue('origin', data.origin)
            data.destination != null && setFieldValue('destination', data.destination)
            data.portOfDischarge != null && setFieldValue('portOfDischarge', data.portOfDischarge)
            data.portOfLoading != null && setFieldValue('portOfLoading', data.portOfLoading)
            data.customerCompanyId != null && setCustomerCompanyId(data.customerCompanyId)
            data.rateCardId != null && setFieldValue('rateCardId', data.rateCardId)
            data.rateOwner != null && setFieldValue('rateOwner', data.rateOwner)
            data.shpRefNo != null && setFieldValue('shpRefNo', data.shpRefNo)
      }

      const onSaveDraft = async () => {
            console.log('On Save Draft')
            if (!isEmpty(errors)) {
                  console.log('errors ' + JSON.stringify(errors))
                  setWarningDialogOpen(true);
            } else {
                  await setFieldValue("saveDraft", true)
                  submitForm()
            }
      }

      const onCreate = async () => {
            console.log('On Create')
            if (!isEmpty(errors)) {
                  console.log('errors ' + JSON.stringify(errors))
            }
            else {
                  await setFieldValue("saveDraft", false)
                  submitForm()
            }
      }


      const onDiscard = () => {
            console.log('On Discard')
            setConfirmDialogOpen(true)
      }

      const onBack = () => {
            history.push({
                  pathname: '/cal-sco/job'
            })
      }


      useEffect(() => {
            fetchRecords.execute(
                  `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
                  {
                        data: {
                              partyId: getPartyID()
                        }
                  },
                  (data) => {
                        setUserAssignedList(
                              data.content.map((datum) => {
                                    return {
                                          text: datum.userProfile.loginId,
                                          value: datum.id
                                    }
                              })
                        )
                  }

            )
      }, [])

      useEffect(() => {
            if(jobReqRefNo !== undefined) {
             setValue('jobReqRefNo', jobReqRefNo)
 
             fetchRecords.execute(
                   SCOJobApiurls.GET_REQUEST_FOR_JOB,
                   {
                         data: {
                               jobReqRefNo: jobReqRefNo
                         }
                   },
                   (data) => {
                        setJobReqDetails(data)
                   },
                   (error) => {
                         console.log(error)
                         history.push("/unauthorized")
                   }
             )
       }
       }, [jobReqRefNo])

      return (
            <Box>
                  <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                        <CngBackdrop loading={loading} />
                  </CngGridItem>

                  <Paper>
                        {!isClone ? <Box className="page-content">
                              <Box pt={5} p={5}>
                                    <JobDetailsComponents.FormBody
                                          parentHandleJobConfigChange={handleJobConfigChange}
                                          jobId={id}
                                          jobReqDetails={jobReqDetails}
                                    />
                              </Box>

                              <Box pt={0} p={5}>
                                    <JobAttributeDialog.FormBody
                                          jobId={id}
                                          jobTypeId={jobTypeId}
                                          jobAttributesData={jobAttributes}
                                          loading={loading}
                                    />
                              </Box>
                              <Box pt={0} p={5}>
                                    <JobTaskComponent.FormBody
                                          jobTypeId={jobTypeId}
                                          loading={loading}
                                          userAssignedList={userAssignedList}
                                          showNotification={showNotification}
                                    />
                              </Box>

                              <Box pt={0} p={5}>
                                    <JobDocumentComponent.FormBody
                                          showNotification={showNotification}
                                    />
                              </Box>

                        </Box>
                              : <Box className="page-content">
                                    <Box pt={5} p={5}>
                                          <JobDetailsComponents.FormBody
                                                parentHandleJobConfigChange={handleJobConfigChange}
                                                jobId={data.id}
                                                jobReqDetails={jobReqDetails}
                                                isClone={isClone}
                                                customerCompanyId={customerCompanyId}
                                          />
                                    </Box>

                                    <Box pt={0} p={5}>
                                          <JobAttributeDialog.FormBody
                                                jobId={data.id}
                                                jobTypeId={data.jobTypeId}
                                                jobAttributesData={data.jobAttributes}
                                                loading={loading}
                                          />
                                    </Box>
                                    <Box pt={0} p={5}>
                                          <JobTaskComponent.FormBody
                                                jobTypeId={data.jobTypeId}
                                                loading={loading}
                                                userAssignedList={userAssignedList}
                                                showNotification={showNotification}
                                                isClone={isClone}
                                                toCloneJobTaskList={data.jobTasksList}
                                          />
                                    </Box>

                                    <Box pt={0} p={5}>
                                          <JobDocumentComponent.FormBody
                                                showNotification={showNotification}
                                                isClone={isClone}
                                          />
                                    </Box>

                              </Box>}
                  </Paper>

                  <Paper className='sticky-footer'>
                        <Box alignItems='center'>
                              <JobButtonComponents
                                    onDiscard={onDiscard}
                                    onSaveDraft={onSaveDraft}
                                    onCreate={onCreate}
                              />
                        </Box>
                  </Paper>

                  <ConfirmDialog
                        isConfirmDialogOpen={confirmDialogOpen}
                        closeDialog={() => setConfirmDialogOpen(false)}
                        confirmDialog={onBack}
                        content={translatedTextsObject.scoJobDiscardChanges}
                        okMsg={uiTranslatedTextsObject.ok}
                        cancelMsg={uiTranslatedTextsObject.cancel}
                        title={translatedTextsObject.scoJobDiscardTitle}
                  />

                  <WarningDialog
                        isDialogOpen={warningDialogOpen}
                        closeDialog={() => setWarningDialogOpen(false)}
                        okDialog={() => setWarningDialogOpen(false)}
                        content={translatedTextsObject.scoFormError}
                        okMsg={uiTranslatedTextsObject.ok}
                  />
            </Box>
      )

}


const JobFormProperties = Object.freeze({
      formikProps: FORMIK_PROPS,
      FormFields: FormFields
})

export default JobFormProperties