import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Menu,
  MenuItem,
  Paper,
} from '@material-ui/core'

import MoreIcon from '@material-ui/icons/MoreVert'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'

import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'




const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngLookupAutocompleteField
    },
  },



} = components




const JobDocumentDetails = (props) => {
  const { submitForm, setFieldValue } = useFormikContext()

  function Download(arrayBuffer, type) {
    var blob = new Blob([arrayBuffer], { type: type });
    console.log(blob)
    var url = URL.createObjectURL(blob);
    window.open(url);
  }


  useEffect(() => {
    var files = props.title
    console.log(files)
  }, [props.title])



  const [anchorMoreActionEl, setAnchorMoreActionEl] = React.useState(null)
  const open = Boolean(anchorMoreActionEl)

  const handleMoreActionClick = (event) => {
    setAnchorMoreActionEl(event.currentTarget)
  }

  const handleMoreActionClose = () => {
    setAnchorMoreActionEl(null)
  }


  if (props.tile == null || props.tile.length <= 0) {
    return (<br />)
  } else {
    return (
      <TableContainer component={Paper}>
        <Table id="table">
          <TableBody>
            {props.tile.map((elem, i) => {
              return (<TableRow key={`row-${i}`}>

                <TableCell className={`file-format`}> <FileCopyIcon /></TableCell>
                <TableCell><DocumentInfoDecoratorComponent name={elem.file.name} size={elem.file.size} /> </TableCell>


                <TableCell>
                  <CngLookupAutocompleteField
                    name={`pendingJobDocuments[${i}].docType`}
                    label={"File Type"}
                    required
                    onChange={(val, evt) => props.handleDropdownChange(val, evt, i, 'docType')}
                    lookupProps={{
                      url: SCOJobApiUrls.DOCUMENT_GETDOCTYPE,
                      label: 'name',
                      value: 'code'
                    }}
                  />


                </TableCell>
                <TableCell><CngTextField
                  type='text'
                  name={`pendingJobDocuments[${i}].fileDescription`}
                  label={"File Description"}
                  required
                  onChange={e => props.handleInputChange(e, i)}
                //error={props.getFieldError(i, 'pendingJobDocuments.fileDescription')}
                //helperText={props.getFieldError(i, 'pendingJobDocuments.fileDescription')}
                />
                </TableCell>
                <TableCell>


                  <IconButton
                    edge='end'
                    className={`row-more-actions`}
                    onClick={handleMoreActionClick}
                  >
                    <MoreIcon />
                  </IconButton>
                  <Menu
                    id='long-menu'
                    anchorEl={anchorMoreActionEl}
                    keepMounted
                    open={open}
                    onClose={handleMoreActionClose}
                    PaperProps={{
                      style: {
                        maxHeight: 'auto',
                        width: 'auto'
                      }
                    }}
                  >
                    <MenuItem key={'r'} onClick={props.removeRowClick(i)}>
                      <DeleteOutlineIcon />Delete
                    </MenuItem>

                  </Menu>
                </TableCell>


              </TableRow>)
            })
            }
          </TableBody >
        </Table>
      </TableContainer>

    )
  }

}

export default JobDocumentDetails
