import { Grid, Box, CircularProgress, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import Paper from '@material-ui/core/Paper';
import * as Yup from 'yup'
import { useFormContext } from 'react-hook-form'
import { Download } from 'react-feather'
import SCOJobTranslationText from '../SCOJobTranslationText'


const {
    dropzone: { CngFileUpload, HelperTextArea},
    form: {
        adapter: {
            useFormAdapter: { useField }
        },
        CngForm
    },
    button: { CngPrimaryButton },
    CngGridItem,
} = components
const { FormState, NotificationType } = constants



function InventoryDocument({ showNotification, jobId, selectedMoveType, setSelectedMoveType, movementType, setMovementType, taskStatus, jobStatus }) {
    const [successMsg, setSuccessMsg] = useState('')
    const { securedSendRequest } = useServices()

    // new------
    const [shouldShowPrimaryButtonCircularProgress, setShouldShowPrimaryButtonCircularProgress] = useState(false)
    const [errorMsgs, setErrorMsgs] = useState([]);
    const validationSchema = Yup.object({})

    const translatedTextsObject = SCOJobTranslationText();

    function handleDisableUpload(){
        if(taskStatus === 'COMPLETED' || jobStatus==='CANC' ){
            console.log("Inside handleDisableUpload ...... with task Status:: "+ JSON.stringify(taskStatus))
            return true
        }
    }

    function handleDisableSubmitBtn(){
        if(taskStatus === 'COMPLETED' || jobStatus==='CANC'){
            console.log("Inside handleDisableSubmitBtn ...... with task Status:: "+ JSON.stringify(taskStatus))
            return true
        }
    }

    function onSubmit(data) {
        setShouldShowPrimaryButtonCircularProgress(true)
        setSuccessMsg('')
        setErrorMsgs([])

        return new Promise((resolve) => {
            if (data && data.files.length > 0) {
                var formData = new FormData()
                formData.append('fileContents', data.files[0].file)
                formData.append('movementType', selectedMoveType)
                formData.append('jobId', jobId)

                securedSendRequest.execute('POST', SCOJobApiUrls.PROCESS_INVENTORY, formData,
                    (response) => {
                        setShouldShowPrimaryButtonCircularProgress(false)
                        setSuccessMsg(response.data.status)
                        resolve()
                    },
                    (error) => {
                        setShouldShowPrimaryButtonCircularProgress(false)
                        setErrorMsgs(error.response.data.errorMessages);
                        resolve()

                    },
                    () => {
                        //complete
                        resolve()
                    }
                )
            } else {
                setShouldShowPrimaryButtonCircularProgress(false)
                resolve()
            }
        })
    }
    // new --------

    return (
        <Paper >
            <CngForm
                formikProps={{
                    initialValues: {
                        files: []
                    },
                    validationSchema: validationSchema,
                    onSubmit: onSubmit
                }}
                renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
                    <>
                        <Fields
                            labelMap={labelMap}
                            shouldHideMap={shouldHideMap}
                            shouldDisableMap={shouldDisableMap}
                            showNotification={showNotification}
                            errorMsgs={errorMsgs}
                            setErrorMsgs={setErrorMsgs}
                            selectedMovetype={selectedMoveType}
                            movementType={movementType}
                            successMsg={successMsg}
                            setSuccessMsg={setSuccessMsg}
                            translatedTextsObject={translatedTextsObject}
                            taskStatus={taskStatus}
                            handleDisableUpload ={handleDisableUpload}
                        />
                    </>
                )}
                renderButtonSection={() => (
                    <>
                    <SubmitButton
                        shouldShowPrimaryButtonCircularProgress={shouldShowPrimaryButtonCircularProgress}
                        setShouldShowPrimaryButtonCircularProgress={setShouldShowPrimaryButtonCircularProgress}
                        showNotification={showNotification}
                        onSubmit={onSubmit}
                        handleDisableSubmitBtn={handleDisableSubmitBtn}
                        taskStatus ={taskStatus}
                    />
                    </>
                )}
                formState={FormState.COMPLETED}
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            />
        </Paper>

    )




}

const SubmitButton = ({ shouldShowPrimaryButtonCircularProgress, onSubmit, handleDisableSubmitBtn, taskStatus }) => {
    const {
        formState: { isSubmitting },
        handleSubmit
    } = useFormContext()

    const handleDisable = handleDisableSubmitBtn(taskStatus)

    return (
        <Box p={5} style={{ textAlign: 'center' }}>
            <CngPrimaryButton
                type='submit'
                disabled={handleDisable}
                onClick={handleSubmit(onSubmit)}
                endIcon={
                    shouldShowPrimaryButtonCircularProgress ? (
                        <CircularProgress color='inherit' size={10} />
                    ) : null
                }
            >
                Upload
            </CngPrimaryButton>
        </Box >
    );
}



function Fields({ showNotification, errorMsgs, setErrorMsgs, movementType, successMsg, setSuccessMsg, translatedTextsObject,taskStatus, handleDisableUpload }) {
    const [uploadFile, , { setValue: setUploadFile }] = useField('files')
    const moreActions = [
        {
            action: 'remove',
            name: 'Remove',
            icon: ['fal', 'trash']
        }
    ]



    const downloadTemplateClickHandler = () => {
        let fileName = '';
        let filePath = '';
        if (movementType === undefined) {
            showNotification(NotificationType.ERROR, "Movement Type is Mandatory. Please select a Movement Type.")
        } else if (movementType === 'Inbound') {
            fileName = 'INVENTORY_TEMPLATE_INBOUND.xlsx'
        } else if (movementType === 'Outbound') {
            fileName = 'INVENTORY_TEMPLATE_OUTBOUND.xlsx'
        } else if (movementType === 'Transfer') {
            fileName = 'INVENTORY_TEMPLATE_TRANSFER.xlsx'
        } else {
            showNotification(NotificationType.ERROR, "Movement Type not supported. Please contact System Admin.")
        }
        filePath = `${process.env.PUBLIC_URL}/static/scojob/${fileName}`
        if (fileName !== '' && filePath !== '') {
            let a = document.createElement('a')
            a.href = filePath
            a.setAttribute('download', fileName)
            a.click()
        }

    }



    useEffect(() => {
        if (uploadFile.value.length == 0 && errorMsgs.length > 0 || uploadFile.value.length == 0 && successMsg.length > 0) {
            setErrorMsgs([])
            setSuccessMsg('')
        }
    }, [uploadFile])


    return (
        < Grid container spacing={3} >
            <CngGridItem xs={12} sm={12}>
                <Box style={{ float: 'right' }} pr={5} pt={2} >
                    <Button
                        variant='contained'
                        color='secondary'
                        startIcon={<Download style={{ marginRight: 5 }} />}
                        classes={{ root: 'ng-button-filled-secondary' }}
                        onClick={() => { downloadTemplateClickHandler() }}
                    >
                        {translatedTextsObject.scoJobDownloadTemplate}
                    </Button>
                </Box>
            </CngGridItem>
            <CngGridItem xs={12} sm={12}>
                <Box pr={5} pl={5}>
                    <Box alignItems='center' pt={1} >
                        <CngFileUpload
                            disabled={handleDisableUpload(taskStatus)}
                            accept={['.xls', '.xlsx']}
                            maxFiles={1}
                            maxSize={10485760}
                            onFileSelect={(files) => {
                                setUploadFile(files)
                            }}
                            files={uploadFile.value}
                            showFormFields={false}
                            moreActions={moreActions}
                            onDropRejected={(errorMessages) => {
                                showNotification(NotificationType.ERROR, errorMessages[0])
                            }}
                            renderHelperText={() => {
                                return (
                                  <HelperTextArea
                                    accept={['.xls', '.xlsx']}
                                    maxFiles={1}
                                    maxSize={1048576}
                                  />
                                )
                            }}
                        />
                    </Box>
                </Box>
            </CngGridItem>
            {errorMsgs && errorMsgs.length > 0 &&
                < CngGridItem xs={12} sm={12}>
                    <Box pr={5} pl={5}>
                        <Box alignItems='center'>
                            {errorMsgs.map((msg) => (

                                <p style={{ color: '#FF808B' }}>
                                    {msg}
                                </p>
                            ))}
                        </Box>
                    </Box>
                </CngGridItem>
            }
            {successMsg && successMsg.length > 0 &&
                < CngGridItem xs={12} sm={12}>
                    <Box pr={5} pl={5}>
                        <Box alignItems='center'>
                            <p style={{ color: '#33CC33' }}>
                                {successMsg}
                            </p>
                        </Box>
                    </Box>
                </CngGridItem>

            }
        </Grid >
    )
}

export default InventoryDocument