import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import { constants, useTranslation } from 'cng-web-lib'
import SCOJMCustomAttributeKeys from "../../../constants/locale/key/SCOJMCustomAttributeKeys"

const NAMESPACE = Namespace.SCO_JM_CUSTOM_ATTR
const {
  locale: {
    key: {
      UiComponentKeys
    }
  }
} = constants

const TranslationText = () => {
  const { translate } = useTranslation([NAMESPACE])
  const moduleTitle = translate(NAMESPACE, SCOJMCustomAttributeKeys.MODULE_TITLE)
  const customAttributeView = translate(NAMESPACE, SCOJMCustomAttributeKeys.CUSTOM_ATTRIBUTE_VIEW)
  const customAttributeAdd = translate(NAMESPACE, SCOJMCustomAttributeKeys.CUSTOM_ATTRIBUTE_ADD)
  const customAttributeEdit = translate(NAMESPACE, SCOJMCustomAttributeKeys.CUSTOM_ATTRIBUTE_EDIT)
  const customAttrHeader = translate(NAMESPACE, SCOJMCustomAttributeKeys.HEADER.CUSTOM_ATTRIBUTE)
  const customAttrValueHeader = translate(NAMESPACE, SCOJMCustomAttributeKeys.HEADER.CUSTOM_ATTRIBUTE_VALUE)

  // fields
  const category = translate(NAMESPACE, SCOJMCustomAttributeKeys.FIELDS.CATEGORY)
  const attributeName = translate(NAMESPACE, SCOJMCustomAttributeKeys.FIELDS.NAME)
  const value = translate(NAMESPACE, SCOJMCustomAttributeKeys.FIELDS.VALUE)

  return {
    moduleTitle,
    customAttributeView,
    customAttributeAdd,
    customAttributeEdit,
    customAttrHeader,
    customAttrValueHeader,
    category,
    attributeName,
    value
  }
}

export default TranslationText
