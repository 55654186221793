import { Yup } from 'cng-web-lib'
import SCOJobConfigTranslationText from 'src/views/scojob/jobconfig/SCOJobConfigTranslationText'

import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants


function JobConfigNameSchema() {
  const translatedTextsObject = SCOJobConfigTranslationText()

  const mandatoryErrMsg = 'Required/Mandatory.'


  return Yup.object({
    jobConfigurationName: Yup.string().required(mandatoryErrMsg).nullable(false).max(200, 'Maximum 200 characters.')
  })

}

export default JobConfigNameSchema
