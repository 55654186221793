import { Card, CardContent, Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import { default as React, useState } from 'react'
import { useFormContext } from "react-hook-form"
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import { makeValidationSchema } from './JobAttribute_ValidationSchema'
import SCOJobAttributeTranslationText from './SCOJobAttributeTranslationText'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import CustomAttributeUrls from 'src/apiUrls/sco-jm-custom-attr/CustomAttributeUrls'



const {
    form: {
        field: {
            CngTextField,
            CngLookupAutocompleteField
        },
    },
    CngGridItem,
} = components

const {
    filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = {
    id: "",
    attributeType: "",
    attributeName: "",
    dataType: "",
    customAttributeId:""
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled } = props;
    const fieldsTranslatedTextObject = SCOJobAttributeTranslationText()
    const { getValues } = useFormContext();
    const values = getValues();
    console.log('this is values ' + JSON.stringify(values))
    const [customAttributeOn, setCustomAttributeOn] = useState(false)

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.scoAttributeConfigurationDetails} />
                                <Grid container spacing={2}>
                                    <CngGridItem xs={4} sm={4} >
                                        <CngLookupAutocompleteField
                                            type='number'
                                            required
                                            name='attributeType'
                                            label={fieldsTranslatedTextObject.scoAttributeType}
                                            disabled={values.id != "" ? true : false}
                                            lookupProps={{
                                                url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                                label: 'description',
                                                value: 'id',
                                                filters: [
                                                    {
                                                        field: 'codeType',
                                                        operator: EQUAL,
                                                        value: 'JOB_ATTR_TYPE'
                                                    }
                                                ]
                                            }}

                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            required
                                            name="attributeName"
                                            label={fieldsTranslatedTextObject.scoAttributeName}
                                        />
                                    </CngGridItem>


                                    <CngGridItem xs={4} sm={4} >
                                        <CngLookupAutocompleteField
                                            type='number'
                                            required
                                            name='dataType'
                                            label={fieldsTranslatedTextObject.scoAttributeDataType}
                                            disabled={values.id != "" ? true : false}
                                            onChange={(value, obj) => {
                                                if(obj && obj.label === "Custom Attribute")
                                                    setCustomAttributeOn(true)
                                                else
                                                    setCustomAttributeOn(false)
                                            }}
                                            lookupProps={{
                                                url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                                label: 'description',
                                                value: 'id',
                                                filters: [
                                                    {
                                                        field: 'codeType',
                                                        operator: EQUAL,
                                                        value: 'COM_INPUT_TYPE'
                                                    }
                                                ]
                                            }}

                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4} >
                                        <CngLookupAutocompleteField
                                          type='number'
                                          required={customAttributeOn}
                                          hidden={!customAttributeOn && !(values.id != "" && values.customAttributeId != "")}
                                          name='customAttributeId'
                                          label={fieldsTranslatedTextObject.scoCustomAttribute}
                                          disabled={values.id != "" ? true : false}
                                          lookupProps={{
                                              url: CustomAttributeUrls.CUSTOM_ATTR_GET,
                                              label: 'attributeName',
                                              value: 'id',
                                              filters: []
                                          }}
                                        />
                                    </CngGridItem>


                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties