import JobDetailsSchema from './validationSchemas/JobDetailsSchema'
import JobTaskSchema from './validationSchemas/JobTaskSchema'
import JobDocumentValidationSchema from './validationSchemas/JobDocumentValidationSchema'
import JobAttributesSchema from './validationSchemas/JobAttributesSchema'
import InventoryFormSchema from './validationSchemas/InventoryFormSchema'

function JobValidationSchema() {
      return JobDetailsSchema()
            .concat(JobAttributesSchema())
            .concat(JobTaskSchema())
            // .concat(InventoryFormSchema())
      //.concat(JobDocumentValidationSchema())
}

export default JobValidationSchema