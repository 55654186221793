import CreateNewJobConfigPage from './CreateNewJobConfigPage'
import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import ManageJobConfigsTable from './ManageJobConfigsTable'
import ViewPage from './ViewPage'
import EditPage from './EditPage'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_SCOJob'
import SCOJobConfigTranslationText from './SCOJobConfigTranslationText'


function WrappedTablePage(props) {
    const translatedTextsObject = SCOJobConfigTranslationText();
    const breadcrumbNameMap = useBreadcrumbNameMap()


    return (
        <CngBasePage
            moduleTitle={translatedTextsObject.scoJobConfigTableModuleTitle}
            renderPage={(showSnackbar) => (
                <ManageJobConfigsTable showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

function WrappedAddPage(props) {
    const translatedTextsObject = SCOJobConfigTranslationText();
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={translatedTextsObject.scoAddJobConfigModuleTitle}
            renderPage={(showSnackbar) => (
                <CreateNewJobConfigPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

function WrappedViewPage(props) {
    const translatedTextsObject = SCOJobConfigTranslationText();
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={translatedTextsObject.scoViewJobConfigModuleTitle}
            renderPage={(showSnackbar) => (
                <ViewPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}


function WrappedEditPage(props) {
    const translatedTextsObject = SCOJobConfigTranslationText();
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={translatedTextsObject.scoEditJobConfigModuleTitle}
            renderPage={(showSnackbar) => (
                <EditPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}


export {
    WrappedAddPage as AddPage,
    WrappedTablePage as TablePage,
    WrappedViewPage as ViewPage,
    WrappedEditPage as EditPage
}
