import { constants, Yup } from 'cng-web-lib'


const {
	locale: {
		key: {
			CommonValidationMessageKeys,
		},
	},
} = constants


export function makeValidationSchema(translate) {


	return Yup.object({
		attributeType: Yup.number().required("Required/Mandatory"),
		attributeName: Yup.string().required("Required/Mandatory").max(255, "Attribute Name cannot be more than 255 characters."),
		dataType: Yup.number().required("Required/Mandatory.")
	})
}

