import { Card, CardContent, Grid, Box, CircularProgress, Paper,FormControlLabel, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { components, useServices, constants } from 'cng-web-lib'

import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import InventoryFormProperties from './InventoryFormProperties'
import InventoryFormSearchByLotNumber from './InventoryForm_SearchByLotNumber'
import InventoryFormSchema from '../validationSchemas/InventoryFormSchema'
import SCOJobTranslationText from 'src/views/scojob/job/SCOJobTranslationText'
import { getPartyID } from 'src/store/intelligent-advisory'

const {
    form: {
        field: {
            CngLookupAutocompleteField,
            CngTextField, CngRadioGroupField 
        },
        adapter: {
            useFormAdapter: { useField, }
        },
        CngForm
    },
    table: {
        CngLocalModeDialogFormTable,
        useFetchCustomLookup
    },
    button: { CngPrimaryButton },
    CngGridItem
} = components


const {
    FormState,
    NotificationType,
    filter: {
        EQUAL
    }
} = constants


const DEFAULT_INITIAL_VALUES = Object.freeze({
    id: '',
    buyerId: '',
    dutyStatusId: '',
    permitTypeId: '',
    locationId: '',
    operatorId: '',
    movementType: '',
    jobId: '',
    invFormList: []
})




function InventoryDetails({ showNotification, jobId, movementType, taskStatus, jobStatus}) {
    const { fetchRecords, securedSendRequest } = useServices()
    const [shouldShowPrimaryButtonCircularProgress, setShouldShowPrimaryButtonCircularProgress] = useState(false);
    const [successMsg, setSuccessMsg] = useState('')
    const [errorMsgs, setErrorMsgs] = useState([]);
    const [orderFormCall, setOrderFormCall] = useState('')
    const [invDetailsForm, setInvDetailsForm] = useState([])
    const [hideAdd, setHideAdd] = useState(false)
    const [hideSubmitBtn, setHideSubmitBtn] = useState(false)
    const [hideBin, setHideBin] = useState(false)

    console.log("Inside Inventory Details::: task status: "+ JSON.stringify(taskStatus))

    function getInvFormByJobId() {
        const onSuccess = (response) => {
            console.log(JSON.stringify(response))
            if (response?.id === null) {
                setOrderFormCall(SCOJobApiUrls.ADD_ORDER_FORM)
            } else {
                setOrderFormCall(SCOJobApiUrls.UPDATE_ORDER_FORM)
                setInvDetailsForm(response)
                setHideAdd(true)
            }
        }

        const onError = (error) => {
            console.log("Error:[" + JSON.stringify(error) + "]")
        }

        const onComplete = () => {
            console.log('completed')
        }

        fetchRecords.execute(
            SCOJobApiUrls.ORDER_FORM_GET_BY_JOB_ID,
            {
                filters: [
                    {
                        field: 'jobId',
                        operator: 'EQUAL',
                        value: jobId
                    }
                ]
            },
            onSuccess,
            onError,
            onComplete
        );
    }


    function onSubmit(data) {
        setShouldShowPrimaryButtonCircularProgress(true)
        setSuccessMsg('')
        setErrorMsgs('')
        if (data !== null) {
            if (data.invFormList.length === 0) {
                showNotification(NotificationType.ERROR, "Upload Form data is empty")
            } else {
                data.jobId = jobId
                data.movementType = movementType
                securedSendRequest.execute('POST', orderFormCall, data,
                    (response) => {
                        console.log(response)
                        setSuccessMsg('Form successfully uploaded.')
                        setHideSubmitBtn(true)
                        setHideBin(true)
                        setHideAdd(true)
                    },
                    (error) => {
                        if (error.response !== undefined) {
                            console.log("Inside onSubmit returns error :::: ",error.response.data.errorMessages)
                            setErrorMsgs(error.response.data.errorMessages)
                        } else {
                            showNotification(NotificationType.ERROR, 'Error uploading form.')
                        }
                        setShouldShowPrimaryButtonCircularProgress(false)
                        setHideSubmitBtn(false)
                    },
                    () => {
                        setShouldShowPrimaryButtonCircularProgress(false)
                    }
                )
            }
        }
    }

    useEffect(() => {
        getInvFormByJobId()
        if(errorMsgs.length > 0 || successMsg.length > 0){
            setErrorMsgs([])
            setSuccessMsg('')
        }
        
        return () => {
            console.log('')
        }
    }, [jobId])


    const handleDutyStatusChange =(e, val, setDutyStatusObj ) => {
        console.log('Inside HandleDutyStatusChange, Duty Status:::: ' + e)
        setDutyStatusObj(val)
    }

    const handlePermitTypeChange =(e, val ) => {
        console.log('Inside handlePermitTypeChange, Permit Type:::: ' + e)
    }

    function handleDisableSubmitBtn(){
        if(taskStatus === 'COMPLETED' || jobStatus === 'CANC'){
            console.log("Inside handleDisableSubmitBtn ...... with task Status:: "+ JSON.stringify(taskStatus))
            console.log("Inside handleDisableSubmitBtn ...... with job Status:: "+ JSON.stringify(jobStatus))
            return true
        }
    }


    return (
        <Paper>
            <CngForm
                innerForm
                formikProps={{
                    initialValues: { ...DEFAULT_INITIAL_VALUES },
                    validationSchema: InventoryFormSchema(),
                    onSubmit: onSubmit
                }}
                renderBodySection={() => (
                    <>
                        <Fields
                            showNotification={showNotification}
                            errorMsgs={errorMsgs}
                            successMsg={successMsg}
                            invDetailsForm={invDetailsForm}
                            shouldHideAdd={hideAdd}
                            shouldHideBin= {hideBin}
                            handleDutyStatusChange= {handleDutyStatusChange}
                            handlePermitTypeChange = {handlePermitTypeChange}
                        />
                    </>
                )}
                renderButtonSection={() => (
                    <>
                        <SubmitButton
                            shouldShowPrimaryButtonCircularProgress={shouldShowPrimaryButtonCircularProgress}
                            invDetailsFormId={invDetailsForm.id}
                            onSubmit={onSubmit}
                            hideSubmitBtn={hideSubmitBtn}
                            handleDisableSubmitBtn={handleDisableSubmitBtn}
                            taskStatus ={taskStatus}
                        />
                    </>
                )}
                formState={FormState.COMPLETED}
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            />
        </Paper>
    )
}



const SubmitButton = ({ shouldShowPrimaryButtonCircularProgress, invDetailsFormId, onSubmit, hideSubmitBtn, handleDisableSubmitBtn, taskStatus }) => {
    const {
        formState: { isSubmitting },
        handleSubmit
    } = useFormContext()

    const handleDisable = handleDisableSubmitBtn(taskStatus)
    return (
        <Box p={5} style={{ textAlign: 'center' }}>
            <CngGridItem shouldHide={hideSubmitBtn} >
                <CngPrimaryButton
                    type='submit'
                    onClick={handleSubmit(onSubmit)}
                    endIcon={
                        shouldShowPrimaryButtonCircularProgress ? (
                            <CircularProgress color='inherit' size={10} />
                        ) : null
                    }
                    disabled = {handleDisable}
                >
                    {invDetailsFormId !== undefined ? 'Update Form' : 'Upload Form'}
                </CngPrimaryButton>
            </CngGridItem>

        </Box >
    );
}




function Fields({ showNotification, errorMsgs, successMsg, invDetailsForm, shouldHideAdd, shouldHideBin, shouldEnableAdd, handleDutyStatusChange, handlePermitTypeChange, jobStatus}) {
    const translatedTextsObject = SCOJobTranslationText()
    const { setValue } = useFormContext()
    const INV_DUTY_STATUS = 'INV_DUTY_STATUS';
    const INV_PERMIT_TYPE = 'INV_PERMIT_TYPE';
    const INV_UOM = 'INV_UOM';
    const fetchCustomLookup = useFetchCustomLookup();
    const partyId = getPartyID()
    const [renderId, setRenderId] = useState('')
    const [dutyStatusId] = useField('dutyStatusId');
    const [permitTypeId] = useField('permitTypeId');
    const [dutyStatusObj, setDutyStatusObj] = useState( {value: '', label:''})
    const [preferredSearch, setPreferedSearch] = useState('');

    useEffect(() => {
        if (invDetailsForm?.id !== undefined) {
            setValue('id', invDetailsForm.id, true)
            setValue('buyerId', invDetailsForm.buyerId, true)
            setValue('dutyStatusId', invDetailsForm.dutyStatusId, true)
            setValue('permitTypeId', invDetailsForm.permitTypeId, true)
            setValue('locationId', invDetailsForm.locationId, true)
            setValue('operatorId', invDetailsForm.operatorId, true)
            setValue('movementType', invDetailsForm.movementType, true)
            setValue('invFormList', invDetailsForm.invFormList, true)
            setRenderId(invDetailsForm.id)
        }

        return () => {
            console.log('')
        }

    }, [invDetailsForm])


    const handleSelectRadioBtnChange = (e, val) => {
        console.log("Insdie handleSelectBtn :::: " + JSON.stringify(e.target.value));

        if(e.target.value !== null ){
              setValue('preferredSearch', e.target.value)
              setPreferedSearch(e.target.value)

        }else{          
              setValue('preferredSearch', "")       
              setPreferedSearch("")
        }
    }

    function displayLayout(preferredSearch){
        console.log("Inside Display Layout, preferredSearch --> ", preferredSearch);
        
        if(preferredSearch === "searchByLotNum" ){
            return (
                {
                    ...InventoryFormSearchByLotNumber,
                    formikProps: {
                        ...InventoryFormSearchByLotNumber.formikProps,
                    },
                    dutyStatusId: dutyStatusObj,
                    permitTypeId: permitTypeId,
                }
            )
        }else{
            return({
                ...InventoryFormProperties,
                formikProps: {
                    ...InventoryFormProperties.formikProps,
                },
                dutyStatusId: dutyStatusObj,
                permitTypeId: permitTypeId,
            })
        }
    }


    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} >
                <Box style={{ 'padding': '15px' }}>
                    Inventory Details                
                    <Grid container spacing={3}>
                        <CngGridItem xs={3} sm={4}>
                            <CngLookupAutocompleteField
                                name={`buyerId`}
                                key={'buyer' + renderId}
                                label={translatedTextsObject.buyer}
                                lookupProps={{
                                    url: SCOInvMgmtApiUrls.GET_BUYER,
                                    filters: [
                                        {
                                            field: 'partyId',
                                            operator: EQUAL,
                                            value: partyId
                                        }
                                    ],
                                    label: 'buyerName',
                                    value: 'id'
                                }}
                            />
                        </CngGridItem>
                        <CngGridItem xs={3} sm={4}>
                            <CngLookupAutocompleteField
                                name={`locationId`}
                                key={'location' + renderId}
                                label={translatedTextsObject.location}
                                lookupProps={{
                                    url: SCOInvMgmtApiUrls.GET_NONSTORAGE_LOCATION_LIST,
                                    filters: [
                                        {
                                            field: 'partyId',
                                            operator: EQUAL,
                                            value: partyId
                                        }
                                    ],
                                    label: 'locationName',
                                    value: 'id'
                                }}
                            />
                        </CngGridItem>

                        <CngGridItem xs={3} sm={4}>
                            <CngLookupAutocompleteField
                                key={'operatorId' + renderId}
                                label={translatedTextsObject.operator}
                                name={`operatorId`}
                                lookupProps={{
                                    url: CecAccountApiUrls.GET_ACCOUNT,
                                    filters: [
                                        {
                                            field: 'partyId',
                                            operator: EQUAL,
                                            value: partyId
                                        },
                                        {
                                            field: 'status',
                                            operator: EQUAL,
                                            value: 'ACTV'
                                        }
                                    ],
                                    label: 'displayName',
                                    value: 'id'
                                }}
                            />
                        </CngGridItem>


                        <CngGridItem xs={3} sm={4}>
                            <CngLookupAutocompleteField
                                name={`dutyStatusId`}
                                key={'dutyStatus' + renderId}
                                required
                                disabled={
                                    (invDetailsForm.length !== 0 && invDetailsForm.id !== null)
                                        | (invDetailsForm.length !== 0 && invDetailsForm.id !== '')
                                        ? true : false
                                }
                                label={translatedTextsObject.fromDutyStatus}
                                lookupProps={{
                                    url: `${SCOInvMgmtApiUrls.GET_CODE_MASTER_DROPDOWN}/${INV_DUTY_STATUS}`,
                                    label: 'name',
                                    value: 'id'
                                }}
                                onChange={(e, val) => handleDutyStatusChange(e, val, setDutyStatusObj)}
                            />
                        </CngGridItem>

                        <CngGridItem xs={3} sm={4}>
                            <CngLookupAutocompleteField
                                name={`permitTypeId`}
                                key={'permitType' + renderId}
                                required
                                disabled={
                                    (invDetailsForm.length !== 0 && invDetailsForm.id !== null)
                                        | (invDetailsForm.length !== 0 && invDetailsForm.id !== '')
                                        ? true : false
                                }
                                label={translatedTextsObject.fromPermitType}
                                lookupProps={{
                                    url: `${SCOInvMgmtApiUrls.GET_CODE_MASTER_DROPDOWN}/${INV_PERMIT_TYPE}`,
                                    label: 'name',
                                    value: 'id'
                                }}
                                onChange={(e, val) => handlePermitTypeChange(e, val)}
                            />
                        </CngGridItem>


                        <CngGridItem xs={3} sm={4} shouldHide={true}>
                            <CngTextField
                                name={`id`}
                                key={`id` + renderId}
                                label={'detailsId'}
                                disabled={true}
                            />
                        </CngGridItem>

                        { (dutyStatusId.value != '' && permitTypeId.value != '') && (
                          <>
                            <CngGridItem item xs={12} sm={12}>
                                <FormControlLabel
                                  control ={
                                      <CngRadioGroupField
                                        disabled={false}
                                        name='preferredSearch'
                                        defaultValue="searchBySKU"
                                        options={[
                                            { label: 'SKU Number', value: 'searchBySKU' },
                                            { label: 'Lot Number', value: 'searchByLotNum' }
                                        ]}
                                        direction='row'
                                        onChange={(e, val) => handleSelectRadioBtnChange(e, val)}
                                        key={'preferredSearch' + renderId}
                                      />
                                  }
                                  label= "Search SKU by:"
                                  labelPlacement="start"
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={12}>
                                <Card style={{ 'padding': '15px' }}>
                                    <CardContent>
                                        <CngLocalModeDialogFormTable
                                          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                                          showNotification={showNotification}
                                          propertyName={`invFormList`}
                                          key={`invFormList` + renderId}
                                          tableColumns={[
                                              {
                                                  title: translatedTextsObject.skuNum,
                                                  field: 'skuId',
                                                  filtering: false,
                                                  sorting: false,
                                                  customLookup: () => {
                                                      return fetchCustomLookup(
                                                        `${SCOInvMgmtApiUrls.GET_SKU}`,
                                                        {
                                                            "partyId": partyId
                                                        },
                                                        'content',
                                                        'skuNum',
                                                        'id',
                                                        (error) => console.error(error)
                                                      )
                                                  }
                                              },
                                              {
                                                  title: translatedTextsObject.skuDesc,
                                                  field: 'skuDesc',
                                                  filtering: false,
                                                  sorting: false
                                              },
                                              {
                                                  title: translatedTextsObject.lotNum,
                                                  field: 'lotNumber',
                                                  filtering: false,
                                                  sorting: false
                                              },
                                              {
                                                  title: translatedTextsObject.quantity,
                                                  field: 'quantity',
                                                  filtering: false,
                                                  sorting: false
                                              },
                                              {
                                                  title: translatedTextsObject.uom_capital,
                                                  field: 'uomId',
                                                  filtering: false,
                                                  sorting: false,
                                                  customLookup: () => {
                                                      return fetchCustomLookup(
                                                        `${SCOInvMgmtApiUrls.GET_CODE_MASTER_DROPDOWN}/${INV_UOM}`,
                                                        {},
                                                        'content',
                                                        'name',
                                                        'id',
                                                        (error) => console.error(error)
                                                      )
                                                  }
                                              },
                                          ]}
                                          pageSize={5}
                                          formProperties = {displayLayout(preferredSearch)}
                                          initialData={DEFAULT_INITIAL_VALUES.invFormList}
                                          editButtonProps={{
                                              hideIf: () => {
                                                  return (true)
                                              },
                                          }}
                                          viewButtonProps={{
                                              hideIf: () => {
                                                  return (true)
                                              },
                                          }}
                                          deleteButtonProps={{
                                              hideIf: (record) => {
                                                  if(shouldHideBin){
                                                      return (true)
                                                  }
                                                  if (record.id !== '') {
                                                      return (true)
                                                  }
                                              }
                                          }}
                                          addButtonProps={{
                                              hidden: shouldHideAdd,
                                          }}
                                        />
                                    </CardContent>
                                </Card>
                            </CngGridItem>
                          </>)}
                    </Grid>
                </Box>

                {errorMsgs && errorMsgs.length > 0 &&
                    < CngGridItem xs={12} sm={12}>
                        <Box pr={5} pl={5}>
                            <Box alignItems='center'>
                                {errorMsgs.map((msg, index) => (
                                    <p 
                                        key={'errorMsgs' + renderId}
                                        style={{ color: '#FF808B', marginTop:'10px' }}
                                    >
                                        {msg}
                                    </p>
                                ))}
                            </Box>
                        </Box>
                    </CngGridItem>
                }
                {successMsg && successMsg.length > 0 &&
                    < CngGridItem xs={12} sm={12}>
                        <Box pr={5} pl={5}>
                            <Box alignItems='center'>
                                <p style={{ color: '#33CC33', marginTop:'10px' }}>
                                    {successMsg}
                                </p>
                            </Box>
                        </Box>
                    </CngGridItem>

                }

            </Grid>
        </Grid>
    )
}

export default InventoryDetails

