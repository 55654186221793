import Namespace from 'src/constants/locale/Namespace'
import SCOJob from 'src/constants/locale/key/SCOJob'
import { useTranslation } from 'cng-web-lib'

const SCOJobAttributeTranslationText = () => {
    const { translate } = useTranslation([
        Namespace.UI_COMPONENT,
        Namespace.SCO_JOB
    ])

    let scoJobAttributeConfiguration = translate(
        Namespace.SCO_JOB,
        SCOJob.JOB_ATTRIBUTE_CONFIGURATION
    )

    let scoAttributeType = translate(
        Namespace.SCO_JOB,
        SCOJob.ATTRIBUTE_TYPE
    )

    let scoAttributeName = translate(
        Namespace.SCO_JOB,
        SCOJob.ATTRIBUTE_NAME
    )

    let scoAttributeDataType = translate(
        Namespace.SCO_JOB,
        SCOJob.DATA_TYPE
    )

    let scoAttributeConfigurationDetails = translate(
        Namespace.SCO_JOB,
        SCOJob.ATTRIBUTE_CONFIGURATION_DETAILS
    )

    let scoAttributeCreatedDate = translate(
        Namespace.SCO_JOB,
        SCOJob.CREATED_DATE
    )

    let scoCustomAttribute = translate(
      Namespace.SCO_JOB,
      SCOJob.CUSTOM_ATTRIBUTE
    )

    return {
        scoJobAttributeConfiguration,
        scoAttributeType,
        scoAttributeName,
        scoAttributeDataType,
        scoAttributeConfigurationDetails,
        scoAttributeCreatedDate,
        scoCustomAttribute
    }
}

export default SCOJobAttributeTranslationText
