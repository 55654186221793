import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import NewJobConfigFormProperties from './NewJobConfigFormProperties'
import SCOJobConfigApiurls from 'src/apiUrls/SCOJobApiUrls'
import JobConfigValidationSchema from './JobConfigValidationSchema'


const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function CreateNewJobConfigPage({ history, showNotification }) {
  const { id } = useParams()
  const { createRecord } = useServices()
  const [loading, setLoading] = useState(false);
  const validationSchema = JobConfigValidationSchema()


  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)


  //when error (mandatory fields is missing)
  function onError(error) {
    setLoading(false);
    showErrorNotification(error.response.data.errorMessages)
  }

  function onSuccess(response) {
    setLoading(false);
    console.log('Success', response)
    showSuccessNotification('Job Configuration created succcessfully.')
    history.push({
      pathname: '/cal-sco/jobconfig'
    })

  }


  function onSubmit(data) {
    setLoading(true);
    console.log('save Job Config data : ' + JSON.stringify(data))
    createRecord.execute(
      SCOJobConfigApiurls.CREATE,
      data,
      onSuccess,
      onError
    )

  }

  return (
    <Grid>

      <CngViewForm
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        history={history}
        showNotification={showNotification}
        bodySection={
          <NewJobConfigFormProperties.FormFields
            history={history}
            showNotification={showNotification}
            disabled={false}
            mode={'add'}
          />
        }
        formikProps={{
          ...NewJobConfigFormProperties.formikProps,
          validationSchema,
          enableReinitialize: true,
          onSubmit: onSubmit

        }}
      />


    </Grid>
  )
}

export default CreateNewJobConfigPage
