import { Grid } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'

import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import DocumentConversionViewFormProperties from './DocumentConversion_ViewFormProperties';
import pathMap from 'src/paths/PathMap_SCO_Job'


const {
    form: {
        CngViewForm,
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    button: {
        CngSecondaryButton
    },
    CngGridItem
} = components



function ViewPage({ history, showNotification, ...props }) {
    const [isOpen, setIsOpen] = useState(false)
    const [hideBackButton, setHideBackButton] = useState(false)
    const [inputDocuments, setInputDocuments] = useState([])
    const [outputDocuments, setOutputDocuments] = useState([])
    const { fetchRecords, securedSendRequest } = useServices()
    const { id } = useParams()


    function fetchInputDocuments() {
        fetchRecords.execute(
            SCOJobApiUrls.GET_INPUT_DOCUMENT,
            {
                customData: {
                    docConversionId: id
                }
            },
            (data) => {
                setInputDocuments(data.content)
            }
        )
    }

    function fetchOutputDocuments() {
        fetchRecords.execute(
            SCOJobApiUrls.GET_OUTPUT_DOCUMENT,
            {
                customData: {
                    docConversionId: id
                }
            },
            (data) => {
                setOutputDocuments(data.content)
            }
        )
    }

    useEffect(() => {
        if (id != null) {
            fetchInputDocuments()
            fetchOutputDocuments()
        }
    }, [])

    return (
        <>
            <CngViewForm
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                showNotification={showNotification}
                history={history}
                bodySection={
                    <>
                        <DocumentConversionViewFormProperties.Fields
                            disabled={true}
                            showNotification={showNotification}
                            id={id}
                            inputDocuments={inputDocuments}
                            outputDocuments={outputDocuments}
                        />
                        <Grid container spacing={3}>
                            <Grid item>
                                <CngSecondaryButton
                                    onClick={() => { history.push(pathMap.SCO_JOB_DOC_CONVERSION) }}>
                                    {'Back'}
                                </CngSecondaryButton>
                            </Grid>
                        </Grid>
                    </>

                }
                formikProps={DocumentConversionViewFormProperties.formikProps}
                toClientDataFormat={DocumentConversionViewFormProperties.toClientDataFormat}
                toServerDataFormat={DocumentConversionViewFormProperties.toServerDataFormat}
                fetch={{
                    id: id,
                    url: `${SCOJobApiUrls.GET_DOC_CONVERSION_BY_ID}`
                }}


            />
        </>




    )

}

export default ViewPage
