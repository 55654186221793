import React from 'react'
import { components, constants, Yup } from 'cng-web-lib'
import TranslationText from "./TranslationText"
import { Card, CardContent, Grid } from '@material-ui/core'
import SCOAdminApiUrls from '../../../apiUrls/SCOAdminApiUrls'

const {
  form: {
    field: {
      CngTextField, CngLookupAutocompleteField
    }
  }, CngGridItem
} = components


const {
  filter: { EQUAL }
} = constants


function Fields({ ...props }) {
  const { disabled } = props

  const {
    category,
    attributeName
  } = TranslationText()


  return (<>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid container spacing={2}>

                  <CngGridItem xs={12} sm={6}>
                    <CngLookupAutocompleteField
                      name='categoryId'
                      label={category}
                      disabled={disabled}
                      required
                      lookupProps={{
                        url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                        label: 'name',
                        value: 'id',
                        filters: [
                          {
                            field: 'codeType',
                            operator: EQUAL,
                            value: 'JOB_ATTR_TYPE'
                          }
                        ]
                      }}
                    >
                    </CngLookupAutocompleteField>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='attributeName'
                      label={attributeName}
                      disabled={disabled}
                      required
                    />
                  </CngGridItem>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
      </Grid>
    </>

  )
}


function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const DEFAULT_INITIAL_VALUES = {
  categoryId: '',
  attributeName: ''
}

const ValidationSchema = () => {
  return Yup.object({

  })
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}


const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties