import { Yup } from 'cng-web-lib'

function JobConfigWorkflowSchema() {

      const estimatedDurationErrMsg = 'Please enter only numeric values from 1 onwards.'
      const sequenceErrMsg = 'Sequence cannot be duplicated/less than 1.'
      const mandatoryErrMsg = 'Required/Mandatory.'
      const mandatoryTaskSequenceMsg = 'Required/Mandatory/No Duplicate.'
      const wrongEmailFormat = 'Must be in email format.'

      let taskSeq_list = []
      let taskType_list = []

      Yup.addMethod(Yup.array, 'uniqueTaskSequence', function (message) {
            return this.test('uniqueTaskSequence', message, function (val) {
                  let list = []
                  val.map((elem) => {
                        if (list.includes(elem.taskSequence)) {
                              taskSeq_list.push(elem.taskSequence)
                        } else {
                              list.push(elem.taskSequence)
                        }
                        if (list.length === val.length) {
                              taskSeq_list = []
                        }
                        return elem
                  })
                  return true
            })
      })

      Yup.addMethod(Yup.array, 'uniqueTaskTypeInv', function (message) {
            return this.test('uniqueTaskTypeInv', message, function (val) {
                  let list = []
                  val.map((elem) => {

                        if (list.includes(elem.taskType)) {

                              taskType_list.push(elem.taskType)

                        } else {

                              list.push(elem.taskType)

                        }
                        if (list.length === val.length) {

                              taskType_list = []

                        }

                        return elem
                  })

                  return true
            })
      })



      Yup.addMethod(Yup.number, 'uniqueSeq', function (message) {
            return this.test('uniqueSeq', message, function (val) {
                  return !taskSeq_list.includes(val)
            })
      })

      Yup.addMethod(Yup.string, 'uniqueUpdateInv', function (message) {
            return this.test('uniqueUpdateInv', message, function (val) {
                  if (val == 'INV') {
                        console.log(!taskType_list.includes(val))
                        return !taskType_list.includes(val)
                  } else {
                        return true
                  }
            })
      })

      return Yup.object({
            workFlowItems: Yup.array(
                  Yup.object({
                        taskSequence: Yup.number().typeError('Must be a number').required(mandatoryTaskSequenceMsg).min(1, sequenceErrMsg).nullable(),
                        taskType: Yup.string().required(mandatoryErrMsg).nullable(),
                        taskDescription: Yup.string().required(mandatoryErrMsg).nullable(false).max(200, 'Maximum 200 characters.'),
                        movementType: Yup.string().nullable()
                              .when('taskType', {
                                    is: (val) => val === 'INV',
                                    then: Yup.string().required(mandatoryErrMsg).nullable(false),
                              }),
                        estimatedDuration: Yup.number().integer().min(1, estimatedDurationErrMsg).nullable(true),
                        emailItems: Yup.array(
                              Yup.object({
                                    emailAddress: Yup.string().required(mandatoryErrMsg).nullable().email(wrongEmailFormat).max(100, "Email Address cannot be more than 100 characters."),
                              }),
                        )
                  })
            )

      })



}

export default JobConfigWorkflowSchema
