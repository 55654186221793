import { Box, Grid, Card, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { components, constants, useTranslation, Yup, useServices } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import { useFieldArray } from "react-hook-form"
import SCOJobAttributeApiUrls from 'src/apiUrls/SCOJobAttributeApiUrls'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import AttributeDialog from './AttributeDialog'
import { v4 as uuid } from 'uuid'
import SCOJobTranslationText from '../SCOJobTranslationText'

const INPUT_TYPE_CODES = {
    DATE: "DT",
    DATE_TIME: "DTTM",
    TEXT: "TXT",
    NUM: "NUM",
    CUSTOM_ATTR: "CATTR"
}

const {
    form: {
        field: { CngTextField, CngDateField, CngDateTimeField, CngLookupAutocompleteField },
        adapter: {
            useFormAdapter: { useFormikContext }
        },
    },
    CngGridItem,
} = components


const {
    filter: { EQUAL }
} = constants

const initialValues = {
    jobAttributeList: []
}

const FormBody = (props) => {
    const { jobTypeId, jobId, jobAttributesData, loading } = props;
    const { setFieldValue } = useFormikContext()
    const { fetchRecords } = useServices()
    const [isOpen, setIsOpen] = useState(false);
    const [attrConfigOptions, setAttrConfigOptions] = useState([]);
    const [filteredAttrConfigOptions, setFilteredAttrConfigOptions] = useState([]);
    const { fields: jobAttributeList } = useFieldArray({ name: 'jobAttributeList', keyName: 'key' });

    const translatedTextsObject = SCOJobTranslationText()


    const displayAttrValueField = (attribute, index) => {
        console.log(attribute)
        let code = attribute.configDTO.inputType.code;
        let field;
        switch (code) {
            case INPUT_TYPE_CODES.DATE:
                field = <CngDateField
                    name={`jobAttributeList[${index}].value`}
                    label={translatedTextsObject.scoAttributeValue}
                    disabled={false}
                    required
                />
                break;
            case INPUT_TYPE_CODES.DATE_TIME:
                field = <CngDateTimeField
                    name={`jobAttributeList[${index}].value`}
                    label={translatedTextsObject.scoAttributeValue}
                    disabled={false}
                    required
                />
                break;
            case INPUT_TYPE_CODES.NUM:
                field = <CngTextField
                    type="number"
                    disabled={false}
                    name={`jobAttributeList[${index}].value`}
                    label={translatedTextsObject.scoAttributeValue}
                    required
                />
                break;
            case INPUT_TYPE_CODES.CUSTOM_ATTR:
                field = <CngLookupAutocompleteField
                  label={translatedTextsObject.scoAttributeValue}
                  name={`jobAttributeList[${index}].value`}
                  options={attribute.configDTO.customAttribute.customAttributeValueList}
                  getOptionLabel={(option) => option.value}
                  required
                />
                break;
            default:
                field = <CngTextField
                    disabled={false}
                    name={`jobAttributeList[${index}].value`}
                    label={translatedTextsObject.scoAttributeValue}
                    required
                />
        }
        return field;
    }


    const getJobAttributesDDL = () => {
        const onSuccess = (response) => {
            console.log(response)
            const options = response.content.map((attr) => {
                return attr;
            })
            setAttrConfigOptions(options)
            let filteredAttr = options.map((opt) => {
                return { text: opt.customAttribute != null ? opt.customAttribute.attributeName : opt.attributeName, value: opt.id }
            })
            setFilteredAttrConfigOptions(filteredAttr);
        }

        fetchRecords.execute(
            SCOJobAttributeApiUrls.GET_JOB_ATTRIBUTE_OPTION,
            {
                filters: [
                    {
                        field: 'attributeType',
                        operator: EQUAL,
                        value: 'JOB'
                    }
                ]
            },
            onSuccess
        )
    }


    const loadAttributesFromJobType = () => {
        const onSuccess = (response) => {
            console.log(response)
            setFieldValue("jobAttributeList", [])
            let responseTemp = response.content;
            setFieldValue("jobAttributeList", responseTemp)
            setNewFilteredOptions(responseTemp)
        }

        fetchRecords.execute(
            SCOJobApiUrls.GETJOBTYPEATTRIBUTES,
            {
                filters: [
                    {
                        field: 'id',
                        operator: EQUAL,
                        value: jobTypeId
                    }
                ]
            },
            onSuccess
        )
    }

    const loadJobAttributesFromJob = () => {
        let dataList = jobAttributesData
        if (dataList != null) {
            setNewFilteredOptions(dataList)
            dataList.forEach(function (x) {
                x.key = uuid();
            })
            setFieldValue("jobAttributeList", dataList)
        }
    }

    const setNewFilteredOptions = (newList) => {
        let filteredAttr = attrConfigOptions.filter(attrConfig => !newList.some(existingAttr => existingAttr.configDTO.id == attrConfig.id)).map((attrConfig) => {
            return { text: attrConfig.customAttribute != null ? attrConfig.customAttribute.attributeName : attrConfig.attributeName, value: attrConfig.id }
        });
        setFilteredAttrConfigOptions(filteredAttr);
    }

    const handleRemoveAttribute = (index) => {
        const list = jobAttributeList;
        list.splice(index, 1);
        setFieldValue("jobAttributeList", list);
        setNewFilteredOptions(list);
    };

    const openAttributeDialog = () => {
        setIsOpen(true);
    }

    useEffect(() => {
        getJobAttributesDDL()
    }, [])

    useEffect(() => {
        if (jobTypeId != '' && jobId == undefined) {
            loadAttributesFromJobType()
        } else {
            loadJobAttributesFromJob()
        }
    }, [jobAttributesData, attrConfigOptions, jobTypeId, jobId])


    return (
        <CngGridItem xs={12} sm={12}>
            <Card>
                <Box px={2} my={1.5}>
                    <Grid container >
                        <AccordionHeaderComponent title={"Job Attribute"} />
                    </Grid>
                    <AttributeDialog
                        reference={"jobAttributeList"}
                        isOpen={isOpen}
                        setFieldValue={setFieldValue}
                        attributeList={jobAttributeList}
                        setIsOpen={setIsOpen}
                        attrOptions={attrConfigOptions}
                        filteredAttrOptions={filteredAttrConfigOptions}
                        setFilteredAttrConfigOptions={setFilteredAttrConfigOptions}
                        setNewFilteredOptions={setNewFilteredOptions}
                    />
                    {jobAttributeList.map((attribute, index) => {
                        return (
                            <Box mb={2} width={`100%`} key={attribute.key}>
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            disabled={true}
                                            name={jobAttributeList[index].configDTO.customAttribute != null ? `jobAttributeList[${index}].configDTO.customAttribute.attributeName` : `jobAttributeList[${index}].configDTO.attributeName`}
                                            label={translatedTextsObject.scoAttribute}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        {displayAttrValueField(attribute, index)}
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <Box style={{ textAlign: 'left' }}>
                                            <IconButton aria-label="remove" disabled={false} onClick={() => handleRemoveAttribute(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </CngGridItem>
                                </Grid>
                            </Box>
                        )
                    }
                    )}


                    <CngGridItem xs={12} sm={4}>
                        <IconButton style={{ width: 100, height: 50 }} aria-label="add" disabled={false}
                            onClick={() => openAttributeDialog()}>
                            <AddCircleOutlineIcon
                                fontSize="small"
                                style={{ fontSize: 20, textAlign: 'center', color: 'grey' }}
                            />
                            <span style={{ fontSize: 15, textAlign: 'center' }}>Add Attribute</span>
                        </IconButton>
                    </CngGridItem>
                </Box>
            </Card>
        </CngGridItem>
    )


}

const JobAttributeDialog = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default JobAttributeDialog
