import { Divider, Grid, CardContent, Card } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import FormProperties from './FormProperties'
import CustomAttributeUrls from "../../../apiUrls/sco-jm-custom-attr/CustomAttributeUrls"
import CustomAttributeValueCrudTable from './customattributevalue/Custom_Attr_Value_TablePage'
import TranslationText from './TranslationText'

const {
  form: { CngViewForm },
  card: { CngSimpleCardHeader }
} = components

function ViewPage({ showNotification }) {
  const { id } = useParams()
  const translatedTextObject = TranslationText()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextObject.customAttrHeader} />
          <Divider />
          <CardContent>
            <Grid item xs={12}>
              <CngViewForm
                fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                showNotification={showNotification}
                bodySection={
                  <FormProperties.Fields
                    disabled={true}
                    showNotification={showNotification}
                  />
                }
                formikProps={FormProperties.formikProps}
                toClientDataFormat={FormProperties.toClientDataFormat}
                toServerDataFormat={FormProperties.toServerDataFormat}
                fetch={{
                  id: id,
                  url: CustomAttributeUrls.CUSTOM_ATTR_GET
                }}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CustomAttributeValueCrudTable customAttributeId={id} isViewOnly={true} showNotification={showNotification} />
      </Grid>
    </Grid>
  )
}

export default ViewPage
