import { Box, Button, Grid } from '@material-ui/core'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'

function JobConfigButtonComponents(props) {
    const mode = props.mode
    const translatedTextsObject = CalistaUiComponentTranslationText();

    if (mode == 'add') {
        return (
            <Box pl={5} pr={5} pt={4} pb={4} >
                <Grid container xs={12} sm={12}>
                    <Grid xs={6} sm={6} justify="flex-start">
                        <Button variant="contained"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                props.onDiscard()
                            }
                            }
                            classes={{ root: 'ng-button-filled-secondary' }}
                        >
                            {translatedTextsObject.discard}
                        </Button>
                    </Grid>
                    <Grid container xs={6} sm={6} justify="flex-end" >
                        <Box>
                            <Button variant="contained"
                                className="button-light-green originalText"
                                onClick={() => { props.onCreate() } 
                                }
                            >
                                {translatedTextsObject.create}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        )
    } else if (mode == 'view') {
        return (
            <Box pl={5} pr={5} pt={4} pb={4} >
                <Grid container xs={12} sm={12}>
                    <Grid xs={6} sm={6} justify="flex-start">
                        <Button variant="contained"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                props.onBack()
                            }
                            }
                            classes={{ root: 'ng-button-filled-secondary' }}
                        >
                            {translatedTextsObject.back}
                        </Button>
                    </Grid>
                </Grid>
            </Box >
        )
    } else if (mode == 'edit') {
        return (
            <Box pl={5} pr={5} pt={4} pb={4} >
                <Grid container xs={12} sm={12}>
                    <Grid xs={6} sm={6} justify="flex-start">
                        <Button variant="contained"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                props.onBack()
                            }
                            }
                            classes={{ root: 'ng-button-filled-secondary' }}
                        >
                            {translatedTextsObject.back}
                        </Button>
                    </Grid>
                    <Grid container xs={6} sm={6} justify="flex-end" >
                        <Box>
                            <Button variant="contained"
                                className="button-light-green originalText"
                                onClick={() => {
                                    props.onUpdate()
                                }
                                }
                            >
                                {translatedTextsObject.update}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        )
    }
}

export default JobConfigButtonComponents