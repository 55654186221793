import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import EditJobFormProperties from './EditJobFormProperties'
import JobValidationSchema from './JobValidationSchema'
import SCOJobApiurls from 'src/apiUrls/SCOJobApiUrls'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function EditPage({ history, showNotification }) {
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const { updateRecord, createRecord } = useServices()
  const validationSchema = JobValidationSchema()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)


  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function uploadDocumentAndSaveDraft(data) {
    var formData = new FormData();
    data.pendingJobDocuments.map((file, i) => {
      var fileBlob = file.file
      formData.append("fileContents", fileBlob)
      return null
    })


    function onSuccess(response) {
      if (response.length > 0 && response.errorMessages != null) {
        console.log('error >> ')
        showErrorNotification(response.errorMessages)
      } else {
        let jobDocuments = data.jobDocuments || []
        if (response && response.length > 0) {
          console.log('this is response >>>> ' + JSON.stringify(response))
          response.map((file, i) => {
            let jobDocu = {
              docType: data.pendingJobDocuments[i].fields[0].value,
              fileName: file.fileName,
              fileDescription: data.pendingJobDocuments[i].fields[1].value,
              id: file.id,
              fileSize: file.fileSize
            }
            jobDocuments.push(jobDocu)
          })
        }
        data.jobDocs = jobDocuments;


        if (data.isDraft === true) {
          console.log('save draft')
          saveDraft(data)
        } else if (data.isDraft === false) {
          console.log('save job')
          updateJob(data)
        }
      }
    }

    function onError(error) {
      showErrorNotification(error.message)
    }

    createRecord.execute(
      SCOJobApiurls.DOCUMENT_UPLOAD,
      formData,
      onSuccess,
      onError
    )
  }


  function saveDraft(data) {
    setLoading(true)


    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(response.jobRefNo + " save succesfully.")
        history.push({
          pathname: '/cal-sco/job'
        })
      }
    }

    updateRecord.execute(
      SCOJobApiurls.HDR_UPDATE_JOB,
      data,
      onSuccess,
      onError
    )
  }

  function updateJob(data) {
    setLoading(true)
    console.log('Updating job >>>>> ' + JSON.stringify(data))

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(response.jobRefNo + ' updated successfully.')
        history.push({
          pathname: '/cal-sco/job'
        })
      }
    }

    updateRecord.execute(
      SCOJobApiurls.HDR_UPDATE_JOB,
      data,
      onSuccess,
      onError
    )
  }


  function onError(error) {
    console.log('connection-request error', error.response.data.errorMessages)
    setLoading(false)
    showErrorNotification(error.response.data.errorMessages)
  }

  function cancelJob(data) {
    setLoading(true)
    console.log('Cancelling job >>>>> ' + JSON.stringify(data))

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(response.jobRefNo + ' Cancel Job successfully.')
        history.push({
          pathname: '/cal-sco/job'
        })
      }
    }

    updateRecord.execute(
      SCOJobApiurls.HDR_CANCEL_JOB,
      data,
      onSuccess,
      onError
    )
  }


  function onSubmit(data) {
    setLoading(true)
    console.log('this is data ' + JSON.stringify(data))
    if (data.pendingJobDocuments && !isEmpty(data.pendingJobDocuments)) {
      uploadDocumentAndSaveDraft(data)
    } else {
      if (data.isDraft === true) {
        saveDraft(data)
      } else if (data.isDraft === false && data.cancelJob === false) {
        console.log("Sending Update Job >>> to BE")
        updateJob(data)
      } else if (data.isDraft === false && data.cancelJob === true) {
        //this works here
        console.log("Sending Cancel Job >>> to BE")
        cancelJob(data)

      }
    }


  }


  return (

    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <EditJobFormProperties.FormFields
          history={history}
          showNotification={showNotification}
          loading={loading}
          id={id}
        />
      }
      formikProps={{
        ...EditJobFormProperties.formikProps,
        validationSchema,
        onSubmit: onSubmit,
      }}
      fetch={{
        id: id,
        url: SCOJobApiUrls.GETMYJOB
      }}
    />

  )

}

export default EditPage
