import { Yup } from 'cng-web-lib'


function InventoryFormSchema() {
    const mandatoryErrMsg = 'Required/Mandatory';

    return Yup.object({
        buyerId: Yup.string().nullable(),
        dutyStatusId: Yup.string().nullable().required(mandatoryErrMsg),
        permitTypeId: Yup.string().nullable().required(mandatoryErrMsg),
        locationId: Yup.string().nullable(),
        operatorId: Yup.string().nullable()
    })
}

export default InventoryFormSchema
