import { Card, CardContent, Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import React, { useEffect } from 'react';
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import SCODocumentTranslationText from './SCODocumentTranslationText'
import DocumentDetailsComponents from './DocumentDetailsComponents'


const {
    dropzone: { CngFileUpload, HelperTextArea },
    form: {
        field: { CngSelectField },
        adapter: {
            useFormAdapter: { useField }
        },
    },
    CngGridItem,
} = components
const { NotificationType } = constants

const DEFAULT_INITIAL_VALUES = {
    fileType: "",
    inputDocuments: []
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES }
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled, showNotification, inputDocuments } = props;
    const [uploadFile, , { setValue: setUploadFile }] = useField('files')
    const [uploadedDocuments, , { setValue: setUploadedDocuments }] = useField('inputDocuments')

    const fieldsTranslatedTextObject = SCODocumentTranslationText()

    const moreActions = [
        {
            action: 'remove',
            name: 'Remove',
            icon: ['fal', 'trash']
        }
    ]

    const onClickDelete = (id) => {
        inputDocuments.splice(id, 1);
        const newFiles = [].concat([], inputDocuments)
        setUploadedDocuments(newFiles)
    }

    useEffect(() => {
        setUploadedDocuments(inputDocuments)
        console.log('this is uploadedDocuments ' + JSON.stringify(uploadedDocuments))
    }, [inputDocuments])

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.uploadDocument} />
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngSelectField
                                            disabled={disabled}
                                            name="fileType"
                                            label={fieldsTranslatedTextObject.outputDocType}
                                            required
                                            fetch={
                                                {
                                                    url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                                    textAccessor: "name",
                                                    body: {
                                                        codeType: 'DOC_CONVERSION_FILE_TYPE',
                                                        status: true
                                                    }
                                                }
                                            }
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4} shouldHide={true}>
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>

                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={12}>
                                        <CngFileUpload
                                            accept={['.pdf']}
                                            maxFiles={5}
                                            maxSize={10485760}
                                            onFileSelect={(files) => {
                                                setUploadFile(files)
                                            }}
                                            files={null}
                                            showFormFields={false}
                                            moreActions={moreActions}
                                            onDropRejected={(errorMessages) => {
                                                showNotification(NotificationType.ERROR, errorMessages[0])
                                            }}
                                            renderHelperText={() => {
                                                return (
                                                    <HelperTextArea
                                                        accept={['.pdf']}
                                                        maxFiles={5}
                                                        maxSize={10485760}
                                                    />
                                                )
                                            }}
                                        />

                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <br />
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.inputDocument} />
                                <DocumentDetailsComponents
                                    uploadedFiles={inputDocuments}
                                    reference={'input'}
                                    isDraft={true}
                                    onClickDelete={onClickDelete}
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
